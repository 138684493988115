




import { Vue, Component } from 'vue-property-decorator';

import { GameLog } from '@/store/types';
import { namespace } from 'vuex-class';

import moment from "moment";

const GameLogsAction = namespace("gameLogs").Action
const SettingsGetter = namespace('settings').Getter
const WorldCupAction = namespace("worldCup").Action
const WorldCupGetter = namespace("worldCup").Getter

const AppProps = Vue.extend({
    data() {
        return {
            totalProgress: 0,
            intervalId: null,
            gameLogIds: [],
        }
    },
    computed: {
        pointsPerProgress() : number {
            return 50
        },
        predictions() : number {
            const matchIds = this.getMatches.map(match => match.match_id)
            return this.predictionsToday.filter(prediction => matchIds.includes(prediction.matchId)).length
        },
        progress() : number {
            const matchIds = this.getMatches.map(match => match.match_id)
            const finishedPredictions = this.predictionsToday.filter(prediction => !matchIds.includes(prediction.matchId)).length
            const progress = this.totalProgress - (finishedPredictions * this.pointsPerProgress)
            return progress
        },
        matches() : number {
            return this.getMatches.length
        },
        maxPoints() : number {
            return this.matches * this.pointsPerProgress
        },
        isMaxed() {
            return this.maxPoints <= this.progress
        }
    },
    methods: {
        getProgress(index: number): string {
            // Mark progress as completed if the progress
            // is enough for the current requirement
            const requirement = index * this.pointsPerProgress
            if (this.progress >= requirement) {
                return '100%'
            }

            // Deduct completed matches from the progress calculation.
            const difference = requirement - this.progress

            // If the difference is greater than the pointPerProgress, automatically return 0%.
            if (difference >= this.pointsPerProgress) {
                return '0%'
            }

            // Otherwise get the inverse of the fraction of the pointsPerProgress and the difference.
            const current = (1 - (difference / this.pointsPerProgress)) * 100
            return `${current.toFixed(1)}%`
        },
        async fetchPoints() {
            const start = moment().utc().startOf('day')
            const tomorrow = moment().utc().add(1, 'days')
            let payload = {
                'startDate': start,
                'endDate': tomorrow,
                'getAll': false,
                //@ts-ignore
                'platformCurrency': this.getPlatformCurrency
            }
            //@ts-ignore
            const response = await this.getGameLogsData(payload)
            if (response.content && response.content.length > 0) {
                response.content.forEach(async (gameLog: GameLog) => {
                    if (!this.gameLogIds.includes(gameLog.macroRoundId)) {
                        this.gameLogIds = [...this.gameLogIds, gameLog.macroRoundId]
                        const gameDate = new Date(gameLog.createdAt)
                        const exchangeRate = await this.getExchangeRate(gameDate)
                        if (exchangeRate) {
                            const platformCurrencyName = this.getPlatformCurrency.toLowerCase() + 'UsdPrice'
                            const platformExchangeRate = exchangeRate[platformCurrencyName]
                            this.totalProgress += (gameLog.betsSum * platformExchangeRate)
                        }
                    }
                });
            }

            return Promise.resolve()
        },
    },
})

@Component 
export default class BaseProgression extends AppProps {
  @GameLogsAction('getGameLogs') getGameLogsData
  @SettingsGetter getPlatformCurrency
  @WorldCupGetter getMatches
  @WorldCupGetter ('getPredictionsToday') predictionsToday
  @WorldCupAction ('getExchangeRate') getExchangeRate
  @WorldCupAction getUserPredictionsToday
}
