











































import { Component, Vue } from 'vue-property-decorator'
import Common from "../../mixins/Common.vue"
import { namespace } from 'vuex-class'
const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace("account").Action
const AccountGetter = namespace("account").Getter

const AppProps = Vue.extend({
  mixins: [Common],
  mounted() {
    this.$refs['play-real-modal'].show()
  },
  methods: {
    closeModal() {
      this.$refs['play-real-modal'].hide()
    },
    async openDepositDialog() {
      await this.getDepositAddress()
    },
    async openMoonpayDialog() {
      this.updateParamsInLocation({ buy: true})
    },
    async openSideshiftDialog() {
      await this.updateParamsInLocation({ convert: true, playforrealdialog: true});
      await this.openDepositDialog();
    },
    async redirectToSignUp() {
      this.$router.push('/signup')
    }
  }
});

@Component
export default class PlayRealDialog extends AppProps {
  @SettingsGetter getPlatformCurrency
  @AccountAction getDepositAddress
  @AccountGetter isLoggedIn: boolean
}
