



import { Component, Vue } from "vue-property-decorator";

const AppProps = Vue.extend({
  props: {}
});
@Component({
  data() {
    return {
      scale_initialized: false
    }
  },
  components: {},
  methods: {
    toggleDropDown(dropdownId, close: false) {
      let dropDown = document.getElementById(dropdownId)
      if (close) {
        dropDown!.classList.remove("show");
      } else {
        dropDown!.classList.toggle("show");
      }

    },
    lstrip(stringToStrip, charToStrip) {
      let replace = new RegExp("^" + charToStrip + "+");
      return stringToStrip.replace(replace, "");
    },
    rstrip(stringToStrip, charToStrip) {
      let replace = new RegExp(charToStrip + "+$");
      return stringToStrip.replace(replace, "");
    },

    decimalFromToken(credit_value) {
      let suffix_num = credit_value / 100000000;
      return Number(suffix_num).toString();
    },

    prizeFromToken(credit_value, multiplier) {
      // @ts-ignore
      return (credit_value * multiplier) / this.string_amount_to_int("1");
    },

    string_amount_to_int(amount) {
      if (typeof amount != "string") return undefined;

      // Replace commas
      amount = amount
        .replace(/\s+/g, "")
        .replace(/\s+$/g, "")
        .replace(",", "");

      // If the number starts with a '-' we need to remember that
      var neg = 1;
      if (amount.length > 0 && amount[0] == "-") {
        neg = -1;
        amount = amount.substr(1);
      }

      // The rest of the number can only be 0..9 and a period
      var amountcheck = amount.replace(/[0-9]/g, "").replace(/\./, "");
      if (amountcheck.length != 0) {
        return undefined;
      }

      // Handle case when zero or empty string is passed in
      amount = amount.replace(/^0+/, "");
      if (amount.length == 0) return 0;

      var scale = 100000000;

      // Find the first '.' (if more sneak by the above check, then parseInt will error)
      var i = amount.indexOf(".");
      if (i == amount.length - 1) {
        amount = amount.substr(0, amount.length - 1);
        if (amount.length == 0) {
          return 0;
        }
        i = -1;
      }

      var v = 0;
      if (i < 0) {
        // No '.' found, use it as a whole number
        v = parseInt(amount) * scale;
      } else {
        var dec = amount.substr(i + 1).replace(/0+$/, "");
        if (dec.indexOf(".") != -1) return undefined;

        scale = Math.floor(scale / Math.pow(10, dec.length));

        amount = amount.substr(0, i) + dec;

        //  - Trim leading zeroes so that "025" becomes "25" so that retarded opera doesn't evaluate the string in octal...
        amount = amount.replace(/^0+/g, "");
        if (amount.length == 0) return 0;

        v = parseInt(amount) * scale;
      }
      return neg * v;
    },

    classSuffixFromToken(credit_value) {
      //@ts-ignore
      let suffix_num = credit_value / 100000000;
      let suffix_str;
      //For some reason 1/10th of a Satoshi is suffix 1 and not 01, so instead we just take the decimals
      //and trim the leading "0." (i.e. 0.001 of a BTC becomes suffix 001). If value is larger or equal to 1,
      //then multiply by 10 to get the correct suffix
      if (suffix_num >= 0.1) {
        suffix_str = Number(suffix_num * 10).toString();
        //@ts-ignore
        suffix_str = this.lstrip(suffix_str, "0");
      } else {
        suffix_str = Number(suffix_num).toString();
        //@ts-ignore
        suffix_str = this.lstrip(suffix_str, "0");
        //@ts-ignore
        suffix_str = this.lstrip(suffix_str, "\\.");
        //@ts-ignore
        suffix_str = this.rstrip(suffix_str, "0");
      }
      return suffix_str;
    },

    setGameScale() {
      // Calculate game scale relative to viewport - Should eventually be converted to a proper responsive game
      const vm = this
      const gameAreaWidth  = document.getElementById('gamearea_bordered').offsetWidth
      const gameAreaHeight= document.getElementById('gamearea_bordered').offsetHeight
      const gameWidth = document.getElementById('main_game_center').offsetWidth
      const fitH = screen.height / gameAreaHeight
      const fitW = gameAreaWidth / gameWidth
      const gameScale = fitH < fitW ? fitH : fitW
      document.documentElement.style.setProperty("--gameScale", gameScale > 1 ? '1' : gameScale.toString())
      document.documentElement.style.setProperty("--gameScaleMargin", gameScale == fitH ? Math.ceil(((screen.width - (gameScale*gameWidth))/2)).toString() + 'px' : '0px')
      document.documentElement.style.setProperty("--gameScreenHeight", screen.height + 'px')
      // @ts-ignore
      vm.scale_initialized = true
    },

    initializeGameScale() {
      // @ts-ignore
      this.setGameScale()
      window.addEventListener('resize', () => {
        // @ts-ignore
        this.setGameScale()
      }, false)
    },

    // Adds or removes query parameters from url without reloading the page/rerouting. Currently used in the 'Play real' and Depost dialog logic
    // if params is 'remove' then it removes all query parameters
    updateParamsInLocation(params) {
      history.replaceState(
              {},
              null,
              this.$route.path + (params !== 'remove' ?
              '?' +
              Object.keys(params)
                      .map(key => {
                        return (
                                encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                        )
                      })
                      .join('&')
              : '')
      )
    },
    /* Close fullscreen */
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      // @ts-ignore
      } else if (document.mozCancelFullScreen) { /* Firefox */
        // @ts-ignore
        document.mozCancelFullScreen();
        // @ts-ignore
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        // @ts-ignore
        document.webkitExitFullscreen();
        // @ts-ignore
      } else if (document.msExitFullscreen) { /* IE/Edge */
        // @ts-ignore
        document.msExitFullscreen();
      }
    }
  }
})
export default class Common extends AppProps {}
