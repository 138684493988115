

























































































































































































































































































































































































































































































































































































































































































































































































































































































































require('@/pages/games/css/roulette.css')

import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  props: {}
})
@Component({
  components: {}
})
export default class RouletteTable extends AppProps {
}
