import moment from 'moment'

const axios = require('axios')
const rqBuilder = require('../helpers/store').rqBuild
const cookies = require('js-cookie')
const _ = require('underscore')

import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, GameLogState } from './types'

type GameLogGetter = GetterTree<GameLogState, RootState>

export const state: GameLogState = {
    gameLogs: [],
    gamesByInternalGameId: {}
}

export const getters: GameLogGetter = {
    gameLogs: state => state.gameLogs,
    gamesByInternalGameId: state => state.gamesByInternalGameId
}

export const mutations: MutationTree<GameLogState> = {
    setGameLog(state, data) {
        state.gameLogs = state.gameLogs.concat(data);
    },
    clearGameLog(state) {
        state.gameLogs = [];
    },
    setGameByInternalGameId(state, content) {
        state.gamesByInternalGameId = {...state.gamesByInternalGameId, ...content};
    }
};

export const actions: ActionTree<GameLogState, RootState> = {
    async getGameLogs({ commit, dispatch, rootGetters }, payload) {
        try {
            if (!cookies.get('userId')) {
              return {
                error: 'No user id present'
              }
            }
            const startDate = moment(payload.startDate).toISOString()
            const endDate = moment(payload.endDate).endOf('day').toISOString()
            const isGetAll = payload.getAll;
            let endpoint = `/gaming_activity_ng/gaming_activity?playerUUID=${cookies.get('userId')}&size=1000`;

            if(!isGetAll){
              endpoint = endpoint + `&startDate=${startDate}&endDate=${endDate}`
            }

            const rqOptions = rqBuilder(
              'GET',
              rootGetters['settings/getApiUrl'] + endpoint,
                {},
                rootGetters['account/userToken']
            );

            const res = await axios(rqOptions);
            if (res.data) {
                if(res.data.content.length > 0) {
                  commit('clearGameLog');
                  commit('setGameLog', res.data.content);
                  // dispatch('getGameByInternalGameId');
                } else {
                  commit('clearGameLog');
                }
                return res.data
            } else throw new Error('Invalid response from server');
        } catch (e) {
          return {
            error: 'Could not get game logs'
          }
        }
    },

    async getGameByInternalGameId({ commit, rootGetters }) {
        const internalGameIds = state.gameLogs.map(a => a.internalGameId ? a.internalGameId : null)
        internalGameIds.splice(0, internalGameIds.length, ...(new Set(internalGameIds)))
        for await (const element of internalGameIds){
            try{
                const endpoint = `/game_info_ng/public/games/internal/${element}?brandId=` + rootGetters['settings/getBrandId']
                const rqOptions = rqBuilder(
                  'GET',
                  rootGetters['settings/getApiUrl'] + endpoint,
                  {},
                    rootGetters['account/userToken']
                )

                const res =await axios(rqOptions)
                if (res.data) {
                    const payload = {}
                    payload[element] = res.data
                    commit('setGameByInternalGameId',payload)
                } else {
                  return {
                    error: 'Game Not Found'
                  }
                }
            } catch (e){
              return {
                error: 'Could not get games by internal game id'
              }
            }
        }
    }
}

export const gameLogs: Module<GameLogState, RootState> = {
    state,
    actions,
    getters,
    mutations,
    namespaced: true
}
