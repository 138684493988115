import { store } from '../../../../../store/index'

export default class GameController {

  constructor (model, parentController = null) {
    this.model = model
    this.parentController = parentController
  }

  getTitle () {
    return this.model.title
  }

  getId() {
    return this.model.gameId
  }

  get categoryData () {
    return this.model.category
  }

  getIconURL () {
    return this.model.desktopImage
  }

  playGame () {
    let path = this.model.identifier.split(':')
    if (path[0] === 'bitcoincom') {
      let url = window.location
      url.href = url.protocol + '//' + url.host + '/' + path[1].toLowerCase()
    } else {
      this.parentController.loadGame(this.model.identifier)
      window.history.pushState({}, this.model.title, '/3game?game_id=' + this.model.identifier)
      this.parentController.closeMenu()
    }
  }

  setFavorite (val) {
    this.model.favorite = val
  }

  async setFavoriteData () {
    if (this.model.favorite) {
      this.parentController.updateFavoritesLocal(this.model.id, false)
      await store.dispatch('game/unfavoriteGame', this.model.id)
    } else {
      this.parentController.updateFavoritesLocal(this.model.id, true)
      await store.dispatch('game/favoriteGame', this.model.id)
    }
  }
}
