













































































































































































































































































































import { Component, Vue } from "vue-property-decorator"
import { namespace } from "vuex-class"
import moment from 'moment-timezone'
import VueRecaptcha from "vue-recaptcha";
import VueMarkdownIt from "vue-markdown-it";

const cookies = require('js-cookie')
const UIMutation = namespace('ui').Mutation
const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace('account').Action
const AccountGetter = namespace('account').Getter
const GameLogsAction = namespace("gameLogs").Action
const DepositAction = namespace("deposit").Action

const AppProps = Vue.extend({
  data() {
    return {
      tabIdx: 0,
      btagPromoTac: {text: '', text_es: '', text_ru: '', text_pt: ''},
      signupProgress: 5,
      hideLoginTab: false,
      loginForm: {
        email: '',
        password: '',
      },
      signupForm: {
        email: '',
        password: '',
        getEmails: true
      },
      recoveryForm: {
        email: ''
      },
      loginLoader: false,
      signupLoader: false,
      passwordFieldType: 'password',
      loginError: false,
      signupError: '',
      signupSubmitted: false,
      verificationEmailResent: false,
      forgottenPassword: false,
      forgottenPasswordEmailSent: false,
      forgottenPasswordEmailResent: false,
      resendTimer: 30,
      isMobile: /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent),
      loginBlockWarning: '',
      affiliateBtagPromoId: cookies.get('affiliateBtagPromoId'),
      VUE_APP_RECAPTCHA_SITE_KEY: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
      reCaptchaToken: null,
      reCaptchaError: null,
      currencySelected: 'BTC'
    }
  },
  components: {
    VueRecaptcha
  },
  props: ['tabIndex', 'queryParams'],
  watch: {
    tabIdx() {
      this.signupForm.email = '';
      this.signupForm.password = '';
      this.loginForm.email = '';
      this.loginForm.password = '';
      this.recoveryForm.email = '';
    },
    signupForm: {
      handler (val) {
        let progress = 5;

        const { password, email } = val

        // E-mail progress //
        if (email.length) { // Started typing e-mail
          progress += 5;
        }

        if(email.includes('@')) { // E-mail has @
          progress += 10

          if(email.includes('.')  // E-mail has . after @
          && email.indexOf('.') > email.indexOf('@')) {
            progress += 10
          }
        }

        if(/.+@.+\..+/.test(email)) { // E-mail is valid
          progress += 20;
        }

        // Password progress //
        if (password.length) { // Started typing password
          progress += 5;
        }

        if (password.length >= 8) { // Password reached 8 chars
          progress += 10;
        }

        if(/[a-z]/.test(password)) { // Password has a lowercase letter
          progress += 10;
        }

        if(/[A-Z]/.test(password)) { // Password has an uppercase letter
          progress += 10;
        }

        if(/[0-9]/.test(password)) { // Password has a number
          progress += 10;
        }


        this.signupProgress = progress;
      },
      deep: true
    }
  },
  computed: {
    localQueryParams: function() {
      if (this.queryParams == null) {
        return JSON.stringify({});
      }
      return JSON.parse(this.queryParams);
    },
  },
  async mounted() {
    // Show the reset password view immediately if player came by reset password link given by support/us
    if (this.$route.query.reset_password !== undefined) {
      this.forgottenPassword = true
    }
    if (this.isLoggedIn) {
      this.$router.push({path: '/home'})
    }
    if (this.$route.query.reset_password) {
      this.forgottenPassword = true;
    }
    this.getLoginAttempts();
    let urlParams = this.$route.query;
    if ((this.affiliateBtagPromoId !== undefined) && cookies.get('btag') !== undefined) {
      let promoTacsRes = await fetch(`${this.VUE_APP_STRAPI_API_URL}/btag-promos-tacs/${urlParams.affiliatePromo || this.affiliateBtagPromoId}`, { method: 'GET' });
      this.btagPromoTac = await promoTacsRes.json();
    }
    if (urlParams.affiliatePromo) {
      this.hideLoginTab = true;
    }
  },
  methods: {
    closeTaCModal(){
      this.$bvModal.hide('tanc-affiliate-promo');
      $('.modal-backdrop').remove();
    },
    triggerTaC() {
      this.$bvModal.show('tanc-affiliate-promo');
    },
    logoImagePath() {
      return require('@/assets/images/common/' + (this.$store.getters['settings/getPlatformName'] === 'cashgames' ? 'cash_games_logo' : 'btc_games_logo') + '.png')
    },
    goHome() {
      this.$router.push('home')
    },
    linkTabClass(idx) {
      if (this.tabIdx === idx) {
        return ['active-tab-title']
      } else {
        return ['inactive-tab-title']
      }
    },
    async submitLogin() {
      if (( this.isGlobalReCaptcha || (this.ipLoginHits >= 3) || (this.emailLoginHits >= 3))
          && (this.reCaptchaToken == null)) {
        this.reCaptchaError = 'Please complete reCaptcha'
        return
      }
      this.loginLoader = true;
      let params = JSON.parse(this.localQueryParams || {})
      params.email = this.loginForm.email
      params.password = this.loginForm.password
      params.reCaptchaToken = this.reCaptchaToken

      let res = await this.loginUser(params)

      // reset captcha and token after api call
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset();
        this.reCaptchaToken = null;
      }

      if (res.error) {
        this.loginLoader = res.disableLoginButton || false
        this.loginError = res.error
        this.loginBlockWarning = res.loginBlockWarning || ''
        if(res.triggerVerifyModal) {
          this.$modal.show('verify-email-modal');
        }
      } else {
        cookies.remove('learn_how_to_deposit_closed');
        this.setSecuredByPassword(true);
        cookies.set('isMagicTokenLogin', false);
        let dates = [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
        const getGameLogs = async () => {
          let payload = {
            'startDate': dates[0],
            'endDate': dates[1],
            'getAll': true,
            'platformCurrency': this.getPlatformCurrency
          }
          await this.getGameLogsData(payload);
        };
        //@ts-ignore
        await getGameLogs(false)
        this.loginLoader = false;

        await this.$router.push('/');
      }
    },
    async submitSignup() {
      this.signupLoader = true;
      let params = JSON.parse(this.localQueryParams || {})
      params.email = this.signupForm.email
      params.password = this.signupForm.password
      params.currencySelected = this.getPlatformCurrency
      let res = await this.registerUser(params)
      if (res.error) {
        this.signupLoader = false;
        this.signupError = res.error;
      } else {
        this.signupLoader = false;
        this.signupSubmitted = true;
        cookies.set('unverified_email', this.signupForm.email);
        await this.$router.push({name: 'home'});
        this.$modal.show('verify-email-modal');
      }
    },
    async submitRecovery() {
      await this.resetPasswordRequest(this.recoveryForm.email)
      this.forgottenPasswordEmailSent = true
      this.forgottenPassword = false
    },
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    hiddenEmailAddress(email) {
      const i = email.indexOf('@');
      const startIndex = i * .2 | 0;
      const endIndex   = i * .9 | 0;
      return email.slice(0, startIndex) +
              email.slice(startIndex, endIndex).replace(/./g, '*') +
              email.slice(endIndex);
    },
    async resendEmail(email, emailType = 'verification') {
      this.interval = setInterval(this.decrementTime, 1000);
      if (emailType === 'verification') {
        this.verificationEmailResent = true
        this.reconfirmEmailPublic(email)
      } else {
        this.resetPasswordRequest(email)
        this.forgottenPasswordEmailResent = true
      }
      setTimeout(() => {
        clearInterval(this.interval)
        this.resendTimer = 30
        if (emailType === 'verification') {
          this.verificationEmailResent = false
        } else {
          this.forgottenPasswordEmailResent = false
        }
      }, 30000)
    },
    decrementTime() {
      this.resendTimer = parseInt(this.resendTimer) - 1;
    },
    onCaptchaVerify(token) {
      this.reCaptchaError = null
      this.reCaptchaToken = token
    }
  }
});

@Component({
  components: { VueMarkdownIt },
  created () {
    this.setFooterDisabled(true)
    this.tabIdx = parseInt(this.tabIndex)
  }
})

export default class LoginOrSignup extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @SettingsGetter getPlatformName!: String
  @AccountAction loginUser
  @AccountAction registerUser
  @AccountAction setSecuredByPassword
  @AccountAction reconfirmEmailPublic
  @AccountAction resetPasswordRequest
  @AccountGetter email
  @AccountGetter isLoggedIn
  @GameLogsAction('getGameLogs') getGameLogsData
  @DepositAction("getDeposits") getDepositsData
  @AccountGetter ipLoginHits
  @AccountGetter emailLoginHits
  @AccountGetter isGlobalReCaptcha
  @AccountAction('getLoginAttempts') getLoginAttempts
}
