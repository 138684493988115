import MenuView from './menuView.js'
import { store } from '../../../../../store/index'

export default class FastMenuView extends MenuView {

  constructor (controller) {
    super(controller)
    this.menu = document.querySelector('#fast_menu')
    this.menuGameArea = this.menu.querySelector('#game_area')
    this.gameTypeSelectors = this.menu.querySelectorAll('.category_selector .game_category')
    this.showFavorite = this.menu.querySelector('.category_selector .favorite')
    this.backArrow = this.menu.querySelector('.controls.back')
    this.forwardArrow = this.menu.querySelector('.controls.forward')
    this.transparentCloseMenu = this.menu.querySelector('.close_menu')
    this.filteredGames = []
    this.currentIndex = 0
    this.currentPage = 1
    this.totalPages = 0

    this.gameAmount = 5
  }

  update (model) {
    super.update(model)
    this.drawGames(model)
    this.openMenu(model)
  }

  setUpHandlers () {
    super.setUpHandlers()
    this.setUpNavigationHandlers()
    this.setUpCloseMenuHandler()
  }

  setUpGameTypeHandlers () {
    this.gameTypeSelectors.forEach(el => {
      el.addEventListener('click', e => {
        this.currentIndex = 0
        const gameBodies = this._getGameBodies()
        gameBodies.css('transform', `translateX(0px)`)
        this.currentPage = 1
        this.menuGameArea.innerHTML = ''
        this.controller.toggleSlide({ categoryId: parseInt(e.target.getAttribute('data-category-id')) })
        this.disableControls()
      })
    })
  }

  setUpFavoriteGameHandler () {
    if (store.getters['account/isLoggedIn']) {
      this.showFavorite.addEventListener('click', () => {
        this.menuGameArea.innerHTML = ''
        this.controller.toggleSlide({ favorite: true })
      })
    }
  }

  setUpNavigationHandlers () {
    this.backArrow.addEventListener('click', (e) => {
      // eslint-disable-next-line no-undef
      if (!$(e.currentTarget).hasClass('disabled')) {
        this._handleMove(false)
      }
    })
    this.forwardArrow.addEventListener('click', (e) => {
      // eslint-disable-next-line no-undef
      if (!$(e.currentTarget).hasClass('disabled')) {
        this._handleMove(true)
      }
    })
  }

  setUpCloseMenuHandler () {
    this.transparentCloseMenu.addEventListener('click', () => {
      this.controller.closeMenu()
    })
  }

  openMenu (model) {
    if (model.open) {
      this.menu.classList.add('open')
    } else {
      this.menu.classList.remove('open')
    }
  }

  disableControls () {
    let canDecrease = this.currentIndex > 0
    let canIncrease = this.currentPage < this.totalPages && this.filteredGames.length > this.gameAmount
    canDecrease ? this.backArrow.classList.remove('disabled') : this.backArrow.classList.add('disabled')
    canIncrease ? this.forwardArrow.classList.remove('disabled') : this.forwardArrow.classList.add('disabled')
  }

  calcForwardAmount () {
    let gamesLeft = this.filteredGames.length - (this.controller.model.selectorIndex + this.gameAmount)
    return gamesLeft > this.gameAmount ? this.gameAmount : gamesLeft
  }

  //TODO:Improve this method, only run when necessary
  drawGames (model) {

    let games = model.visibleGames
    this.filteredGames = games
    this.totalPages = Math.ceil(this.filteredGames.length / this.gameAmount)

    for (let game of games) {
      game.view.addTo(this.menuGameArea)
    }
  }

  _getGameBodies () {
    // eslint-disable-next-line no-undef
    return $(this.menu.querySelectorAll('.game_body'))
  }

  _handleMove (isRight) {
    const gameBodies = this._getGameBodies()
    if (isRight) {
      this.currentIndex = this.currentIndex + this.gameAmount
      this.currentPage++
    } else {
      this.currentIndex = this.currentIndex - this.gameAmount < 0 ? 0 : this.currentIndex - this.gameAmount
      this.currentPage--
    }
    // eslint-disable-next-line no-undef
    gameBodies.css('transform', `translateX(-${$(gameBodies).outerWidth(true) * this.currentIndex}px)`)
    this.disableControls()
  }
}
