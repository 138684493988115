



















































import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const cookies = require('js-cookie')

const AccountAction = namespace('account').Action
const AccountMutation = namespace('account').Mutation

const AppProps = Vue.extend({
  props: {
    modalName: String,
    censoredEmail: String,
  },
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },

    resendEmailUpdate() {
      this.$emit('resend-email-update');
    },

    async logout() {
      const authToken = cookies.get('token');

      await this.logoutUser(authToken);

      cookies.remove('magicToken')
      cookies.remove('token')
      cookies.remove('banner_id')
      cookies.remove('play_real_dialog_shown')
      cookies.remove('userId')
      cookies.remove('isMagicTokenLogin')
      cookies.remove('firstLoginPending')
      sessionStorage.removeItem('session-id')

      this.setIsLoggedIn(false)
      this.setHasMagicToken(false)
      this.setMagicToken('')
      this.setToken('')
      this.setUserId('')

      const query = Object.assign({}, this.$route.query);
      delete query.account_key;
      this.$router.go()
    },
  },
})

@Component({
  components: {}
})

export default class BaseModal extends AppProps {
  @AccountAction logoutUser

  @AccountMutation setIsLoggedIn
  @AccountMutation setMagicToken
  @AccountMutation setHasMagicToken
  @AccountMutation setToken
  @AccountMutation setUserId
}
