
























































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import MaintenanceBanner from '../banners/MaintenanceBanner.vue'
import gql from 'graphql-tag'
import Common from '../../mixins/Common.vue'
import axios from 'axios'
import NavbarProgression from "@/components/widgets/WorldCupProgression/NavbarProgression.vue";


const cookies = require('js-cookie')
const AccountAction = namespace('account').Action
const AccountGetter = namespace('account').Getter
const DepositAction = namespace("deposit").Action
const SettingsGetter = namespace('settings').Getter
const DepositGetter = namespace("deposit").Getter
const WorldCupGetter = namespace("worldCup").Getter

const PaymentMethodsGetter = namespace('paymentMethods').Getter
const PaymentMethodsMutation = namespace('paymentMethods').Mutation
const PaymentMethodsAction = namespace('paymentMethods').Action

const AppProps = Vue.extend({
  mixins: [Common],
  props: {},
  data: () => {
    return {
      availablePaymentMethods: [],
      selectedPaymentMethodIdx: '',
      casino_code: cookies.get('casino_code'),
      countryCode: '',
      homePageBanners: [],
      VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
      VUE_APP_WMS_URL: process.env.VUE_APP_WMS_URL,
      isIndia: false,
      isMobile: false,
      renderModal: false,
      bannerImageLoaded: false
    }
  },
  watch: {
    selectedPaymentMethodIdx (val) {
      window.scrollTo(0, 0);
      if(val === '') return
      this.setSelectedPaymentMethod(this.selectedPaymentMethodIdx)
      this.selectedPaymentMethodIdx = ''
    },
    getSelectedPaymentMethod (newVal) {
      // opens deposit dialog
      if(newVal === -1) return
      $('#header-control-deposit').click()
    }
  },
  methods: {
    async depositClickHandler () {
      $('#header-control-deposit').click()
      if (!this.userAddress.length && !this.limitedAccount) {
        await this.getDepositAddress()
      }
    },
     getBannerImg(banner) {
        try {
          let banners = banner['images' + (this.$i18n.locale === 'en' ? '' : '_' + this.$i18n.locale)];
          if(banners && banners.length) {
            return `${this.VUE_APP_STRAPI_API_URL + banners[0].url}`;
          } else {
            return `${this.VUE_APP_STRAPI_API_URL + banner.images[0].url}`;
          }
        } catch (error) {
          console.log(error);
          // Returns english banner if other language fails
          return banner.images[0].url;
        }
      },
      getBannerImgMobile(banner) {
          try {
            let banners = banner['images_mobile' + (this.$i18n.locale === 'en' ? '' : '_' + this.$i18n.locale)];
            if(banners) {
              return `${this.VUE_APP_STRAPI_API_URL + banners.url}`;
            } else {
              return `${this.VUE_APP_STRAPI_API_URL + banner.images_mobile.url}`;
            }
          } catch (error) {
            console.log(error);
            // Returns english banner if other language fails
            return banner.images_mobile.url;
          }
      },
      async openMoonpayDialog() {
        this.updateParamsInLocation({ buy: true})
      },
      openSideShift() {
        this.updateParamsInLocation({ convert: true, playforrealdialog: true})
      },
      redirectOrAction(banner, catId) {
        if (catId) {
          $(`#cat_${catId}`).trigger('click');
          return;
        }
        let redirectLink = banner[`link${this.$i18n.locale === 'en' ? '' : '_' + this.$i18n.locale}`];
        window.location.href = redirectLink || banner.link;
      },
      openBonusCat() {
        $('#cat_55').trigger('click');
      },
    selectPaymentMethod () {
      this.openPaymentDialog = 'moonpay-apple'
    },
    async setupPaymentMethods() {
      let promiseArr = []

      await this.getAllCountriesList() // Call API for storing the list of country in the state
      let map = this.getCountries.map(country => country.countryCode)
      let idx = map.indexOf(this.userCountryCode.toUpperCase())
      if (idx < 0) idx = 0
      this.countryCode = this.getCountries[idx].countryCode

      // countrycode is the supported countries
      // userCountrycode is the ip country

      const payload = {
        filter_country: this.countryCode,
      };
      await this.getCountryDataWithPaymentMethod(payload)
      this.getPaymentMethods(this.countryCode).reduce((a, c) => {
        // if moonpay is true, map out payment methods
        if (c.moonpay === 'true') {
          let moonpayMethods = [];
          this.getPaymentMethod.forEach(paymentMethod =>
            moonpayMethods.push(paymentMethod.methodName)
          )
          moonpayMethods.map(
            method => (a = [...a, { aggregator: 'moonpay', method}])
          )

          this.availablePaymentMethods = a
        }

        if (c.xanpool === 'true' && this.getPlatformCurrency === 'BTC') {
          const url = '/api/xanpool/methods'
          promiseArr.push(axios.get(url))
        }
      }, [])
      if (promiseArr.length === 0) return
      try {
        let res = await Promise.all(promiseArr)
        // @ts-ignore
        if (res[0].data.buy) this.xanpoolPaymentMethods = res[0].data.buy
        this.xanpoolPaymentMethods
          .filter(item => item.country === this.userCountryCode)
          .map(item => item.methods)
          .forEach(item => {
            item.forEach(item =>
              this.availablePaymentMethods.push({
                method: item.method,
                aggregator: 'xanpool'
              })
            )
          })
      } catch (e) {
        throw new Error(e)
      }
    },
    showVerifyEmailPopup () {
      if (!this.emailVerified) {
        let iconSection = document.querySelector('#verify_email_mobile_dialog')
        let menu = document.querySelector('#verify_email_mobile_dialog')
        menu!.classList.add('active')
        iconSection!.classList.add('active')

        // eslint-disable-next-line
        ;($('#pre_email_verification_dialog') as any).lightbox_me({
          centered: true
        })
      } else {
        // redirect to promo page
        this.$router.push('promotion')
      }
    },
    openOverlay () {
      this.$emit('childToParent', true)
    },
    redirectToPromotionPage () {
      if (this.$route.query.banner_id !== undefined) {
        this.$router.push({
          name: 'promotion',
          query: { banner_id: this.$route.query.banner_id }
        })
      } else {
        this.$router.push('/promotion')
      }
    },
    redirectToSignUp() {
      this.$router.push('/signup')
    },
    closeModal() {
      cookies.set("learn_how_to_deposit_closed", "true", { expires: 1 });
      this.renderModal = false
    }
  },
  computed: {
    canShowLearnToDeposit() {
      return this.renderModal &&
        this.depositHistory.length == 0 &&
        cookies.get('learn_how_to_deposit_closed') !== "true"
    },
    showWorldCupProgress: function () {
      return this.isLoggedIn && !(['worldcup', '3game'].includes(this.$route.name)) && this.getMatches.length > 0;
    },
    filteredHomePageBanners: function() {
      return this.homePageBanners.filter((banner) => {
        if (this.isLoggedIn) {
          if (this.depositHistory.length > 0) {
            return banner.visible_for_ndc;
          }
          return banner.visible_for_nrc;
        }

        return banner.visible_on_los;
      });
    }
  },
  async beforeMount () {
    const initPaymentMethods = () => {
      if(this.userCountryCodeLoaded) {
        this.setupPaymentMethods()
      } else {
          window.setTimeout(function() {
            initPaymentMethods()
        }, 250);
      }
    }
    initPaymentMethods()

    this.isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent)
  },
  mounted() {
    const getDeposits = () => {
      const payload = {
        'startDate': '2017-02-01T10:46:03',
        'endDate': new Date(),
        'getAll': true,
        'platformCurrency': this.getPlatformCurrency
      }
      this.getDepositsData(payload);
    };
    getDeposits()

    setTimeout(() => {
      this.renderModal = this.depositHistory.length === 0
    }, 1000)
  },
  apollo: {
    homePageBanners: {
      // GraphQL Query
      query: gql`
        query HomePageBanners($casinoCode: String!) {
          casinos(where: { name: $casinoCode }) {
            home_page_banners {
              id
              order
              status
              link
              link_ru
              link_es
              link_pt
              excludeFrom
              openCategory
              logged_in_only
              images_mobile {
                url
              }
              images_mobile_ru {
                url
              }
              images_mobile_es {
                url
              }
              images_mobile_pt {
                url
              }
              images {
                url
              }
              images_ru {
                url
              }
              images_pt {
                url
              }
              images_es {
                url
              }
              hide_on_deposite
              visible_for_ndc
              visible_for_nrc
              visible_on_los
            }
          }
        }
      `,
      update: function(data) {
          let banners = data.casinos[0].home_page_banners.sort( (a, b) => parseInt(a.order) - parseInt(b.order) );
          //@ts-ignore
          banners = window.VueStore.getters["settings/getWalletManagerUrl"].includes("btctest.net") ?
            banners :
            banners.filter(b => b.status === "published");

          // Some banners are not visible for some countries
          banners = banners.filter(b => {
            if (b.excludeFrom) {
              //@ts-ignore
              return !b.excludeFrom.includes(this.userCountryCode);
            }
            return true;
          });
          return banners;
        },
      variables () {
        // Use vue reactive properties here
        return {
          casinoCode: this.casino_code
        }
      }
    }
  }
})
@Component({
  components: {
    MaintenanceBanner,
    NavbarProgression
  }
})
export default class HomeGameBanner extends AppProps {
  @AccountGetter email
  @AccountGetter userToken
  @AccountGetter userAddress
  @AccountGetter limitedAccount
  @AccountGetter userBonusBalance
  @AccountGetter signedMoonPayUrl
  @AccountAction getDepositAddress
  @AccountAction getSignedMoonpayUrl
  @AccountGetter userCountryCodeLoaded
  @AccountGetter emailVerified!: boolean
  @AccountGetter isLoggedIn: boolean
  @DepositAction("getDeposits") getDepositsData

  @DepositGetter depositHistory

  @SettingsGetter getMoonpayUrl!: String
  @SettingsGetter getMoonpayApiKey!: String
  @SettingsGetter getPlatformCurrency!: String

  @AccountGetter userCountryCode
  @PaymentMethodsGetter getCountries
  @PaymentMethodsGetter getCountryCurrencies
  @PaymentMethodsGetter getPaymentMethods
  @PaymentMethodsMutation setSelectedPaymentMethod
  @PaymentMethodsGetter getSelectedPaymentMethod
  @PaymentMethodsGetter getPaymentMethod
  @PaymentMethodsAction getAllCountriesList
  @PaymentMethodsAction getCountryDataWithPaymentMethod
  @WorldCupGetter getMatches
}
