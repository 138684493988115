



























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import { QrcodeStream } from 'vue-qrcode-reader';
import moment from 'moment-timezone';
import CopySpan from '@/components/macros/CopySpan.vue';
import VueJsonToCsv from 'vue-json-to-csv'

const SettingsGetter = namespace('settings').Getter
const DepositAction = namespace("deposit").Action
const WithdrawalAction = namespace("withdrawal").Action
const DepositGetter = namespace("deposit").Getter
const WithdrawalGetter = namespace("withdrawal").Getter
const GameLogsAction = namespace("gameLogs").Action
const GameLogsGetter = namespace("gameLogs").Getter
const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action
const SettingsAction = namespace('settings').Action

const AppProps = Vue.extend({
  data: () => {
    return {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        range: {},
        loading: false,
        search: '',
        expanded: [],
        isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
          navigator.userAgent
        ),
        allTableData: [],
        alreadyPlayedGames: false,
        depositsTableData: [],
        withdrawalsTableData: [],
        currentTransactionsSelectedTabIndex: 0,
        viewPassword: 0,
        date: new Date().toISOString().substr(0, 10),
        dates: [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        depositDates: [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        withdrawalDates: [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        dateFormatted: null,
        menu1: false,
        menu2: false,
        showQRScanner: false,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        today: new Date(),
        headers: []
      }
  },
  watch: {
    locale() {
      this.headers = [
          { text: this.$i18n.t('time_nma'), value: 'createdAt' },
          { text: this.$i18n.t('game_nma'), value: 'gameId', align: ' d-none d-sm-table-cell' },
          { text: this.$i18n.t('bet'), value: 'betsSum', hide: 'smAndDown' },
          { text: this.$i18n.t('bonus_bet'), value: 'bonusBetsSum', hide: 'smAndDown' },
          { text: this.$i18n.t('result'), value: 'balanceAfter', hide: 'smAndDown' },
          { text: this.$i18n.t('won'), value: 'winsSum'},
          { text: this.$i18n.t('bonus_win'), value: 'bonusWinsSum', hide: 'smAndDown' },
          { text: this.$i18n.t('round_id'), value: 'macroRoundId', hide: 'smAndDown' },
      ];
    },
    computedAlreadyPlayedGames() {
      this.alreadyPlayedGames = true;
    }
  },
  computed: {
    computedAllTableData() {
      return [...this.gameLogs.map(el => {
                el.dataType = 'gamelog';
                el.hashOrGameName = el.externalRef;
                el.gameId = el.gameProviderId === 'bitcoin' ? this.$i18n.t(el.gameId) : el.gameId
                return el
              })];
    },
    computedAlreadyPlayedGames() {
      return this.gameLogs.length
    },
    computedHeaders () {
      let headers = [...this.headers];
      headers = [
        { text: this.$i18n.t('time_nma'), value: 'createdAt', class: this.$i18n.locale },
        { text: this.$i18n.t('game_nma'), value: 'gameId', align: ' d-none d-sm-table-cell', class: this.$i18n.locale },
        { text: this.$i18n.t('bet'), value: 'betsSum', hide: 'smAndDown', class: this.$i18n.locale },
        { text: this.$i18n.t('bonus_bet'), value: 'bonusBetsSum', hide: 'smAndDown', class: this.$i18n.locale },
        { text: this.$i18n.t('result'), value: 'balanceAfter', hide: 'smAndDown', class: this.$i18n.locale },
        { text: this.$i18n.t('won'), value: 'winsSum', class: this.$i18n.locale},
        { text: this.$i18n.t('bonus_win'), value: 'bonusWinsSum', hide: 'smAndDown', class: this.$i18n.locale },
        { text: this.$i18n.t('round_id'), value: 'macroRoundId', hide: 'smAndDown', class: this.$i18n.locale },
      ];
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    dateRangeText () {
      if(this.dates && Array.isArray(this.dates)) {
        let dates = [...this.dates];
        return this.normalizeDates(dates).join(' ~ ');
      }
      else
        return ""
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    formattedStartDate () {
      return moment(this.startDate).format('L')
    },
    formattedEndDate () {
      return moment(this.endDate).format('L')
    },
  },
  props: ['locale'],
  async mounted () {
    await this.getGameLogs(true, this.normalizeDates(this.dates));
    if (this.gameLogs.length) {
      this.alreadyPlayedGames = true;
    }

    this.headers = [
      { text: this.$i18n.t('time_nma'), value: 'createdAt', class: this.$i18n.locale },
      { text: this.$i18n.t('game_nma'), value: 'gameId', hide: 'smAndDown', class: this.$i18n.locale },
      { text: this.$i18n.t('bet'), value: 'betsSum', hide: 'smAndDown', class: this.$i18n.locale },
      { text: this.$i18n.t('bonus_bet'), value: 'bonusBetsSum', hide: 'smAndDown', class: this.$i18n.locale },
      { text: this.$i18n.t('result'), value: 'balanceAfter', hide: 'smAndDown', class: this.$i18n.locale },
      { text: this.$i18n.t('won'), value: 'winsSum', class: this.$i18n.locale },
      { text: this.$i18n.t('bonus_win'), value: 'bonusWinsSum', hide: 'smAndDown', class: this.$i18n.locale },
      { text: this.$i18n.t('round_id'), value: 'macroRoundId', hide: 'smAndDown', class: this.$i18n.locale },
    ];
  },
  methods: {
    /**
     * @param arr Array of objects
     * Removes falsy properties from objects in the array
     * It also specifically treats amount as HRZN returns amount as:
     * {amount: { amount: 0.01, currency: 'BTC'} }
     * @example removeEmpty([{a: null, b: undefined, c: '', d: 100}])
     * @returns [{d: 100}]
     */
     removeEmpty(arr) {
      const newArr = arr.map((el: any) => ({...el}));
      newArr.forEach((obj, i) => {
        if (Array.isArray(obj.events)) {
          let eventsStr = obj.events[0].type + ', ' + (obj.winsSum > 0 ? "WIN" : "LOST");
          obj.events = eventsStr;
        }
      // eslint-disable-next-line no-unused-vars
      newArr[i] = Object.fromEntries(Object.entries(obj).filter(([_, v]) => 
            (!Array.isArray(v) && v)
            || (Array.isArray(v) && v.length)
        )); 
      });
      return newArr;
    },
    normalizeDates(dates) {
      if (Date.parse(dates[1]) < Date.parse(dates[0])) {
              let temp = dates[1];
              dates[1] = dates[0];
              dates[0] = temp;
      }
      return dates;
    },
    async updateDates(dataType:string) {
      switch (dataType) {
        case "all":
            this.loading = true;
            await this.getGameLogs(false, this.normalizeDates(this.dates));
            this.loading = false;
          break;
      }
    },
    moment: function () {
      return moment();
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    downloadCsvGameLogsClickHandler() {
      // eslint-disable-next-line no-unused-vars
      let filename = 'BitcoinCom_Games_Logs-'
      if ($('#btn_see_all_game_logs').data('seeAllGameLogs') === 'true') {
        filename += 'all'
      } else if (this.formattedStartDate === this.formattedEndDate) {
        filename += this.formattedStartDate
      } else {
        filename += this.formattedStartDate + '-' + this.formattedEndDate
      }
      //@ts-ignore
      // eslint-disable-next-line no-undef
      export_table_to_csv('#mygames', `${filename}` + '.csv')
    },

    async getGameLogs(getAll, dateRangeArr) {
      let payload = {
        'startDate': dateRangeArr[0],
        'endDate': dateRangeArr[1] || dateRangeArr[0],
        'getAll': getAll,
        'platformCurrency': this.getPlatformCurrency
      }
      await this.getGameLogsData(payload);
    },
    copyLink (payload) {
      try {
        this.isCopied = true;
        this.$copyText(payload)
        setTimeout(function () { this.isCopied = false }.bind(this), 1000)
      } catch (err) {
        console.log('Oops, unable to copy');
      }
    },
    dateToHumanReadable (dateStr) {
      return moment(dateStr).format("MM/DD/YYYY hh:mm")
    },
    formatGameLogResult(winAmount) {
      return winAmount;
    },
    formatGrossAmount(record) {
      return record.amount
    },
    noFormatFn(record) {
      return record;
    },
    humanizeGameName(txt){
      try {
        return txt.replace(/([A-Z+_])/g, ' $1').trim()
      } catch(e) {
        return txt;
      }
    },
    formatGameName(record) {
      return record.gameId ? record.gameId.charAt(0).toUpperCase() + record.gameId.slice(1) : record.externalRef;
    },
    formatRoundId(record) {
      return record.macroRoundId
    },
  }
})

@Component({
  components: {
    QrcodeStream,
    CopySpan,
    VueJsonToCsv
  }
})

export default class GamesReportsPanel extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @SettingsGetter getWithdrawalServiceFee
  @SettingsAction getWithdrawRules
  @AccountGetter hasUnconfirmedTx
  @AccountGetter twofaAuthEnabled
  @AccountGetter securedByPassword
  @AccountGetter legacyPassword
  @AccountGetter userBalance: Number
  @AccountAction getUserData
  @AccountAction getTotpSecretKey
  @AccountAction getFixedWithdrawalAddress
  @AccountAction getMagicTokenAndAuthData
  @AccountGetter userId!: String
  @SettingsGetter getPlatformName!: String
  @AccountAction createWithdrawal
  @AccountGetter magicToken!: String
  @AccountGetter fixedWithdrawalAddress!: String
  @DepositAction("getDeposits") getDepositsData
  @WithdrawalAction('getWithdrawals') getWithdrawalsData
  @DepositGetter depositHistory
  @WithdrawalGetter withdrawalHistory
  @GameLogsAction('getGameLogs') getGameLogsData
  @GameLogsGetter gameLogs
  @GameLogsGetter gamesByInternalGameId
}
