






































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import {_} from 'vue-underscore';
import FadingImg from "@/components/menus/FadingImg.vue";
import Button from "../global/Button.vue"

const GameAction = namespace('game').Action
const AccountGetter = namespace('account').Getter
const BonusWagerGetter = namespace("bonusWager").Getter

const AppProps = Vue.extend({
  props: [
    'gamesList',
    'category',
    'section',
    'filterByAggregator',
    'titleSearch'
  ],
  data: () => {
    return {
      limit : 20,
      page : 1,
      langs: ['en', 'es', 'pt', 'ru']
    }
  },
  methods: {
    async toggleFavorite (game) {
      if(!game.favorite) {
        let gameUpdate = _.find(this.gamesList, {'internalGameId': game.internalGameId})
        gameUpdate.favorite = true
        this.$forceUpdate()
        await this.favoriteGame(game.internalGameId)
      } else {
        let gameUpdate = _.find(this.gamesList, {'internalGameId': game.internalGameId})
        gameUpdate.favorite = false
        this.$forceUpdate()
        await this.unfavoriteGame(game.internalGameId)
      }
    },
    loadNext(e){
      e.preventDefault()
      let scrollTop = window.pageYOffset
      this.page += 1
      this.$nextTick(function () {
        window.scrollTo(0, scrollTop)
      })
    },
    canPlayForReal(game) {
      // If player has real (or bonus if it's not an exclusive game) balance,
      // or free spins for that particular game, allow him to play it for real;
      // otherwise modify 'play now' text and its link to open the deposit modal
      return this.activeFreeSpinsGamesIds.includes(game.gameId)
              || this.userBalance > 0
              || (this.userBonusBalance > 0 && game.isExclusive == 'false')
    },

    removeAggregator(name): void {
      this.$emit('remove-aggregator', name);
    },

    clearSearch(): void {
      this.$emit('clear-search')
    }
  },

  computed: {
    aggregators() {
      return this.gamesList.reduce((a, c) => {
        if (!a.includes(c.providerId)) a.push(c.providerId)
        return a
      }, [])
    },
    listAggregators(): string[] {
      const keys = Object.keys(this.aggregators)

      return keys.filter(key => {
        return this.aggregators[key]
      })
    },
  },

  mounted() {
    this.gamesList.filter(game => {
      if (!this.aggregators.includes(game.providerId)) {
        this.aggregators = [... this.aggregators, game.providerId]
      }
    })
  }
})
@Component({
  components: {
    FadingImg,
    Button
  }
})
export default class GamesListDesktop extends AppProps {
  @GameAction favoriteGame
  @GameAction unfavoriteGame
  @AccountGetter isLoggedIn
  @AccountGetter userBonusBalance
  @AccountGetter userBalance
  @BonusWagerGetter activeFreeSpinsGamesIds
}
