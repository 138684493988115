








import { Component, Vue } from "vue-property-decorator";
import gql from "graphql-tag";
import moment from "moment";
const cookies = require("js-cookie");
const AppProps = Vue.extend({
  props: {},
  data: () => {
    return {
      casino_code: cookies.get("casino_code"),
      VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
      activeMaintenance: {}
    };
  },
  filters: {
    localizedMaintenanceText(activeMaintenance, locale) {
      const titleLocalized = activeMaintenance['website_alert_text_' + locale]
      return (locale !== 'en' && titleLocalized) ? titleLocalized : activeMaintenance.website_alert_text
    }
  },
  apollo: {
    maintenances: {
      // GraphQL Query
      query: gql`
        query maintenances($status: String!) {
          maintenances(where: { status: $status }) {
            id
            status
            date_start
            date_end
            website_alert_text
            website_alert_text_pt
            website_alert_text_ru
            website_alert_text_es
          }
        }
      `,
      update: function(data) {
        let now = moment.utc().valueOf();
        this.maintenance = data.maintenances.forEach(maintenance => {
          let dateStart = moment(maintenance.date_start).valueOf();
          let dateEnd = moment(maintenance.date_end).valueOf();
          if (dateEnd > now && dateStart < now) {
            this.activeMaintenance = maintenance;
          }
        });
      },
      variables: {
        status: process.env.VUE_APP_WMS_URL.includes("btctest.net")
          ? "draft"
          : "published"
      }
    }
  }
});
@Component({
  components: {}
})
export default class MaintenanceBanner extends AppProps {}
