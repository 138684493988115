



























































import {Component, Vue} from "vue-property-decorator";

const AppProps = Vue.extend({
  props: {
    activePage: Number
  },
  data() {
    return {
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
          navigator.userAgent
      ),
    }
  },
})
@Component({})
export default class DepositDialogSkeleton extends AppProps {
}
