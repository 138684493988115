const axios = require('axios')
const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import {
  RootState,
  FreeSpinState,
  AvailablePromotionsCampaignState
} from './types'

type FreeSpinStateGetter = GetterTree<FreeSpinState, RootState>

export const state: FreeSpinState = {
  size: 20,
  number: 0,
  totalElements: 1,
  totalPages: 1,
  first: true,
  last: false,
  numberOfElements: 1,
  content: []
}

export const getters: FreeSpinStateGetter = {
  getFreeSpinsConnectedWithCampaign: state => state.content
}

export const mutations: MutationTree<FreeSpinState> = {
  setFreeSpins (state, content) {
    if (content) {
      state.content = content
    }
  }
}

export const actions: ActionTree<FreeSpinState, RootState> = {
  async getFreeSpinsCampaignWise ({ commit, rootGetters }, campaignUUID) {
    try {
      let endpoint = `/free_spin/free-spins/${rootGetters['account/userId']}?searchBy=${campaignUUID}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      let res = await axios(rqOptions)
      if (res.data) {
        commit('setFreeSpins', res.data.content)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('could not get free-spins for campaign')
    }
  }
}

export const freeSpins: Module<FreeSpinState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
