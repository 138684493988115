












































































































































import HeaderNav from "./HeaderNav.vue"
import SubHeader from "./SubHeader.vue"
import SelectLocale from './SelectLocale.vue'
import HeaderControls from "./HeaderControls.vue"
import LanguageSelector from "./LanguageSelector.vue";
import { Component, Vue } from "vue-property-decorator"
import Common from "../../mixins/Common.vue"
import { namespace } from "vuex-class"
import { loadLanguageAsync } from "@/helpers/i18n"

const cookies = require('js-cookie')
const AccountGetter = namespace("account").Getter
const SettingsGetter = namespace("settings").Getter
const AccountAction = namespace("account").Action
const AccountMutation = namespace("account").Mutation
const UIGetter = namespace('ui').Getter
const UIMutation = namespace('ui').Mutation
const BonusWagerGetter = namespace("bonusWager").Getter
const WorldCupGetter = namespace('worldCup').Getter;

const AppProps = Vue.extend({
  data: function() {
    return {
      showExp: false,
      avatar: 'static/images/misc/user-avatar.svg'
    }
  },
  computed: {
    currentLocale: function() {
      let names = {
        en: 'English',
        pt: 'Português',
        es: 'Español',
        ru: 'Русский'
      };

      return names[this.$i18n.locale];
    },
    currentFlag: function() {
      let flags = {
        en: 'gb',
        pt: 'br',
        es: 'es',
        ru: 'ru'
      };

      return flags[this.$i18n.locale];
    },
    isSecuredLoginPrompt: function() {
      return this.$route.name === 'login' && (this.securedByPassword || this.securedByTwoFA)
    },
    showWorldCupProgress: function () {
      return this.isLoggedIn && !(['worldcup', '3game'].includes(this.$route.name)) && this.getMatches.length > 0;
    }
  },
  methods: {
    async changeLocale(locale:string) {
      this.toggleDropDown('languageDropdown')
      if (locale !== this.$i18n.locale) {
        const loader = Vue.$loading.show()
        await loadLanguageAsync(locale)
        cookies.set('userLanguage', locale)
        // Send language update message to Angry Banker iframe
        //@ts-ignore
        document.getElementById('iframe_holder') && document.getElementById('iframe_holder').contentWindow.postMessage({
          sender: 'games-client', action: 'set_lang', lang: this.$i18n.locale
        }, this.getGamesRepoUrl);
        //@ts-ignore
        // document.getElementById('iframe_holder') && document.getElementById('iframe_holder').contentWindow.postMessage({
        //   sender: 'games-client', action: 'set_lang', lang: this.$i18n.locale
        // }, process.env.VUE_APP_ROCKET_SLOT_HOST);
        document.getElementById('iframe_holder') && this.sendMessageToIframe({sender: 'games-client', action: 'set_locale', locale: locale})
        await this.updateUserProfile({languageCode: locale})
        if (['3game',
             'blackjack',
             'keno',
             'videopoker',
             'slots',
             'dice',
             'roulette',
             'craps'].includes(this.$route.name)) {
             // Rerender the page because of the legacy js code manipulating/breaking vue template parts
             this.increaseVueAppKey()
          }
        loader.hide()
       }
    },
    sendMessageToIframe(message) {
      // @ts-ignore
      document.getElementById('iframe_holder').contentWindow.postMessage(message, this.getGamesRepoUrl);
      // // @ts-ignore
      // document.getElementById('iframe_holder').contentWindow.postMessage(message, process.env.VUE_APP_ROCKET_SLOT_HOST);
    },
    closeLangDropdown(event) {
      // Close dropdown unless it's an element that triggered its opening n the first place
      if (event.target.classList && !event.target.classList.contains('dropbtn')) {
        this.toggleDropDown('languageDropdown', close)
      }
    },
    showProvablyFair() {
      // @ts-ignore
      $("#provably_fair_explain_dialog").lightbox_me({centered: true, });
    },
    showExpectedReturn() {
      // @ts-ignore
      $("#expected_return_dialog").lightbox_me({centered: true, });
    },
    openNav() {
      document.getElementById("hamburger-sidenav").style.width = "75%";
      $('<div class="nav-backdrop"></div>').appendTo(document.body);
      $("body").addClass("backdrop-open");

    },
    login(): void {
      this.$router.push({ name:'player-login' })
    },
    signup(): void {
      this.$router.push({ name:'signup' })
    },
    closeNav() {
      document.getElementById("hamburger-sidenav").style.width = "0";
      document.getElementById("header-top").style.visibility = "visible";
      $(".nav-backdrop").remove();
      $("body").removeClass("backdrop-open")
    },
    reloadToHome() {
      this.$router.replace("/");
    },
    toggleBonusBar(dropdownId) {
      if(this.getIsAvailableBonus) {
        this.toggleDropDown(dropdownId)
      }
    }
  }
});

@Component({
  mixins: [Common],
  components: {
    HeaderNav,
    SubHeader,
    SelectLocale,
    HeaderControls,
    LanguageSelector,
  },
  mounted() {

  },
  computed: {
    userIdDisplay() {
      return this.userId ? this.userId.slice(7, 18) : ''
    },
    usernameDisplay() {
      return this.username
        // If username exists
        ? this.username.slice(0, 7) === "player-"
          ? this.userIdDisplay
          : this.username
        // If username doesn't exist, fallback to userId (e.g. Horizon errors)
        : this.userIdDisplay
    }
  }
})
export default class Header extends AppProps {
  @UIGetter balanceDisabled!: boolean
  @UIMutation increaseVueAppKey
  @AccountGetter userId!: String;
  @AccountGetter hasUnconfirmedTx
  @AccountGetter userToken!: String;
  @AccountGetter userBalance!: String;
  @AccountGetter userAddress!: String;
  @AccountGetter lockedAccount;
  @AccountGetter userBonusBalance!: String;
  @AccountGetter username!: String;
  @AccountGetter usernameRetrieved!: boolean;
  @AccountGetter securedByPassword: boolean;
  @AccountGetter securedByTwoFA: boolean;
  @AccountGetter balanceLoaded;
  @AccountGetter emailVerified!: boolean;
  @AccountGetter isLoggedIn: boolean;
  @AccountMutation setWantsToLogout;
  @SettingsGetter getPlatformName!: String;
  @SettingsGetter getGamesRepoUrl: String;
  @SettingsGetter getPlatformCurrency!: String;
  @AccountGetter limitedAccount
  @AccountAction getDepositAddress;
  @AccountAction updateUserProfile;
  @BonusWagerGetter getBonusClaimTotalWager;
  @BonusWagerGetter getBonusClaimMaxWager;
  @BonusWagerGetter getIsAvailableBonus;
  @BonusWagerGetter getBonusWagerCampaignName;
  @WorldCupGetter getMatches
}
