import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'

import Home from '../pages/Home.vue'
import ThirdPartyGame from '../pages/games/ThirdPartyGame.vue'
import LoginOrSignup from '../pages/LoginOrSignup.vue'
import PasswordReset from '../pages/PasswordReset.vue'
import EmailVerification from '../pages/EmailVerification.vue'

import Roulette from '../pages/games/Roulette.vue'

const Faq = () => import('../pages/Faq.vue')
const Logout = () => import('../pages/Logout.vue')
const ProvablyFair = () => import('../pages/ProvablyFair.vue')
const Tos = () => import('../pages/Tos.vue')
const Privacy = () => import('../pages/Privacy.vue')
const NotFound = () => import('../pages/404.vue')
const Login = () => import('../pages/Login.vue')
const Promotion = () => import('../pages/Promotion.vue')
const ExitIframe = () => import('../pages/ExitIframe.vue')

const HowToDeposit = () => import('../pages/HowToDeposit.vue')
const Vip = () => import('../pages/Vip.vue')
const Maintenance = () => import('../pages/Maintenance.vue')
const Blackjack = () => import('../pages/games/Blackjack.vue')
const Craps = () => import('../pages/games/Craps.vue')
const Dice = () => import('../pages/games/Dice.vue')
const AngryBanker = () => import('../pages/games/AngryBanker.vue')
const Keno = () => import('../pages/games/Keno.vue')
const Slots = () => import('../pages/games/Slots.vue')
const SatoshiCircle = () => import('../pages/games/SatoshiCircle.vue')
const Videopoker = () => import('../pages/games/VideoPoker.vue')
const RocketSlot = () => import('../pages/games/RocketSlot.vue')

import LoadScript from 'vue-plugin-load-script'
import store from '@/store'

const cookies = require('js-cookie')

Vue.use(LoadScript)
Vue.use(VueRouter)

const childRoutes = [
  {
    path: '/home',
    name: 'home',
    alias: '/',
    component: Home
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance
  },
  {
    path: '/angrybanker',
    name: 'angrybanker',
    component: AngryBanker
  },
  {
    path: '/rocketslot',
    name: 'rocketslot',
    component: RocketSlot
  },
  {
    path: '/howtodeposit',
    name: 'howtodeposit',
    component: HowToDeposit
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/vip',
    name: 'vip',
    component: Vip
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: Promotion
  },
  {
    path: '/3game',
    name: '3game',
    component: ThirdPartyGame
  },
  // {
  //   path: '/blackjack-bitcoin',
  //   name: 'blackjack',
  //   component: Blackjack
  // },
  {
    path: '/bitcoincomroulette',
    name: 'roulette',
    component: Roulette
  },
  // {
  //   path: '/craps',
  //   name: 'craps',
  //   component: Craps
  // },
  {
    path: '/satoshicircle',
    name: 'satoshicirle',
    component: SatoshiCircle
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true
  },
  {
    path: '/player-login',
    name: 'player-login',
    component: LoginOrSignup,
    props: { tabIndex: 1 }
  },
  {
    path: '/signup',
    name: 'signup',
    component: LoginOrSignup,
    props: { tabIndex: 0 }
  },
  {
    path: '/loginorsignup',
    name: 'loginorsignup',
    redirect: '/player-login',
    component: LoginOrSignup,
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq
  },
  {
    path: '/provably_fair',
    name: 'ProvablyFair',
    component: ProvablyFair
  },
  {
    path: '/tos',
    name: 'tos',
    component: Tos
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/slots',
    name: 'slots',
    component: Slots
  },
  {
    path: '/videopoker',
    name: 'videopoker',
    component: Videopoker
  },
  // {
  //   path: '/dice',
  //   name: 'dice',
  //   component: Dice
  // },
  {
    path: '/keno',
    name: 'keno',
    component: Keno
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: PasswordReset
  },
  {
    path: '/verify-email',
    name: 'verifyEmail',
    component: EmailVerification
  },
  {
    path: '/exit_iframe',
    name: 'exit-iframe',
    component: ExitIframe
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  routes: [{
    // Include the locales you support between ()
    path: '/:locale(en|pt|ru|es)?',
    component: {
      beforeRouteEnter: setLocale,
      render(c) { return c('router-view'); }
    },
    children: childRoutes
  }],
  mode: 'history',
  scrollBehavior () {
    window.scrollTo(0, 0)
  }
})

router.beforeEach((to, from, next) => {
  // Keeping this part in case that any route in the future requires player to be logged in, like /promotions did before
  if (!to.query.account_key && to.matched.some(record => record.meta.requiresLogin) && !store.getters["account/isLoggedIn"]) {
    // Store btag in a cookie if it's present;
    // it will be stored by casino api/associated to user on the first login if the user is not registered yet
    if (to.query.btag !== undefined) {
      cookies.set('btag', to.query.btag, { expires: 30 })
    }

    // Store banner id for promotion purposes if it's present;
    // it will be used to auto opt in banner campaign on the first login if the user is not registered yet
    if (to.query.banner_id !== undefined) {
      cookies.set('banner_id', to.query.banner_id, { expires: 30 })
    }
    next({ name: 'loginorsignup', params: { tabIndex: '0' } })
    return
  } else {
    next()
  }
})

async function setLocale(to: any, from: any, next: any) {
  let { locale } = to.params; // url language i.e. (/en or /ru)
  
  if (!locale) {

    // Try to set language first from the cookie, then from Hrzn user profile;
    // then from the user's browser settings, with the last fallback in line being  English
    locale = cookies.get("userLanguage") || store.getters['account/userLanguage'] || navigator.language.split('-')[0]
  }

  if (cookies.get("userLanguage")) {
    if(locale !== cookies.get("userLanguage")) {
      cookies.set("userLanguage", locale);
    }
  }

  // Do something with locale, check availability of messages etc.
  //@ts-ignore
  // loadLanguageAsync(locale).then(() => next({query: to.query}))
  next();
}

export default router
