/**
 * Request object builder to use with axios requests
 *
 * @param rqType string Request type (GET, POST, etc.)
 * @param endpoint string Api endpoint to call
 * @param data object Request parameters to send
 * @param token string User JWT token
 */
const rqBuild = (rqType, endpoint, data: Object = {}, token?) => {
  let rqObj =  {
    method: rqType,
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }

  rqObj[rqType == 'GET' ? 'params' : 'data'] = data

  if (token) {
    rqObj.headers['Authorization'] = `Bearer ${token}`
  }
  return rqObj
}

export {
  rqBuild
}

