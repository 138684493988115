

















import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  data: function() {
      return {
        isCopied: false,
        showCopyIcon: 0,
        charCount: 0
      }
  },
  methods: {
    copyHandler (payload) {
      try {
        this.isCopied = true;
        this.$copyText(payload)
        //@ts-ignore
        setTimeout(function () { this.isCopied = false }.bind(this), 1000)
      } catch (err) {
        console.log('Oops, unable to copy');
      }
    },
    copyDivHandler () {
        if(this.isCopied) {
          setTimeout(function () { this.showCopyIcon = false }.bind(this), 1000)
        }
        else {
            this.showCopyIcon = false;
        }
    }
  },
  props: ['copyText', 'mobile', 'characterAmount', 'shortwidth', 'longwidth', 'floatRight', 'padding', 'showThreeDots', 'height'],
  computed: {
    cssVars () {
      return {
        '--shortwidth': this.shortwidth || '100%',
        '--longwidth': this.longwidth || '100%',
        '--padding': this.padding || '7px',
        '--height': this.height || 'auto'
      }
    }
  },
  mounted() {
    setTimeout(()=> {
      this.charCount = Math.floor(this.$refs.copyWrapper.clientWidth / 10)
    }, 500)
  }
})
@Component({
  components: {}
})
export default class CopySpan extends AppProps {
}
