

























import {Vue, Component} from 'vue-property-decorator';
import {loadLanguageAsync} from "@/helpers/i18n";
import {namespace} from "vuex-class";
import Common from "@/mixins/Common.vue";

const cookies = require('js-cookie');
const AccountAction = namespace("account").Action;
const UIMutation = namespace('ui').Mutation;
const SettingsGetter = namespace("settings").Getter;

@Component({
  props: {
    onlyFlag: {
      type: Boolean,
      default: false
    }
  },
  mixins: [Common]
})
export default class LanguageSelector extends Vue {
  @AccountAction updateUserProfile;
  @UIMutation increaseVueAppKey;
  @SettingsGetter getGamesRepoUrl: String;

  get currentLocale(): string {
    let names = {
      en: 'English',
      pt: 'Português',
      es: 'Español',
      ru: 'Русский'
    };
    return names[this.$i18n.locale];
  }

  get currentFlag(): string {
    let flags = {
      en: 'gb',
      pt: 'br',
      es: 'es',
      ru: 'ru'
    };
    return flags[this.$i18n.locale];
  }

  async changeLocale(locale: string) {
    if (locale !== this.$i18n.locale) {
      const loader = Vue.$loading.show()
      await loadLanguageAsync(locale)
      cookies.set('userLanguage', locale);
      // send language and locale change message to games
      document.getElementById('iframe_holder') && this.sendMessageToIframe({sender: 'games-client', action: 'set_lang', lang: this.$i18n.locale});
      document.getElementById('iframe_holder') && this.sendMessageToIframe({sender: 'games-client', action: 'set_locale', locale: locale});
      await this.updateUserProfile({languageCode: locale})
      if (['3game',
        'blackjack',
        'keno',
        'videopoker',
        'slots',
        'dice',
        'roulette',
        'craps'].includes(this.$route.name)) {
        // Rerender the page because of the legacy js code manipulating/breaking vue template parts
        this.increaseVueAppKey()
      }
      loader.hide()
    }
  }

  sendMessageToIframe(message) {
    // send message to games
    // @ts-ignore
    document.getElementById('iframe_holder').contentWindow.postMessage(message, this.getGamesRepoUrl);
    // @ts-ignore
    //document.getElementById('iframe_holder').contentWindow.postMessage(message, process.env.VUE_APP_ROCKET_SLOT_HOST);
  }
}
