import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, PaymentMethodsState } from './types'

const rqBuilder = require('../helpers/store').rqBuild
const cookies = require('js-cookie')
const axios = require('axios')

type PaymentMethodsGetter = GetterTree<PaymentMethodsState, RootState>

export const state: PaymentMethodsState = {
  countryCurrencies: [
    {
      country: 'AT',
      currency: 'EUR'
    },
    {
      country: 'AU',
      currency: 'AUD'
    },
    {
      country: 'BE',
      currency: 'EUR'
    },
    {
      country: 'BR',
      currency: 'BRL'
    },
    {
      country: 'CA',
      currency: 'CAD'
    },
    {
      country: 'CH',
      currency: 'CHF'
    },
    {
      country: 'CO',
      currency: 'COP'
    },
    {
      country: 'CY',
      currency: 'EUR'
    },
    {
      country: 'DE',
      currency: 'EUR'
    },
    {
      country: 'EE',
      currency: 'EUR'
    },
    {
      country: 'ES',
      currency: 'EUR'
    },
    {
      country: 'FI',
      currency: 'EUR'
    },
    {
      country: 'FR',
      currency: 'EUR'
    },
    {
      country: 'GB',
      currency: 'GBP'
    },
    {
      country: 'GR',
      currency: 'EUR'
    },
    {
      country: 'HK',
      currency: 'HKD'
    },
    {
      country: 'IN',
      currency: 'INR'
    },
    {
      country: 'ID',
      currency: 'IDR'
    },
    {
      country: 'IE',
      currency: 'EUR'
    },
    {
      country: 'IL',
      currency: 'ILS'
    },
    {
      country: 'IT',
      currency: 'EUR'
    },
    {
      country: 'KOR',
      currency: 'KRW'
    },
    {
      country: 'LV',
      currency: 'EUR'
    },
    {
      country: 'MT',
      currency: 'EUR'
    },
    {
      country: 'MX',
      currency: 'MXN'
    },
    {
      country: 'MY',
      currency: 'MYR'
    },
    {
      country: 'NG',
      currency: 'NGN'
    },
    {
      country: 'NL',
      currency: 'EUR'
    },
    {
      country: 'NO',
      currency: 'NOK'
    },
    {
      country: 'NZ',
      currency: 'NZD'
    },
    {
      country: 'PE',
      currency: 'PEN'
    },
    {
      country: 'PH',
      currency: 'PHP'
    },
    {
      country: 'PL',
      currency: 'PLN'
    },
    {
      country: 'PT',
      currency: 'EUR'
    },
    {
      country: 'RU',
      currency: 'RUB'
    },
    {
      country: 'SE',
      currency: 'SEK'
    },
    {
      country: 'SK',
      currency: 'EUR'
    },
    {
      country: 'SG',
      currency: 'SGN'
    },
    {
      country: 'SL',
      currency: 'EUR'
    },
    {
      country: 'TR',
      currency: 'TRY'
    },
    {
      country: 'TH',
      currency: 'THB'
    },
    {
      country: 'TW',
      currency: 'TWD'
    },
    {
      country: 'VN',
      currency: 'VND'
    },
    {
      country: 'ZA',
      currency: 'ZAR'
    }
  ],
  selectedPaymentMethod: undefined,
  token: cookies.get('token') || '',
  countriesList: [],
  paymentMethod: []
}

export const mutations: MutationTree<PaymentMethodsState> = {
  setSelectedPaymentMethod (state, payload) {
    state.selectedPaymentMethod = payload
  },

  setCountriesList(state, payload) {
    state.countriesList = payload;
  },

  setPaymentMethod(state, payload) {
    state.paymentMethod = payload;
  }
}

export const getters: PaymentMethodsGetter = {
  getCountries: state => state.countriesList,
  getCountryCurrencies: state => state.countryCurrencies,
  userToken: state => state.token,
  getPaymentMethod: state => state.paymentMethod,

  // used by HomeGameBanner
  getPaymentMethods: state => userCountryCode =>
    state.countriesList.filter(
      country => country.countryCode === userCountryCode
    ),
  getSelectedPaymentMethod: state => state.selectedPaymentMethod
}

export const actions: ActionTree<PaymentMethodsState, RootState> = {
  async getAllCountriesList ({commit, getters}) {    
    try {
      const endpoint = `/api/paymentMethods/countries`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {},
        getters.userToken
      )
      const res = await axios(rqOptions)
      if(res.data) {
        commit('setCountriesList', res.data.countries);
        return {
          success: true,
        }
      } else {
        throw new Error("could not retrieve countries list")
      }
    } catch (e) {
      console.log(e)
      console.log("Could not get countries list")
    }
  },

  async getCountryDataWithPaymentMethod ({commit, getters}, payload) {    
    try {
      const endpoint = `/api/paymentMethods`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        payload,
        getters.userToken
      )
      const res = await axios(rqOptions)
      if(res.data) {
        commit('setPaymentMethod', res.data.methods);
        return {
          success: true,
        }
      } else {
        throw new Error("could not retrieve payment method")
      }
    } catch (e) {
      console.log(e)
      console.log("Could not get payment method")
    }
  }
}

export const paymentMethods: Module<PaymentMethodsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
