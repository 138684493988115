<template>
  <div id="third_party_game">
    <div class="loader" v-if="!isAccountLoaded"></div>
  </div>
</template>

<script>
  export default {
    name: 'GameBox',
    props: {
      isAccountLoaded: Boolean
    }
  }
</script>

<style src="../css/loader.css"></style>
