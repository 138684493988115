








































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const GameAction = namespace('game').Action
const GameGetter = namespace('game').Getter
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  data: () => {
    return {
      game: {},
      realPlay: false,
      fullscreen: false,
      favorite: false,
      depositing: false,
      copied: false,
    }
  },
  methods: {
    realPlaySwitch(): void { 
      if (this.isLoggedIn) {
        const params = { ...this.$route.query };
        if (!this.realPlay) {
          params.demo = true;
          this.$router.push({ name: '3game', query: params })
        } else {
          delete params.demo;
          this.$router.push({ name: '3game', query: params });
        }
        this.$router.go();
      } else {
         window.location.href = "/player-login"
      }
    },
    openQuickDepositModal(): void {
      // @ts-ignore
      $('#cryptoQuickDeposit').modal('show');
    },
    copyAddress(): void {
      this.copied = true;
      navigator.clipboard.writeText(this.userAddress);
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    
    async toggleFavorite(): Promise<void> { 
      if (!this.favorite) {
        this.favorite = true
        await this.favoriteGame(this.game.internalGameId) 
      } else {
        this.favorite = false
        await this.unfavoriteGame(this.game.internalGameId)
      }
    },
  },

  computed: {
    gameName(): string {
      return this.games.find(game => game.gameId === this.$route.query.game_id)?.fullGameName
    },

    aggregator(): string {
      // capitalizes aggregator name 
      const game = this.games?.find(game => game.gameId === this.$route.query.game_id)
      return game ? `${game.providerAlias.charAt(0).toUpperCase()}${game.providerAlias.slice(1)}` : ''
    },
    canQuickDeposit() {
      return this.isLoggedIn && this.userAddress;
    },
  }, 

  created() {
    this.game = this.games.find(game => game.gameId === this.$route.query.game_id)
    this.realPlay = !this.$route.query.demo
    if(this.isLoggedIn){
      this.favorite = this.favoriteGames.includes(this.game.internalGameId)
    }
  }
})

@Component({
})
export default class GameController extends AppProps {
  @GameGetter games
  @GameGetter favoriteGames
  @AccountGetter isLoggedIn: boolean
  @GameAction favoriteGame
  @GameAction unfavoriteGame
  @AccountGetter userAddress
}
