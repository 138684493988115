



















































































































import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'

const UIMutation = namespace('ui').Mutation
const AccountAction = namespace('account').Action
const AppProps = Vue.extend({
  data: function () {
    return {
      error: '',
      msg: '',
      pw: '',
      pw_repeat: '',
      success: false,
      loading: false,
      passwordFieldType: {
        'input-reset-password': 'password',
        'input-conf-password': 'password',
      },
      isMobile: /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent)
    }
  },
  async mounted() {
    if (!this.$route.query.token) {
      this.loading = false
      this.error = 'the_link_has_expired'
    }
  },
  methods: {
    changePassword: async function () {
      this.error = ''
      this.msg = ''

      if(this.pw != this.pw_repeat) {
        this.error = "password_do_not_match"
        return
      }

      this.loading = true
      let payload = {
        password: this.pw,
        repeatPassword: this.pw,
        token: this.$route.query.token
      }
      try {
        let res = await this.resetPassword(payload)
        if (res.status === 200) {
          this.loading = false;
          this.success = true
        } else {
          this.loading = false;
          this.error = 'invalid_token'
        }
      } catch (e) {
        this.loading = false;
        this.error = 'invalid_token'
      }
    },
    logoImagePath() {
      return require('@/assets/images/common/' + (this.$store.getters['settings/getPlatformName'] === 'cashgames' ? 'cash_games_logo' : 'btc_games_logo') + '.png')
    },
    goHome() {
      this.$router.push('home')
    },
    goToLogin() {
      this.$router.push("/player-login")
    },
    switchPasswordVisibility(inputId) {
      this.passwordFieldType[inputId] = this.passwordFieldType[inputId] === 'password' ? 'text' : 'password'
    },
  }
})
@Component({
  created() {
    this.setFooterDisabled(true)
  },
  components: {}
})
export default class EmailVerification extends AppProps {
  @UIMutation('setSimpleFooter') setSimpleFooter
  @UIMutation('setFooterDisabled') setFooterDisabled
  @AccountAction verifyEmail
  @AccountAction resetPassword
}
