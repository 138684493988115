import {GetterTree, MutationTree, Module, ActionTree} from 'vuex'
import {WorldCupPredictionState, RootState} from './types'

const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
const axios = require('axios');

type WorldCupPredictionGetter = GetterTree<WorldCupPredictionState, RootState>

export const state: WorldCupPredictionState = {
    token: cookies.get('token') || '',
    betPredictions: [],
    wageredValue: 0,
}

export const getters: WorldCupPredictionGetter = {
    userToken: state => state.token,
    getBetPredictions: state => state.betPredictions,
    wageredvalue: state => state.wageredValue,
}

export const mutations: MutationTree<WorldCupPredictionState> = {
    setBetPredictions(state, payload) {
        state.betPredictions = payload;
    },
    setWageredValue(state, wageredValue) {
        state.wageredValue = wageredValue
    }
}

export const actions: ActionTree<WorldCupPredictionState, RootState> = {
    async setWorldCupPrediction ({getters}, data) {
        const endpoint = '/api/worldcup/prediction'
        try {
            const rqOptions = rqBuilder (
                'POST',
                endpoint,
                data,
                getters.userToken
            )
            const res = await axios(rqOptions)
            if(res.data) {
                return {
                    success: true,
                    message: res.data.message 
                }
            } else {
                return {
                    success: false,
                    message: res.data.message
                }
            }
        } catch (e) {
            console.log("Exception creating bet prediction")
            return {
                error: e
            }
        }
    },
    async getAllBetPredictions({commit, getters}) {
        try {
          const endpoint = `/api/worldcup/user/allbetprediction`
          const rqOptions = rqBuilder(
            'GET',
            endpoint,
            {},
            getters.userToken
          )
          const res = await axios(rqOptions)
          if (res.data) {
            commit('setBetPredictions', res.data.prediction);
          }
        } catch (e) {
          console.log('Could not get world cup predictions')
        }
    },
    async updateWorldCupPrediction ({getters}, data) {
        const endpoint = '/api/worldcup/update/prediction'
        try {
            const rqOptions = rqBuilder (
                'PATCH',
                endpoint,
                data,
                getters.userToken
            )
            const res = await axios(rqOptions)
            if(res.data) {
                return {
                    success: true,
                    message: res.data.message 
                }
            } else {
                return {
                    success: false,
                    message: res.data.message
                }
            }
        } catch (e) {
            console.log("Exception creating update bet prediction")
            return {
                error: e
            }
        }
    },
    async getWageredValue({commit, getters}) {
        try {
          const endpoint = `/api/worldcup/user/wageredvalue`
          const rqOptions = rqBuilder(
            'GET',
            endpoint,
            {},
            getters.userToken
          )
          const res = await axios(rqOptions)
          if (res.data) {
            commit('setWageredValue', res.data.wageredValue);
          }
        } catch (e) {
          console.log('Could not get world cup predictions')
        }
    }
}

export const WorldCupPrediction: Module<WorldCupPredictionState, RootState> = {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}