import GameModel from './gameModel.js'
import GameView from './gameView.js'
import GameController from './gameController.js'

export default class Game {

  constructor (gameData, parentController = null) {
    this.model = new GameModel(gameData)
    this.controller = new GameController(this.model, parentController)
    this.view = new GameView(this.controller)
  }

  clone () {
    let clone = new Game(JSON.parse(this.model.gameData))
    clone.controller.parentController = this.controller.parentController
    return clone
  }
}
