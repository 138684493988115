
































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueMarkdownIt from "vue-markdown-it";
const cookies = require('js-cookie');
const AccountAction = namespace("account").Action;
const AvailablePromotionsCampaignAction = namespace('availablePromotionsCampaign').Action

const AppProps = Vue.extend({
    data: () => {
        return {
            showToS: false,
            VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    props: {
        targetedPromotion: Object
    },

    methods: {
        clickOutside(e): void {
            cookies.set(`tpromotiondialogshown-${this.targetedPromotion.id}`, "true", { expires: 365 });
            if (e.target.id === 'modal-bg') this.closeModal()
        },
        closeModal(): void {
            cookies.set(`tpromotiondialogshown-${this.targetedPromotion.id}`, "true", { expires: 365 });
            this.$emit('close-modal')
        },
        async acceptTargetedPromotion() {
            cookies.set(`tpromotiondialogshown-${this.targetedPromotion.id}`, "true", { expires: 365 });
            if(this.targetedPromotion.isOptIn){
              await this.claimPromotionCampaign(this.targetedPromotion.campaignUUID);
            }
            this.$emit('close-modal');
            if (this.targetedPromotion.type === 'deposit_now'){
                await this.getDepositAddress();
                ($('#pre_deposit_dialog') as any).modal('show');
            } else {
                window.location.href = this.targetedPromotion.cta_link;
            }
        },
    },

    computed: {}
})

@Component({
    components: {
        VueMarkdownIt
    }
})
export default class MobileTargetedPromotionDialog extends AppProps {
    @AccountAction getDepositAddress
    @AvailablePromotionsCampaignAction claimPromotionCampaign
 }
