const axios = require('axios');
const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, PastPromotionsCampaignState } from './types'
import apolloClient from '../vue-apollo';
import gql from "graphql-tag";

type PastPromotionsCampaignGetter = GetterTree<PastPromotionsCampaignState, RootState>

const getDefaultState = () => {
  return {
    authorUUID: '',
    rewardUUID: '',
    status: '',
    data: {
      bonusUUID: '',
      playerUUID: '',
      label: '',
      currency: '',
      grantedAmount: {
        amount: 0,
        currency: ''
      },
      balance: {
        amount: 0,
        currency: ''
      },
      wagered: {
        amount: 0,
        currency: ''
      },
      amountToWage: {
        amount: 0,
        currency: ''
      },
      state: '',
      createdDate: '',
      startDate: '',
      endDate: '',
      expirationDate: '',
      prize: '',
      capping: {},
      claimable: false,
      bonusLifetime: 0,
      bonusType: '',
      operatorUUID: '',
      campaignUUID: '',
      cancellerUUID: '',
      cancellationReason: '',
      convertedAmount: 0,
      moneyTypePriority: '',
      brandId: '',
      templateUUID: '',
      lockedAmount: {
        amount: 0,
        currency: ''
      },
      maxBet: 0,
      maxBetSport: 0,
      rewardVersion: 0,
      initialLockedAmount: {
        amount: 0,
        currency: ''
      },
      lockAmountStrategy: '',
      bonusDuration: 0,
      description: '',
      requirements: '',
      wager_requirement: 0,
      image: '',
      campaign_name: '',
      terms_and_condition: '',
      is_deposit: false,
      uuid: '',
      name: '',
      authorUUID: '',
      status: '',
      gameId: '',
      lifetime: 0,
      amount: 0,
      played: 0,
      creationDate: '',
      reason: '',
      type: ''
    }
  }
}

export const state: PastPromotionsCampaignState = {
  past_promo_content: []
}

export const getters: PastPromotionsCampaignGetter = {
  getPastBonusData: state => state.past_promo_content
}

export const mutations: MutationTree<PastPromotionsCampaignState> = {
  async setBonusContent (state, content) {
    let defaultStateData = getDefaultState()
    let banner_id = cookies.get('banner_id');

    if(banner_id === undefined) {
      banner_id = 'default';
    }

    if(content) {
      const response = await apolloClient.query({
        query: gql`
            query Promos($casinoCode: String!, $status: String!, $bannerId: String!) {
              casinos(where: { name: $casinoCode }) {
                name
                promotions(
                  where: { status: $status, advertisements: { banner_id: $bannerId } }
                ) {
                  id
                  index
                  description
                  description_ru
                  description_es
                  description_pt
                  wager_requirement
                  campaign_name
                  requirements
                  requirements_ru
                  requirements_es
                  requirements_pt
                  terms_and_condition
                  terms_and_condition_ru
                  terms_and_condition_es
                  terms_and_condition_pt
                  buttonText
                  buttonText_ru
                  buttonText_es
                  buttonText_pt
                  auto_opt_in
                  redirectToAfterClaim
                  uuid
                  status
                  is_deposit
                  is_playnow
                  is_freespins
                  is_disabled
                  advertisements(where: { banner_id: $bannerId }) {
                    banner_id
                  }
                  image {
                    formats
                  }
                }
              }
            }
        `,
        variables: {
          status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published",
          casinoCode: cookies.get('casino_code'),
          bannerId: banner_id
        },
        fetchPolicy: "no-cache"
      });
      let casino_campaigns = response.data.casinos.find(c => c.name == cookies.get('casino_code'))
      for (let i = 0; i < content.length; i++) {
        let campaignData = [];
        let duplicateObject = state.past_promo_content.filter(
          function (data) {
            if (data.authorUUID === content[i].authorUUID) {
              data.data = { ...data.data, ...content[i].data }
              return data
            }
          }
        );
        if (duplicateObject.length === 0) {
          campaignData = casino_campaigns.promotions.filter(
            function (data) { return data.uuid == content[i].authorUUID }
          );

          if (campaignData.length > 0) {
            content[i].data = { ...defaultStateData.data, ...content[i].data, ...campaignData[0] }
            state.past_promo_content.push(content[i])
          }
        }
      }
    }
  },
  resetBonusContent(state) {
    state.past_promo_content = []
  }
}

export const actions: ActionTree<PastPromotionsCampaignState, RootState> = {
  async getPastBonuses({ commit, dispatch, rootGetters }) {
    try {
      let endpoint = `/reward_view/${rootGetters['account/userId']}?status=EXPIRED,CANCELLED,COMPLETED`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      let res = await axios(rqOptions)
      if (res.data) {
        commit('resetBonusContent')
        commit('setBonusContent', res.data.content)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('could not get past promotions')
    }
  },
  deletePastPromo({state},index) {
    state.past_promo_content.splice(index,1);
  }
}

export const pastPromotionsCampaign: Module<PastPromotionsCampaignState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
