



















































































import { Component, Vue } from "vue-property-decorator";
import Common from "../../mixins/Common.vue";
import { namespace } from "vuex-class";
import { loadLanguageAsync } from "@/helpers/i18n"
import LanguageSelector from "@/components/headers/LanguageSelector.vue";

const cookies = require('js-cookie')
const AccountGetter = namespace("account").Getter
const AccountAction = namespace("account").Action
const UIGetter = namespace("ui").Getter
const SettingsGetter = namespace('settings').Getter
const AccountMutation = namespace('account').Mutation

const AppProps = Vue.extend({
  methods: {
    showModal () {
      this.$modal.show('contact-us-modal');
      this.closeNav();
    },
    hideModal () {
      this.$modal.hide('contact-us-modal');
    },
    async changeLocale(locale:string) {
      this.toggleDropDown('languageDropdownMobile')
      if (locale !== this.$i18n.locale) {
        await loadLanguageAsync(locale)
        cookies.set('userLanguage', locale)
        await this.updateUserProfile({languageCode: locale})
        if (this.$route.name === '3game') {
          window.location.reload()
        }
      }
    },
    async showIframeDepositPopup() {
        this.limitedAccount ? this.$bvModal.show('new-restricted-region-modal') : ($('#pre_deposit_dialog')  as any).modal('show');
        $('body').prepend('<div class="bs-canvas-overlay bg-dark position-fixed w-100 h-100"></div>');
        $('.offcanvas-collapse').addClass('open');
        this.setCanvasOpen(true);
    },
    closeNav() {
      document.getElementById("hamburger-sidenav").style.width = "0";
      setTimeout(() => {
        $(".nav-backdrop").remove();
        $("body").removeClass("backdrop-open");
      }, 500);
    },
    reloadToHome() {
      this.$router.replace("/");
    },
    redirectToPromotionPage() {
      this.closeNav()
      if(this.$route.query.banner_id !== undefined) {
        this.$router.push({name:'promotion', query: {banner_id:this.$route.query.banner_id}});
      } else {
        this.$router.push('promotion');
      }
    },
    goToLoginAndSignup(tabIndex) {
      this.closeNav()

      if (tabIndex === 0) {
        this.$router.push({ name:'player-login' })
      } else {
        this.$router.push({ name:'signup' })
      }
    },
    onMyAccountModalOpen() {
      this.closeNav();
      this.$emit('setAccountModalTab', 0);
    },
    allowToAccess() {
      const showAfter = new Date('2022-11-20 00:00:00');
      const today = new Date();
      if (showAfter < today) {
        return true;
      }
      return false;
    }
  }
});

@Component({
  mixins: [Common],
  components: {LanguageSelector},
  mounted() {
    $("#about").on("show.bs.collapse", function() {
      document.getElementById("arrow").style.transform = "rotate(90deg)";
    });
    $("#about").on("hide.bs.collapse", function() {
      document.getElementById("arrow").style.transform = "rotate(-90deg)";
    });
  },
  computed: {
    currentLocale: function() {
      let names = {
        en: 'English',
        pt: 'Português',
        es: 'Español',
        ru: 'Русский'
      };

      return names[this.$i18n.locale];
    },
    currentFlag: function() {
      let flags = {
        en: 'gb',
        pt: 'br',
        es: 'es',
        ru: 'ru'
      };

      return flags[this.$i18n.locale];
    },
    userIdDisplay() {
      return this.userId ? this.userId.slice(7, 18) : "";
    },
    usernameDisplay() {
      return this.username
          ? // If username exists
          this.username.slice(0, 7) === "player-"
              ? this.userIdDisplay
              : this.username
          : // If username doesn't exist, fallback to userId (e.g. Horizon errors)
          this.userIdDisplay;
    }
  }
})
export default class Header extends AppProps {
  @UIGetter balanceDisabled!: boolean
  @AccountGetter userId!: String
  @AccountGetter hasUnconfirmedTx
  @AccountGetter userToken!: String
  @AccountGetter userBalance!: String
  @AccountGetter userAddress!: String
  @AccountGetter emailVerified
  @AccountGetter username!: String
  @AccountGetter balanceLoaded
  @AccountGetter isLoggedIn: boolean
  @AccountAction getDepositAddress
  @AccountAction updateUserProfile
  @SettingsGetter getPlatformName!: String
  @SettingsGetter getPlatformCurrency!: String
  @AccountMutation setCanvasOpen: boolean
}
