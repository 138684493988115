



















































































































import { Component, Vue } from "vue-property-decorator";

const AppProps = Vue.extend({
  props: {},
  methods: {}
});
@Component({
  components: {}
})
export default class ExpectedReturnDialog extends AppProps {}
