




















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from "vuex-class";

const AccountGetter = namespace("account").Getter;
const AppProps = Vue.extend({
  props: {},
  methods: {
    redirectToPromotionPage() {
      if(this.$route.query.banner_id !== undefined) {
        this.$router.push({name:'promotion', query: {banner_id:this.$route.query.banner_id}});
      } else {
        this.$router.push('promotion');
      }
    },
  }
})
@Component({
  components: {

  },
  methods: {
    showModal () {
        this.$modal.show('contact-us-modal');
    },
    showProvablyFair() {
      // @ts-ignore
      $('#provably_fair_explain_dialog').lightbox_me({})
    },
    showExpectedReturn() {
      // @ts-ignore
      $('#expected_return_dialog').lightbox_me({})
    }
  }
})
export default class HeaderNav extends AppProps {
  @AccountGetter emailVerified!: boolean
}
