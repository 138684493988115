import { store } from '../../../../../store/index'

export default class MenuView {

  constructor (controller) {
    let div = () => document.createElement('div')
    this.controller = controller
    this.gameTypeSelectors = [div()]
    this.drawnCategories = []
    this.controller.model.registerObserver(this)

    //Delay setUpHandlers so view elements are wired correctly
    window.setTimeout(this.setUpHandlers.bind(this))
  }

  update (model) {
    this.drawCategories(model)
    this.setActiveCategory(model)
    if (store.getters['account/isLoggedIn']) {
      this.setActiveFavorite(model)
    }
  }

  setUpHandlers () {
    this.setUpGameTypeHandlers()
    this.setUpFavoriteGameHandler()
  }

  setUpGameTypeHandlers () {
    console.error('Implement this function', this.setUpGameTypeHandlers)
  }

  setUpFavoriteGameHandler () {
    console.error('Implement this function', this.setUpFavoriteGameHandler)
  }

  drawCategories (model) {
    if (JSON.stringify(model.categories) === JSON.stringify(this.drawnCategories)) return
    this.drawnCategories = model.categories
    this.gameTypeSelectors.forEach(el => {
      let catId = parseInt(el.getAttribute('data-category-id'))
      let catTitle = ''
      try {
        catTitle = model.categories.filter(cat => cat.id === catId)[0].title
      } catch (e) {
        console.error('Could not retrieve category info')
      }
      el.innerText = catTitle
    })
  }

  setActiveCategory (model) {
    this.gameTypeSelectors.forEach(el => {
      el.classList.remove('active')
      let catId = parseInt(el.getAttribute('data-category-id'))
      if (model.activeCategory == catId) {
        el.classList.add('active')
      }
    })
  }

  setActiveFavorite (model) {
    if (this.showFavorite) {
      if (model.showFavorites) {
        this.showFavorite.classList.add('active')
      } else {
        this.showFavorite.classList.remove('active')
      }
    }
  }
}
