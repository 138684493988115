












































































import { Component, Vue } from 'vue-property-decorator';
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
import CopySpan from '@/components/macros/CopySpan.vue';
import MyAccountPanel from "@/components/panels/MyAccountPanel.vue";
import WithdrawalPanel from "@/components/panels/WithdrawalPanel.vue";
import GamesReportsPanel from "@/components/panels/GamesReportsPanel.vue";
import SecurityPanel from "@/components/panels/SecurityPanel.vue";
import TransactionsHistoryPanel from "@/components/panels/TransactionsHistoryPanel.vue";

const AppProps = Vue.extend({
  data: () => {
    return {
      tabs: ['Account', 'Transactions History', 'Security', 'Withdrawal', 'Games Reports'],
      tabsI18nKeys: ['my_account', 'transactions_history_nma', 'security_nma', 'withdrawals_nma', 'games_reports'],
      account_key: '',
      withdrawal_address: ''
    }
  },
  computed: {
    userIdDisplay() {
      return this.userId ? this.userId.slice(7, 18) : "";
    },
    usernameDisplay() {
      return this.username
        ? // If username exists
          this.username.slice(0, 7) === "player-"
          ? this.userIdDisplay
          : this.username
        : // If username doesn't exist, fallback to userId (e.g. Horizon errors)
          this.userIdDisplay;
    },
    hasEmailSet () {
      return this.email && (this.email.slice(0, 6) !== 'player' || this.email.slice(-7) !== 'hrzn.io')
    }
  },
  props: {
    currentSelectedTabIndex: {
      default: 0,
      type: Number
    }
  },
  async mounted () {
  },
  methods: {
    scrollToSelected (event) {
      this.$scrollTo('#' + event.target.value, 1000, { easing: 'ease', cancelable: false, offset: -80 })
      event.target.value = ''
    },
    validateEmail (email) {
      const re = /.+@.+\..+/
      return re.test(email)
    },
    async selectTab(index:number) {
      this.$emit('setAccountModalTab', index);
      if (index === 1) {
        await this.$refs.transactionsHistoryPanel.updateDates('all');
      }
      if (index === 4) {
        await this.$refs.gamesReportsPanel.updateDates('all');
      }
    }
  },
  async created () {}
})
@Component({
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    CopySpan,
    MyAccountPanel,
    WithdrawalPanel,
    SecurityPanel,
    TransactionsHistoryPanel,
    GamesReportsPanel
  }
})
export default class VerifyGame extends AppProps {}
