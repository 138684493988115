import MenuModel from './menuModel.js'
import FastMenuView from './fastMenuView.js'
import HomeMenuView from './homeMenuView.js'
import MenuController from './menuController.js'

class FastMenu {

  constructor () {
    this.model = new MenuModel()
    this.controller = new MenuController(this.model)
    this.view = new FastMenuView(this.controller)
  }
}

class HomeMenu {

  constructor () {
    this.model = new MenuModel()
    this.controller = new MenuController(this.model)
    this.view = new HomeMenuView(this.controller)
    this.controller.toggleSlide({ categoryId: 1 })
  }
}

export { FastMenu, HomeMenu }
