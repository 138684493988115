import Observable from '../Util/observable.js'

export default class MenuModel extends Observable {

  constructor () {
    super()
    //Constant, don't modify
    this.categories = []
    this.games = []
    this.clonesToUpdate = []
    this.featuredSections = []

    this.open = false
    this.filterOpen = false
    this.showFavorites = false

    this.activeCategory = 0

    this.searchString = ''
    this.gameProvider = ''
    this.filters = {}
    this.selectorIndex = 0

    this.visibleGames = []
  }
}
