const axios = require('axios')
const rqBuilder = require('../helpers/store').rqBuild
const cookies = require('js-cookie')
const satsMultiplier = 100000000
import router from '../router'
const bchaddr = require('bchaddrjs')
import { i18n } from '@/helpers/i18n'

import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, AccountState } from './types'

// there is vue-js code relies on having a casino-code available as a cookie
// cookie was previously only available after login
cookies.set('casino_code', process.env.VUE_APP_PLATFORM_NAME, { expires: 365 })
cookies.set('brand_id', process.env.VUE_APP_BRAND_ID, { expires: 365 })

type AccountGetter = GetterTree<AccountState, RootState>

export const state: AccountState = {
  token: cookies.get('token') || '',
  sessionToken: cookies.get('sessionToken') || '',
  userId: cookies.get('userId') || '',
  userCid: cookies.get('cid') || '',
  userCountryCode: '',
  magicToken: cookies.get('magicToken') || '',
  username: '',
  userIsActive: true,
  usernameRetrieved: false,
  email: '',
  emailVerified: false,
  depositIncomingUSDAmount: 0,
  depositIncomingCryptoAmount: 0,
  fixedWithdrawalAddress: '',
  realBalance: 0,
  bonusBalance: 0,
  totalBalance: 0,
  balanceCurrency: '',
  fakeBalance: 1,
  balanceLoaded: false,
  identifier: '',
  userAddress: '',
  totpEnabled: false,
  freespinsSeen: cookies.get('freespins') || [],
  securedByPassword: false,
  securedByTwoFA: false,
  isLegacyUser: false,
  limitedAccount: false,
  lockedAccount: false,
  // pixel tracking banner tag
  btag: '',
  blockedCountriesList: [],
  accumulatedDepositsAmount: -1,
  hasUnconfirmedTx: false,
  userCountryCodeLoaded: false,
  signedMoonPayUrl: '',
  userProfileLoaded: false,
  isLoggedIn: false,
  wantsToLogout: false,
  //For legacy users that have magic token, used on My account page flow synchronization
  hasMagicToken: false,
  unconfimedTxPaymentIdData: cookies.get('unconfimedTxPaymentIdData') || [],
  userLanguage: cookies.get('userLanguage') || '',
  ipLoginHits: null,
  emailLoginHits: null,
  isGlobalReCaptcha: null,
  canvasOpen: false,
}

export const getters: AccountGetter = {
  userId: state => state.userId,
  userCid: state => state.userCid,
  userLanguage: state => state.userLanguage,
  userCountryCode: state => state.userCountryCode,
  userIsActive: state => state.userIsActive,
  userSessionToken: state => state.sessionToken,
  userToken: state => state.token,
  magicToken: state => state.magicToken,
  userAddress: state => state.userAddress,
  email: state => state.email,
  username: state => state.username,
  identifier: state => state.identifier,
  usernameRetrieved: state => state.username,
  lockedAccount: state => state.lockedAccount,
  limitedAccount: state => state.limitedAccount,
  userBalance: state => state.realBalance,
  fakeBalance: state => state.fakeBalance,
  userBonusBalance: state => state.bonusBalance,
  userTotalBalance: state => state.totalBalance,
  balanceCurrency: state => state.balanceCurrency,
  emailVerified: state => state.emailVerified,
  depositIncomingUSDAmount: state => state.depositIncomingUSDAmount,
  depositIncomingCryptoAmount: state => state.depositIncomingCryptoAmount,
  fixedWithdrawalAddress: state => state.fixedWithdrawalAddress,
  userBalanceInSat: state =>
    Math.round((state.realBalance * satsMultiplier) / 10) * 10,
  fakeBalanceinSat: state =>
    Math.round((state.fakeBalance * satsMultiplier) / 10) * 10,
  bonusBalanceInSat: state =>
    Math.round((state.bonusBalance * satsMultiplier) / 10) * 10,
  balanceLoaded: state => state.balanceLoaded,
  twofaAuthEnabled: state => state.totpEnabled,
  getFreespinsSeen: state => state.freespinsSeen,
  securedByPassword: state => state.securedByPassword,
  isLegacyUser: state => state.isLegacyUser,
  securedByTwoFA: state => state.securedByTwoFA,
  hasUnconfirmedTx: state => state.hasUnconfirmedTx,
  userProfileLoaded: state => state.userProfileLoaded,
  isLoggedIn: state => state.isLoggedIn,
  wantsToLogout: state => state.wantsToLogout,
  hasMagicToken: state => state.hasMagicToken,
  signedMoonPayUrl: state => state.signedMoonPayUrl,
  userCountryCodeLoaded: state => state.userCountryCodeLoaded,
  ipLoginHits: state => state.ipLoginHits,
  emailLoginHits: state => state.emailLoginHits,
  isGlobalReCaptcha: state => state.isGlobalReCaptcha,
  blockedCountriesList: state =>state.blockedCountriesList,
  getCanvasOpen: state => state.canvasOpen,
}

export const mutations: MutationTree<AccountState> = {
  setUserIsActive (state, isActive) {
    state.userIsActive = isActive
  },
  setBlockedCountriesList(state, data){
    state.blockedCountriesList = data
  },
  setBalanceCurrency (state, currency) {
    state.balanceCurrency = currency
  },
  setUserCountryCode (state, countryCode) {
    state.userCountryCode = countryCode
    state.userCountryCodeLoaded = true
  },
  setIsLegacyUser (state, isLegacyUser) {
    state.isLegacyUser = isLegacyUser
  },
  setLockedAccount (state) {
    state.lockedAccount = true
  },
  setUserProfileLoaded (state) {
    state.userProfileLoaded = true
  },
  setDepositIncomingAmount (state, amount) {
    state.depositIncomingCryptoAmount = amount
  },
  setDepositIncomingCryptoAmountToZero (state) {
    state.depositIncomingCryptoAmount = 0
  },
  async setUnconfirmedTxTrue (state, payloadData) {
    state.hasUnconfirmedTx = true
    const payload = payloadData[0]
    window['dataLayer'] = window['dataLayer'] || []
    if (typeof state.unconfimedTxPaymentIdData === 'string') {
      state.unconfimedTxPaymentIdData = JSON.parse(
        state.unconfimedTxPaymentIdData
      )
    }
    // Values for pending deposit popup, that will show the sum of all pending deposits for the player
    let pendingDepositUSDAmount = 0
    let pendingDepositCryptoAmount = 0
    let BTCPrice = 0
    let BCHPrice = 0
    // Go through each of the pending deposits for player
    for (let i = 0; i < payload.length; i++) {
      // Check that deposit hasn't already been reported to google and in popup to player
      if (!state.unconfimedTxPaymentIdData.includes(payload[i].paymentId)) {
        state.unconfimedTxPaymentIdData.push(payload[i].paymentId)
        const googleTagEvent = {
          event: 'pending_deposit_popup',
          eventValue: null
        }
        if (payload[i].amount) {
          pendingDepositCryptoAmount += payload[i].amount.amount
          /*Convert BTC or BCH deposit amount to USD for GTM*/
          if (!BTCPrice || !BCHPrice) {
            const res = await axios.get(
              'https://markets.api.bitcoin.com/live/bitcoin'
            )
            if (res.status === 200 && res.data.data) {
              BTCPrice = res.data.data.BTC
              BCHPrice = res.data.data.BCH
            }
          }

          const casino_code = cookies.get('casino_code')
          let currentUserBalanceUSD = 0
          if (casino_code === 'games') {
            googleTagEvent.eventValue = BTCPrice * payload[i].amount.amount
            currentUserBalanceUSD += BTCPrice * state.realBalance
          }
          if (casino_code === 'cashgames') {
            googleTagEvent.eventValue = BCHPrice * payload[i].amount.amount
            currentUserBalanceUSD += BCHPrice * state.realBalance
          }

          pendingDepositUSDAmount += googleTagEvent.eventValue
          window['dataLayer'].push(googleTagEvent)

            if(cookies.get('cid')) {
              const urlPending = `/api/affreport?cid=${cookies.get('cid')}&payout=${googleTagEvent.eventValue}&et=pending_deposit_popup`
              axios.get(urlPending);

              const reportUSDBalance = `/api/affreport?cid=${cookies.get('cid')}&payout=${currentUserBalanceUSD}&et=pending_deposit_balance`
              axios.get(reportUSDBalance);
            }
          }
        }
      }

    if (pendingDepositUSDAmount) {
      state.depositIncomingUSDAmount = pendingDepositUSDAmount
    }
    if (pendingDepositCryptoAmount) {
      state.depositIncomingCryptoAmount = pendingDepositCryptoAmount
    }
    cookies.set(
      'unconfimedTxPaymentIdData',
      JSON.stringify(state.unconfimedTxPaymentIdData)
    )
  },
  setUnconfirmedTxFalse (state) {
    state.hasUnconfirmedTx = false
    state.unconfimedTxPaymentIdData = []
    cookies.set(
      'unconfimedTxPaymentIdData',
      JSON.stringify(state.unconfimedTxPaymentIdData)
    )
  },
  setLimitedAccount (state) {
    state.limitedAccount = true
  },
  unSetLimitedAccount(state){
    state.limitedAccount = false
  },
  setFreespinSeen (state, freespinId) {
    let freeSpinsAlreadySeen = cookies.get('freespins') || ''
    if (freeSpinsAlreadySeen.indexOf(freespinId) === -1) freeSpinsAlreadySeen += freespinId
    cookies.set('freespins', freeSpinsAlreadySeen)
    state.freespinsSeen = freeSpinsAlreadySeen
  },
  setSecuredByPassword (state, securedByPassword) {
    state.securedByPassword = securedByPassword
  },
  setSecuredByTwoFA (state, securedByTwoFA) {
    state.securedByTwoFA = securedByTwoFA
  },
  setFakeBalance (state, balanceInSats) {
    state.fakeBalance = Math.round(balanceInSats) / satsMultiplier
  },
  loginUser (state, userDetails) {
    state.token = userDetails.token
    state.sessionToken = userDetails.sessionToken || userDetails.sessionUUID
    state.userId = userDetails.uuid
    state.magicToken = userDetails.magicToken || ''
    if (userDetails.hasMagicToken) {
      state.hasMagicToken = true
    }
    const twentyMinutes = new Date(new Date().getTime() + 20 * 60 * 1000)
    cookies.set('sessionToken', userDetails.sessionToken, {
      expires: twentyMinutes
    })
    cookies.set('token', userDetails.token, { expires: 365 })
    cookies.set('userId', userDetails.uuid, { expires: 365 })
    cookies.set('magicToken', userDetails.magicToken, { expires: 365 })
    if (userDetails.identifier)
      cookies.set('cid', userDetails.identifier, { expires: 365 })
    if (userDetails.btag) {
      cookies.set('btag', userDetails.btag, { expires: 30 })
    }
    state.isLoggedIn = true
    console.log("is logged i")
  },
  setMagicToken(state, token) {
    state.magicToken = token
  },
  setHasMagicToken(state, hasMagicToken) {
    state.hasMagicToken = hasMagicToken
  },
  setToken(state, token) {
    state.token = token
  },
  setUserId(state, id) {
    state.userId = id
  },
  registerUser (state, userDetails) {
    if (userDetails.btag) {
      cookies.set('btag', userDetails.btag, { expires: 30 })
    }
    state.email = userDetails.email
    state.userId = userDetails.userId
    cookies.set('userId', userDetails.uuid, { expires: 365 })
  },
  setAddress (state, address) {
    state.userAddress = address
  },
  setFixedWithdrawalAddress (state, address) {
    state.fixedWithdrawalAddress = address
  },
  setTotpEnabled (state) {
    state.totpEnabled = true
  },
  setEmailVerified (state) {
    state.emailVerified = true
  },
  setIsLoggedIn(state, setTrue) {
    state.isLoggedIn = setTrue
  },
  setWantsToLogout(state, setTrue) {
    state.wantsToLogout = setTrue
  },
  setMoonpaySignedUrl (state, url) {
    state.signedMoonPayUrl = url
  },
  updateUserProfile (state, userData) {
    // if the CID is known on our side but not on HRZN
    // we should update it again
    // this is for fixing our oldest voluum users
    if (cookies.get('cid') && !userData.identifier) {
      this.dispatch('account/updateUserProfile', {
        identifier: cookies.get('cid')
      })
    }

    Object.keys(userData).forEach(function (item) {
      if (item === 'login') {
        state.username = userData[item]
        state.usernameRetrieved = true
      } else if (item === 'languageCode') {
        state.userLanguage = userData[item]
      } else if (Object.prototype.hasOwnProperty.call(state, item) && !['token'].includes(item))
      {
          if(userData[item].amount !== undefined) {
            state[item] = userData[item].amount
          } else {
            state[item] = userData[item]
          }
      }
    })
  },
  setRealBalance (state, balanceInSats) {
    if (balanceInSats > 0)
      state.realBalance = Math.round(balanceInSats) / satsMultiplier
  },
  async setBalance (state, payload) {
    let userBalanceUSD = 0
    const deposit_amount = payload.userBalance - state.realBalance
    state.realBalance = payload.userBalance
    state.bonusBalance = payload.bonusBalance
    state.totalBalance = payload.totalBalance
    state.balanceLoaded = true

    // Fire a Netrefer 'pixel' event in case there is a new deposit registered
    if (
      state.accumulatedDepositsAmount < payload.accumulatedDepositsAmount &&
      state.accumulatedDepositsAmount !== -1
    ) {
      // Check if it's first deposit for the player
      const actionName =
        state.accumulatedDepositsAmount === 0 ? 'First Deposit' : 'Any deposit'
      if (actionName === 'First Deposit' && cookies.get('cid')) {
        const currency = cookies.get('casino_code') === 'games' ? 'btc' : 'bch'
        const reportFirstDepositUrl = `/api/affreport?cid=${payload['userCid']}&payout=${userBalanceUSD}&et=first_deposit_${currency}`
        axios.get(reportFirstDepositUrl)
      }

      $.ajax({
        url:
          'https://tracking.netrefer.com/Tracking.svc/Track?to=both&clientID=' +
          window['cid'] +
          '&ActionName=' +
          actionName +
          '&b=all&s=all&TrackingTag=' +
          cookies.get('btag'),
        dataType: 'jsonp'
      })

      /*Convert BTC or BCH deposit amount to USD for GTM*/
      let deposit_amount_in_usd = 0
      await $.get('https://markets.api.bitcoin.com/live/bitcoin', function (
        res,
        status
      ) {
        if (status === 'success') {
          const data = res.data
          const casino_code = cookies.get('casino_code')
          if (casino_code === 'games') {
            deposit_amount_in_usd = data.BTC * deposit_amount
            userBalanceUSD = data.BTC * state.realBalance
          }
          if (casino_code === 'cashgames') {
            deposit_amount_in_usd = data.BCH * deposit_amount
            userBalanceUSD = data.BCH * state.realBalance
          }
        }
      })
      // Fire the GTM new deposit tag in case there is a new deposit registered
      window['dataLayer'] = window['dataLayer'] || []
      const googleTagEvent = {
        event: 'new_deposit_popup',
        eventValue: deposit_amount_in_usd
      }
      window['dataLayer'].push(googleTagEvent)
      if (cookies.get('cid')) {
        const url = `/api/affreport?cid=${cookies.get('cid')}&payout=${
          googleTagEvent.eventValue
        }&et=new_deposit_popup`
        axios.get(url)

        const reportDepositBalanceUrl = `/api/affreport?cid=${cookies.get(
          'cid'
        )}&payout=${userBalanceUSD}&et=new_deposit_popup_balance`
        axios.get(reportDepositBalanceUrl)
      }
    }

    state.accumulatedDepositsAmount = payload.accumulatedDepositsAmount
  },
  setLoginAttempts (state, payload) {
    state.ipLoginHits = payload.ipLoginHits ? payload.ipLoginHits : null
    state.emailLoginHits = payload.emailLoginHits ? payload.emailLoginHits : null
    state.isGlobalReCaptcha = payload.isGlobalReCaptcha === 'true'
  },
  setCanvasOpen (state, setTrue) {
    state.canvasOpen = setTrue
  }
}

export const actions: ActionTree<AccountState, RootState> = {
  /**
   * Fired when player enters an email on the 'forgot password' form and clicks on 'Send' to have an email
   * with reset password link sent to him
   */
  async resetPasswordRequest({commit, getters, rootState, rootGetters}, payload) {
    try {
      const endpoint = '/auth/password/reset/request?brandId=bccashgames'

      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          email: payload
        })
      const res = await axios(rqOptions)
      if (res.status === 200) {
        return res
      } else {
        throw new Error('Password recovery')
      }
    } catch(e) {
      return {
        error: 'Error sending password recovery email'
      }
    }
  },
  /**
   * Fired after the player is redirected to our page upon clicking on a reset password link in email,
   * when he/she enters old and new password and clicks 'confirm' to reset password
   */
  async resetPassword({commit, getters, rootState, rootGetters}, payload) {
    try {
      const endpoint = '/auth/password/reset'

      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          password: payload.password,
          repeatPassword: payload.repeatPassword,
          token: payload.token
        })
        console.log("CREATE CALL", rqOptions)
        const res = await axios(rqOptions)
        console.log(res, "res2")
        if (res.status === 200) {
          return res
        } else {
          throw new Error('server error')
        }
    } catch(e) {
      console.log(e, "error")
      throw new Error('cannot update password')
    }
  },
  async userIpCheck ({ commit }) {
    try {
      const endpoint = '/api/ipcheck'
      const rqOptions = rqBuilder('GET', endpoint, {})
      const res = await axios(rqOptions)
      if (res.data.country) {
        commit('setUserCountryCode', res.data.country)
        const blacklistedCountries = [
          'US', // United States
          'JP', // Japan
          'CR', // Costa Rica
          'CU', // Cuba
          'IR', // Iran
          'SY', // Syria
          'KP', // North Korea
          'BY', // Belarus
          'MM', // Myanmar (Burma)
          'BI', // Burundi
          'CF', // Central African Republic
          'CD', // Congo (the Democratic Republic of the)
          'CG', // Congo (the)
          'IQ', // Iraq
          'LB', // Lebanon
          'LY', // Libya
          'NI', // Nicaragua
          'SO', // Somalia
          'SD', // Sudan, The
          'SS', // South Sudan
          'VE', // Venezuela
          'YE', // Yemen
          'ZW', // Zimbabwe
          'FR', // France
          'VN', // Vietnam
          'SG' // Singapore
        ]
        if (blacklistedCountries.indexOf(res.data.country) != -1) {
          commit('setLimitedAccount')
        } else {
          commit('unSetLimitedAccount')
        }
      } else {
        commit('setUserCountryCode', '')
      }
    } catch (e) {
      throw new Error('cannot check user ip')
    }
    // commit('setUserCountryCode', '')
  },
  async getBlockedCountries({ commit }) {
    try {
      const endpoint = '/api/blockedcountry'
      const rqOptions = rqBuilder('GET', endpoint, {})
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setBlockedCountriesList', res.data.blockedCountries)
      }
    } catch (e) {
      throw new Error('cannot get blocked countries')
    }
  },
  async getUserFreeSpins ({ rootGetters }) {
    try {
      const endpoint = `/free_spin/free-spins/${cookies.get('userId')}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data.content) {
        return res.data.content.filter(freespin => freespin.status === 'ACTIVE')
      } else {
        throw new Error('invalid response from server')
      }
    } catch (e) {
      return {
        error: e
      }
    }
  },
  async setPlayerLoginData({getters})
  {
    try{
      const endpoint = 'api/playerLoginLogs'
      const rqOptions = rqBuilder(
        'POST',
        endpoint,
        {
          playerId: cookies.get('userId'),
          casinoCode: cookies.get('casino_code'),
        },
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.status == 201) {
        return {
          success: true,
          message: res.data.message
        }
      } else {
        return {
          success: false,
          message: res.data.message
        }
      } 
    }catch (e){
      console.log("ERROR",e)
    } 
  },
  async validUserBalanceType ({ commit, getters, rootGetters }, payload) {
    const endpoint = `/balance/v2/${payload.userId}`
    const rqOptions = rqBuilder(
      'GET',
      rootGetters['settings/getApiUrl'] + endpoint,
      {},
      payload.token
    )
    try {
      const res = await axios(rqOptions)
      if (res.data) {
        return res.data[rootGetters['settings/getPlatformCurrency']] !== undefined;
      } else {
        return {
          error: 'Error getting user balance',
          status: res.status
        }
      }
    } catch (e) {
      console.log('Exception getting valid balance type', e)
      return {
        error: e
      }
    }
  },
  async hasPendingTx ({ commit, getters, rootGetters }) {
    try {
      if (!cookies.get('userId')) {
        return {
          error: 'No user id present'
        }
      }
      const endpoint = `/payment_view_ng/payments/${cookies.get(
        'userId'
      )}?currency=${
        rootGetters['settings/getPlatformCurrency']
      }&type=Deposit&statuses=created`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        state.token
      )
      const res = await axios(rqOptions)
      if (res.data.content) {
        if (res.data.content.length > 0) {
          commit('setUnconfirmedTxTrue', [res.data.content, getters['userCid']])
        } else {
          commit('setUnconfirmedTxFalse')
        }
      } else {
        return {
          error: 'Error getting pending tx',
          status: res.status
        }
      }
    } catch (e) {
      console.log('error getting pending tx')
      return { error: e }
    }
  },
  async getUserBalance ({ commit, getters, rootGetters, dispatch }) {
    try {
      if(getters.lockedAccount || !getters.isLoggedIn) return
      if (!cookies.get('userId')) {
        return {
          error: 'No user id present'
        }
      }

      // balance endpoint now brings bonusBalance as well
      const balanceEndpoint = `/balance/v2/${cookies.get('userId')}/${rootGetters['settings/getPlatformCurrency']}`

      // new endpoint to get accumulated deposits
      const accumulatedPaymentEndpoint = `/accumulated-payment/payment_stats`

      let rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + balanceEndpoint,
        {},
        getters.userToken
      )
      const res = await axios(rqOptions)

      rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + accumulatedPaymentEndpoint,
        { playerId: cookies.get('userId'),
          time: new Date().toISOString(),
          currency: rootGetters['settings/getPlatformCurrency']
        },
        getters.userToken
      )
      let resAccumulatedPayments;
      try {
        resAccumulatedPayments = await axios(rqOptions)
      } catch(e) {
        console.log("Could not fetch accumulated payments");
        console.log(e)
      }

      if (res.data) {
        // bonusBalance now comes with walletIds that start with BONUS- for every bonus, for our case we just sum the amount
        const balanceData = res.data[rootGetters['settings/getPlatformCurrency']].balances.find(el => el.walletId == rootGetters['settings/getPlatformCurrency']);
        const bonusBalances = res.data[rootGetters['settings/getPlatformCurrency']].balances.filter(o => o.walletId.match(/BONUS/));
        let bonusBalance = 0;
        bonusBalances.forEach(bonus => {
          bonusBalance += bonus.amount;
        });
        // in case we ever change currency on a casino this is a fallback to be able to redirect to other casinos
        try {
         commit('setBalanceCurrency', balanceData.walletId.toUpperCase());
        } catch (e) {
          console.error(e);
        }
        if(balanceData.walletId != rootGetters['settings/getPlatformCurrency']) {
            const currentCasino = rootGetters['settings/getPlatformName']
            const redirectCasino = currentCasino === 'games' ? 'cashgames' : 'games'
            alert(i18n.t('warning_this_account_is', {redirectCasino: redirectCasino, currentCasino: currentCasino}))
            window.setTimeout( async function() {
              const magicToken = cookies.get('magicToken');
              const isMagicTokenLogin = cookies.get('isMagicTokenLogin');

              await dispatch('logoutUser', cookies.get('token'));

              if (isMagicTokenLogin === 'true') {
                window.location.href = `https://${redirectCasino}.bitcoin.com?account_key=${magicToken}`
              } else {
                window.location.href = `https://${redirectCasino}.bitcoin.com/player-login`
              }
          }, 2000 );
        }
        let accumulatedPayments = 0;
        try {
          accumulatedPayments = resAccumulatedPayments.data.dep_sum_lt;
        } catch (e) {
          console.log("Error setting accumulated payments");
          console.log(e);
          accumulatedPayments = 0;
        }
        const payload = {
          userBalance: balanceData.amount,
          bonusBalance: bonusBalance,
          totalBalance: balanceData.amount + bonusBalance,
          accumulatedDepositsAmount: accumulatedPayments,
          userCid: getters['userCid']
        }
        dispatch('bonusWager/getActiveAndPendingPromotions', null, { root: true })
        return commit('setBalance', payload)
      } else {
        return {
          error: 'Error getting user balance',
          status: res.status
        }
      }
    } catch (e) {
      if (e.response.status === 401) {
        await dispatch('logoutUser', cookies.get('token'));
        location.href = '/'
      }
      console.log("Exception getting user balance, attempt a relogin")
      console.log(e);
      if(e.response && e.response.data && e.response.data.jwtError == "LOCKED") {
        commit("setLockedAccount")
        alert(i18n.t('your_account_is_locked'))
      }
      // if log into different casino then this error will be returned
      if (e.response && e.response.data && e.response.data.error === "error.real_balance.not-found") {
        const currentCasino = rootGetters['settings/getPlatformName']
        const redirectCasino = currentCasino === 'games' ? 'cashgames' : 'games'
        alert(i18n.t('warning_this_account_is', {redirectCasino: redirectCasino, currentCasino: currentCasino}))
        window.setTimeout( async function() {
          const magicToken = cookies.get('magicToken');
          const isMagicTokenLogin = cookies.get('isMagicTokenLogin');

          await dispatch('logoutUser', cookies.get('token'));

          if (isMagicTokenLogin === 'true') {
            window.location.href = `https://${redirectCasino}.bitcoin.com?account_key=${magicToken}`
          } else {
            window.location.href = `https://${redirectCasino}.bitcoin.com/player-login`
          }
        }, 2000 );
      }
      return {
        error: e
      }
    }
  },
  /**
   * Updating password, different endpoints and params used for old magic token users and for new login/signup ones
   */
  async updatePassword ({ commit, getters, rootState, rootGetters }, payload) {
    try {
      let endpoint = `/auth/magictoken/${cookies.get('userId')}/password`
      let rqType = 'PUT'
      if (!payload.hasMagicToken) {
        endpoint = `/auth/credentials/password`
        rqType = 'POST'
      }
      const rqOptions = rqBuilder(
        rqType,
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          newPassword: payload.newPassword,
          oldPassword: payload.oldPassword
        },
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.status == 200) {
        commit('setSecuredByPassword', true)
        return {
          success: true
        }
      } else {
        return {
          error: i18n.t('error_updating_password'),
          status: res.status
        }
      }
    }
    catch(e) {
      let errMsg = i18n.t('could_not_set_new')
      if(e.response.data.error == 'error.validation.password.empty') errMsg = i18n.t('please_input_your_current')
      if(e.response.data.error == 'error.validation.password.nonEquals') errMsg = i18n.t('incorrect_current_password')
      if(e.response.data.error == 'error.validation.password.not_robust') errMsg = i18n.t('password_is_not_secure')
      throw new Error(errMsg.toString())
    }
  },
  async getMagicTokenAndAuthData({commit, getters, rootGetters}) {
    try {
      const endpoint = `/auth/magictoken/${cookies.get('userId')}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.status == 200) {
        commit('updateUserProfile', res.data)
        return {
          success: true,
          ...res.data
        }
      } else {
        return {
          error: res.error || 'Error retrieving magic token data'
        }
      }
    } catch (e) {
      console.log('Exception retrieving magic token data')
      return {
        error: e
      }
    }
  },
  async enableTwoFactorAuth (
    { commit, getters, rootState, rootGetters },
    code
  ) {
    try {
      const endpoint = `/auth/magictoken/${cookies.get('userId')}/totp`
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          totp: code
        },
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.status === 200) {
        commit('setTotpEnabled')
        return {
          success: true
        }
      }
    } catch (e) {
      let errType = ''
      const errorData = e.response.data
      if (errorData.error === 'error.access.unauthorized') {
        const additionalErrors = errorData.additional_errors.map(err => err.error)
        if (additionalErrors.indexOf('error.magic_token.2fa.invalid') != -1) {
          errType = 'codeInvalid'
        }
      }
      console.log('Exception enabling 2fa')
      return {
        error: errType
      }
    }
  },
  async getDepositAddress ({ commit, getters, rootGetters, dispatch }) {
    try {
      if (getters.limitedAccount) {
        throw new Error('cannot create address for this region')
      }
      if(getters.balanceCurrency !== rootGetters['settings/getPlatformCurrency']) {
        const currentCasino = rootGetters['settings/getPlatformName']
        const redirectCasino = currentCasino === 'games' ? 'cashgames' : 'games'
        alert(i18n.t('warning_this_account_is', {redirectCasino: redirectCasino, currentCasino: currentCasino}))
          window.setTimeout( async function() {
            const magicToken = cookies.get('magicToken');
            const isMagicTokenLogin = cookies.get('isMagicTokenLogin');

            await dispatch('logoutUser', cookies.get('token'));

            if (isMagicTokenLogin === 'true') {
              window.location.href = `https://${redirectCasino}.bitcoin.com?account_key=${magicToken}`
            } else {
              window.location.href = `https://${redirectCasino}.bitcoin.com/player-login`
            }
        }, 2000 );
        throw new Error('Currency mismatch')
      }
      const endpoint =
        '/api/wms/user/address?currency=' +
        rootGetters['settings/getPlatformCurrency']
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getWalletManagerUrl'] + endpoint,
        {},
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.data) {
        let userAddress = res.data.address
        const currency = rootGetters['settings/getPlatformCurrency']
        if (currency == 'BCH' && bchaddr.isLegacyAddress(userAddress)) {
          userAddress = bchaddr.toCashAddress(userAddress)
        }
        return commit('setAddress', userAddress)
      } else {
        return {
          error: 'Error retrieving deposit address'
        }
      }
    } catch (e) {
      if (e.response.status === 401) {
        await dispatch('logoutUser', cookies.get('token'));
        location.href = '/'
      }
      console.log('Exception retrieving deposit address')
      return {
        error: e
      }
    }
  },
  async getFixedWithdrawalAddress({commit, getters, rootGetters}) {
    try {
      if(!getters['userId']) {
        return;
      }
      const endpoint = '/api/wms/user/withdrawal/address'
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getWalletManagerUrl'] + endpoint,
        {
          currency: rootGetters['settings/getPlatformCurrency']
        },
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit(
          'setFixedWithdrawalAddress',
          res.data.fixed_withdrawal_address || ''
        )
        return res.data.fixed_withdrawal_address || ''
      } else {
        throw new Error('Invalid response from server')
      }
    } catch (e) {
      console.log('Could not retrieve fixed withdrawal address')
      return null
    }
  },
  async getSignedMoonpayUrl (
    { commit, getters, rootState, rootGetters },
    moonpayUrl
  ) {
    try {
      const endpoint = '/api/wms/user/deposit/signedurl'
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getWalletManagerUrl'] + endpoint,
        {
          url: moonpayUrl
        },
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setMoonpaySignedUrl', res.data.signed_url || '')
        return res.data.signed_url || ''
      } else {
        throw new Error('Invalid response from server')
      }
    } catch (e) {
      console.log('Could not retrieve signed moonpay address')
      return null
    }
  },
  async createWithdrawal (
    { commit, getters, rootState, rootGetters },
    payload
  ) {
    try {
      const endpoint = '/api/wms/user/withdrawal'
      const objProps = {
        currency: rootGetters['settings/getPlatformCurrency'],
        address: payload.address,
        amount: parseFloat(payload.amount),
        magic_token: payload.magic_token,
        email: rootGetters['account/email']
      }
      if (parseInt(payload.totp)) {
        objProps['totp'] = parseInt(payload.totp)
      }
      if (payload.password) {
        objProps['password'] = payload.password
      }
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getWalletManagerUrl'] + endpoint,
        objProps,
        getters.userToken
      )
      const res = await axios(rqOptions)
      console.log('action', res)
      if (!res.error) {
        return {
          success: true,
          ...res.data
        }
      } else {
        return {
          error: res.error,
          status: res.status
        }
      }
    } catch (e) {
      console.log('Could not make a withdrawal')
      return {
        error: e.response
      }
    }
  },
  async setFixedWithdrawalAddress (
    { commit, getters, rootState, rootGetters },
    address
  ) {
    try {
      const endpoint = '/api/wms/user/withdrawal/address'
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getWalletManagerUrl'] + endpoint,
        {
          currency: rootGetters['settings/getPlatformCurrency'],
          address: address
        },
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (!res.error) {
        commit('setFixedWithdrawalAddress', address)
        return {
          success: true,
          ...res.data
        }
      } else
        return {
          error: res.error,
          status: res.status
        }
    } catch (e) {
      console.log('Could not set fixed withdrawal address')
      return {
        error: e
      }
    }
  },
  setSecuredByPassword (
    { commit },
    securedByPassword
  ) {
    try {
      commit('setSecuredByPassword', securedByPassword)
    } catch (e) {
      console.log('Could not set fixed securedByPassword address')
      return {
        error: e
      }
    }
  },
  setIsLegacyUser (
    { commit },
    isLegacyUser
  ) {
    try {
      commit('setIsLegacyUser', isLegacyUser)
    } catch (e) {
      console.log('Could not set isLegacyUser')
      return {
        error: e
      }
    }
  },
  async retrieveJWTFromAccountKey (
    { commit, dispatch, getters, rootState, rootGetters },
    payload
  ) {
    try {
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + `/auth/public/magictoken/signin`,
        {
          magicToken: payload.accountKey,
          brandId: rootGetters['settings/getBrandId'],
          password: payload.password,
          totp: payload.totp
        }
      )
      const res = await axios(rqOptions)
      if (res.data.playerUUID) {
        // check if the users casino code is actually correct
        // we can do this by checking the currency on his account
        // we need to prevent saving of this account key incase it is incorrect
        const validCurrency = await dispatch('validUserBalanceType', {userId: res.data.playerUUID, token: res.data.token})
        if(!validCurrency) {
            const currentCasino = rootGetters['settings/getPlatformName']
            const redirectCasino = currentCasino === 'games' ? 'cashgames' : 'games'
            alert(i18n.t('warning_this_account_is', {redirectCasino: redirectCasino, currentCasino: currentCasino}))
            window.setTimeout( async function() {
              const magicToken = payload.accountKey;

              await dispatch('logoutUser', cookies.get('token'));

              window.location.href = `https://${redirectCasino}.bitcoin.com?account_key=${magicToken}`
          }, 2000 );
        } else {
          commit('loginUser', {
            token: res.data.token,
            sessionToken: res.data.sessionUUID,
            uuid: res.data.playerUUID,
            magicToken: res.data.magicToken,
            hasMagicToken: true
          })
        }
        return {
          success: true
        }
      }
    } catch (e) {
      // check if user has a password or 2fa set
      const errorData = e.response.data
      if (errorData.error === 'error.access.unauthorized') {
        const additional_errors = errorData.additional_errors.map(
          err => err.error
        )
        const password_enabled =
          additional_errors.indexOf('error.magic_token.password.invalid') != -1
        const twofa_enabled =
          additional_errors.indexOf('error.magic_token.2fa.invalid') != -1
        commit('setSecuredByPassword', password_enabled)
        commit('setSecuredByTwoFA', twofa_enabled)
        if ((password_enabled || twofa_enabled) && (router.currentRoute.name !== 'login')) {
          router.push({
            name: 'login',
            params: {
              password_enabled: password_enabled.toString(),
              twofa_enabled: twofa_enabled.toString(),
              magic_token: payload.accountKey
            }
          })
        }
      } else if(errorData.error === "error.validation.failed") {
        commit('setSecuredByPassword', state.securedByPassword)
        commit('setSecuredByTwoFA', state.securedByTwoFA)
        if (router.currentRoute.name !== 'login') {
          router.push({
            name: 'login',
            params: {
              password_enabled: state.securedByPassword.toString(),
              twofa_enabled: state.securedByTwoFA.toString(),
              magic_token: payload.accountKey
            }
          })
        }
      } else {
        console.log('Exception signing in user')
        return {
          error: e
        }
      }
    }
  },
  async registerUser ({ dispatch, commit, getters, rootGetters }, query) {
    
    const { utm_source, utm_medium, utm_campaign, btag, email, password, currencySelected } = query

    const payload_data = {
      languageCode: cookies.get("userLanguage") || navigator.language.split('-')[0] || 'en',
      currency: currencySelected || rootGetters['settings/getPlatformCurrency']
    }


    payload_data['email'] = email
    payload_data['password'] = password
    payload_data['brandId'] = 'bccashgames'

    if(getters['userCountryCodeLoaded']) {
      payload_data['country'] = getters['userCountryCode']
    }
    // Set user's btag either from query param, or, if user had initially come from the affiliate page, take it from the cookie
    const btagToRegister = btag || cookies.get('btag')
    if (btagToRegister) {
      payload_data['btag'] = btagToRegister.toString()
      payload_data['affiliateId'] = 'bitcoin'
    }

    if (
      utm_source !== undefined ||
      utm_medium !== undefined ||
      utm_campaign !== undefined
    ) {
      payload_data[
        'btag'
      ] = `{"utm_source":"${utm_source}","utm_medium":"${utm_medium}","utm_campaign":"${utm_campaign}"`
      if (btag !== undefined) payload_data['btag'] += `,"btag":"${btag}"}`
      else {
        payload_data['btag'] += `,"btag":""}`
      }
    }

    // Not allowing player to send registration request from restricted country.
    if(getters['limitedAccount']) {
      return {
        error: 'Your country is restricted from accessing our service',
        code: 451
      }
    }

    try {
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + `/profile/public/signup`,
        payload_data
      )
      const res = await axios(rqOptions)
      if (res.data) {
        const regOBj = {
          email: email,
          userId: res.data.playerUUID,
          uuid: res.data.playerUUID
        }
        if(btagToRegister) {
          regOBj['btag'] = btagToRegister

          // Netrefer registrations tracking
          $.ajax({
            url:
              'https://tracking.netrefer.com/Tracking.svc/Track?to=both&clientID=' +
              window['cid'] + '&customerID=' + regOBj.uuid +
              '&ActionName=Registrations&b=all&s=all&TrackingTag=' +
              btag,
            dataType: 'jsonp'
          })
        }
        commit('registerUser', regOBj)
        return {
          success: res.data.playerUUID || email
        }
      } else {
        return {
          error: 'Error registering user',
          code: res.status
        }
      }
    } catch (e) {
      let errMsg = ''
      if (e.response.data.error == 'error.validation.failed'){
        errMsg = 'something_went_wrong'
        if (e.response.data.fields_errors?.email?.error == 'error.validation.email.exist'){
          errMsg = 'email_address_already_exists_nma'
        }
      }
      return {
        error: errMsg
      }
    }
  },
  async loginUser({ dispatch, commit, getters, rootGetters }, query) {
    const payload_data = {
      brandId: 'bccashgames',
    }
    const { email, password, reCaptchaToken } = query
    payload_data['login'] = email
    payload_data['password'] = password
    payload_data['type'] = 'email'
    payload_data['reCaptchaToken'] = reCaptchaToken

    // Not allowing player to login from restricted country.
    if(getters['limitedAccount']) {
      return {
        error: 'Your country is restricted from accessing our service',
        code: 451
      }
    }

    try {
      cookies.remove('token')
      const rqOptions = rqBuilder(
        'POST',
        `/api/login`,
        payload_data
      )
      const res = await axios(rqOptions)
      if (res.data) {
        cookies.remove('unverified_email');
        commit('loginUser', res.data)
        // Register btag if this is first successful login, through api
        if (cookies.get('firstLoginPending') && cookies.get('btag')) {
          this.dispatch('account/btagTrack', {
            btag: cookies.get('btag')
          })
        }
        return {
          success: res.data.playerUUID || email
        }
      } else {
      return {
        error: 'Error logging in user',
        code: res.status
      }
    }
    } catch (e) {
      if(e.response.data.errorType === 'recaptcha') {
        commit('setLoginAttempts', e.response.data)
        return {
          error: e.response.data.error
        }
      }
      const error = e.response.data.error.split('\n');
      const errorCode = error[0].split(' ')[1];
      if (errorCode === '401') {
        return {
          error: i18n.t('wrong_email_or_password')
        }
      }
      if(errorCode === '423') {
        const additionalError = JSON.parse(error[1]);
        if (additionalError.error === 'error.user.logon-attempts-limit-reached') {
          return {
            error: i18n.t('your_account_has_been_locked'),
            disableLoginButton: true
          }
        } else if (additionalError.error === 'error.user.locked') {
          cookies.set('unverified_email', email)
          return {
            triggerVerifyModal: true,
            error: i18n.t('please_verify_email_before_loggin_in'),
          }
        }
      }
      return {
        error: i18n.t('wrong_email_or_password')
      }
    }
  },
  async getUserData ({ dispatch, commit, getters, rootGetters }) {
    try {
      if(!getters['userId']) {
        return;
      }
      const endpoint = rootGetters['settings/getApiUrl'] + `/profile/profiles/${getters['userId']}`
      const rqOptions = rqBuilder('GET', endpoint, {}, getters.userToken)
      const res = await axios(rqOptions)
      commit('updateUserProfile', res.data)
      commit('setUserProfileLoaded')
      switch (res.status) {
        case 200:
          return res
        case 401:
          return { error: 'Unauthorized', status: 401 }
        case 403:
          return { error: 'Forbidden', status: 403 }
        case 404:
          return { error: 'Not Found', status: 404 }
      }
    } catch (e) {
      if (e.response.status === 401) {
        await dispatch('logoutUser', cookies.get('token'));
        location.href = '/'
      }
      console.log('Exception getting user data')
      return {
        error: e
      }
    }
  },
  async updateUserProfile (
    { commit, getters, rootState, rootGetters },
    userObj
  ) {
    try {
      if(!getters['userId']) {
        return;
      }
      const endpoint = rootGetters['settings/getApiUrl'] + `/profile/profiles/${getters['userId']}`
      const rqOptions = rqBuilder('PUT', endpoint, userObj, getters.userToken)
      const res = await axios(rqOptions)
      if (res.status === 200) {
        commit('updateUserProfile', userObj)
        return {
          success: true,
          data: userObj
        }
      } else {
        return {
          error: 'Error updating player profile',
          code: res.status
        }
      }
    } catch (e) {
      console.log('Exception updating player profile')
      return {
        error: e
      }
    }
  },
  async getTotpSecretKey({ getters, rootGetters }) {
    try {
      const endpoint = rootGetters['settings/getApiUrl'] + `/auth/magictoken/${cookies.get('userId')}/secretkey`
      const rqOptions = rqBuilder('GET', endpoint, {}, getters.userToken)
      const res = await axios(rqOptions)
      if (res.status === 200) {
        return {
          success: true,
          secretKey: res.data.secretKey
        }
      } else {
        return {
          error: 'Error retrieving the totp secret key',
          code: res.status
        }
      }
    } catch (e) {
      console.log('Exception retrieving totp secret key')
      return {
        error: e
      }
    }
  },
  async checkIfEmailExists ({ commit, getters, rootState, rootGetters }, data) {
    try {
      const endpoint = rootGetters['settings/getApiUrl'] + `profile/public/check/email`
      const rqOptions = rqBuilder('POST', endpoint, {
        'email': data.email,
        'brandId': rootGetters['settings/getBrandId']
      }, getters.userToken)
      const res = await axios(rqOptions)
      return res.status === 200
    } catch (e) {
      console.log('Exception checking if the email already exists')
      return {
        error: e
      }
    }
  },
  async updateEmail ({ commit, getters, rootState, rootGetters }, email) {
    try {
      const endpoint = rootGetters['settings/getApiUrl'] + `/profile/profiles/${getters['userId']}/email`
      const rqOptions = rqBuilder('PUT', endpoint, {
        'email': email
      }, getters.userToken)
      const res = await axios(rqOptions)
      if (res.status === 200) {
        commit('updateUserProfile', { email: email })
        return {
          success: true,
          email: email
        }
      } else {
        return {
          error: 'Error updating email',
          code: res.status
        }
      }
    } catch (e) {
      console.log("Exception updating user's email")
      return {
        error: e
      }
    }
  },
  async confirmEmail ({ commit, getters, rootState, rootGetters }, email) {
    try {
      const endpoint = rootGetters['settings/getApiUrl'] + `/profile/profiles/${getters['userId']}/reconfirm-email`
      const rqOptions = rqBuilder('POST', endpoint, {
        'email': email
      }, getters.userToken)
      const res = await axios(rqOptions)
      if (res.status === 200) {
        commit('updateUserProfile', { email: email })
        return {
          success: true,
          email: email
        }
      } else {
        return {
          error: 'Error updating email',
          code: res.status
        }
      }
    } catch (e) {
      console.log("Exception updating user's email")
      return {
        error: e
      }
    }
  },
  async reconfirmEmailPublic ({ commit, getters, rootState, rootGetters }, email) {
    try {
      const endpoint = rootGetters['settings/getApiUrl'] + `/profile/public/reconfirm-email`
      const rqOptions = rqBuilder('POST', endpoint, {
        'email': email,
        'brandId': 'bccashgames'
      })
      const res = await axios(rqOptions)
      if (res.status === 200) {
        return {
          success: true,
          email: email
        }
      } else {
        return {
          error: 'Error resending email',
          code: res.status
        }
      }
    } catch (e) {
      console.log('Exception resending verification email')
      return {
        error: e
      }
    }
  },
  async verifyEmail ({ commit, getters, rootState, rootGetters }, token) {
    try {
      const endpoint = rootGetters['settings/getApiUrl'] + `/profile/public/verification`
      const rqOptions = rqBuilder('POST', endpoint, {
        'verificationToken': token
      })
      const res = await axios(rqOptions)
      if (res.status === 200) {
        commit('updateUserProfile', { emailVerified: true })
        cookies.set('firstLoginPending', true, {expires: 2/24})
        return {
          success: true,
          verificationToken: token
        }
      } else {
        return {
          error: 'Error verifying email',
          code: res.status
        }
      }
    } catch (e) {
      console.log("Exception verifying user's email")
      return {
        error: e
      }
    }
  },
  async requestVip ({ getters, rootGetters }) {
    try {
      // Todo: which endpoint to use?
      // Todo: if player uses different email than the one he has registered with, what is the flow? He can have only one, as per Horizon
      const endpoint = ''
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        getters.userToken
      )
      const res = await axios(rqOptions)
      if (res.data) {
        return {
          success: true
        }
      } else {
        return {
          error: 'Error requesting VIP for the user',
          code: res.status
        }
      }
    } catch (e) {
      console.log('Exception requesting VIP for the user')
      return {
        error: e
      }
    }
  },
  async btagTrack ({ getters, rootGetters }, payload) {
    payload['player_id'] = cookies.get('userId')
    try {
      const endpoint='/api/btagtrack'
      const rqOptions = rqBuilder(
        'POST',
        endpoint,
        payload,
        getters.userToken
      )
      const res = await axios(rqOptions)
    } catch (e) {
      console.log('cannot call btag track api')
    }
  },
  async logoutUser ({commit, getters, rootGetters}, payload) {
    try {
      const endPoint = '/auth/logout';
      const rqOptions = rqBuilder(
          'POST',
          rootGetters['settings/getApiUrl'] + endPoint,
          {},
          payload
      )
      sessionStorage.clear();
      const res = await axios(rqOptions);
    } catch (e) {
      console.log('logout error: ', e.response)
    }
    cookies.remove('magicToken')
    cookies.remove('token')
    cookies.remove('banner_id')
    cookies.remove('play_real_dialog_shown')
    cookies.remove('userId')
    cookies.remove('isMagicTokenLogin')
    cookies.remove('firstLoginPending')
    cookies.remove('worldcup_dialog_dismissed')
    sessionStorage.removeItem('session-id')

    commit('setIsLoggedIn', false)
    commit('setHasMagicToken', false)
    commit('setMagicToken', '')
    commit('setToken', '')
    commit('setUserId', '')
  },
  async getLoginAttempts({commit, getters, rootGetters}) {
    try {
      const rqOptions = rqBuilder(
          'GET',
          '/api/login-attempts',
          {},
      )
      const res = await axios(rqOptions);
      if (res.data) {
        commit('setLoginAttempts', res.data)
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const account: Module<AccountState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
