import { render, staticRenderFns } from "./QuickDeposit.vue?vue&type=template&id=323f4479&scoped=true&"
import script from "./QuickDeposit.vue?vue&type=script&lang=ts&"
export * from "./QuickDeposit.vue?vue&type=script&lang=ts&"
import style0 from "./QuickDeposit.vue?vue&type=style&index=0&id=323f4479&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323f4479",
  null
  
)

export default component.exports