<template>
  <button
    :style="style"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <slot />
  </button>
</template>

<script>
/*
use .native on the parent component to bind event handlers to Button.vue:
<Button @click.native="function()" />
*/
export default {
  name: "Button",
  props: {
    fontSize: {
      type: String,
      required: false,
      default: "1rem",
    },
    fontWeight: {
      type: String,
      required: false,
      default: "bold",
    },
    color: {
      type: String,
      required: false,
      default: "white",
    },
    border: {
      type: String,
      required: false,
      default: "1px solid #0AC18E",
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#0AC18E",
    },
    hoverColor: {
      type: String,
      required: false,
      default: "#09ae80",
    },
    borderRadius: {
      type: String,
      required: false,
      default: "4px",
    },
    margin: {
      type: String,
      required: false,
      default: "0"
    },
    padding: {
      type: String,
      required: false,
      default: "12px 24px",
    },
    cursor: {
      type: String,
      required: false,
      default: "pointer"
    }
  },
  data: () => {
    return {
      isHover: false,
    };
  },
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        color: this.color,
        border: this.border,
        backgroundColor: this.isHover ? this.hoverColor : this.backgroundColor,
        borderRadius: this.borderRadius,
        margin: this.margin,
        padding: this.padding,
        cursor: this.cursor
      };
    },
  },
};
</script>

<style scoped>
button {
  border: none;
  outline: none;
  transition: 0.2s;
  white-space: nowrap;
  font-family: Gilroy-Regular, sans-serif;
}
</style>