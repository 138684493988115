






















import { namespace } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import QuickDeposit from '@/components/dialogs/QuickDeposit.vue'
import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import { i18n } from '@/helpers/i18n'
import GameBox from '@/pages/games/modules/GameBox.vue'
import GameboxNoLoad from '@/pages/games/modules/GameboxNoLoad.vue'
import GameController from './modules/GameController.vue'

const UIMutation = namespace('ui').Mutation
const GameAction = namespace('game').Action
const GameGetter = namespace('game').Getter
const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace('account').Action
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  data: () => {
    return {
      gameOverlayEnabled: true,
      logoEnabled: false,
      gameBoxText: '',
      primaryButtonText: '',
      primaryButtonHref: '',
      primaryButtonShowDepositModal: false,
      secondaryButtonText: '',
      secondaryButtonHref: '',
      LIVE_GAME_IDENTIFIERS: [
        'ezugi',
        'spinomenal:roulette',
        'spinomenal:baccarat',
        'spinomenal:blackjack',
        'spinomenal:lobby'
      ],
      isMobile: /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent),
      isAppleDevice: /iphone|ipod|ipad/i.test(navigator.userAgent),
      isAccountLoaded: false,
      providerIdsForNoScrollingProperties: ['wazdan'],
      providerIdsForSquareGameArea: ['wazdan'],
      isSquareGameArea: false
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play the hot new online games from popular providers like Pragmatic Play, Endorphina, Playson, Wazdan & many more at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Bitcoin Cash and Bitcoin Games`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  methods: {
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.$refs.gamearea.setAttribute('webkitAllowFullScreen', 'true')
        this.$refs.gamearea.setAttribute('mozAllowFullScreen', 'true')
        this.$refs.gamearea.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    },
    applyLiveGameStyling (gameIdentifier) {
      // currently values are hardcoded to disable the demo button on direct link
      // ticket has been created to create function to retrieve game meta data
      this.LIVE_GAME_IDENTIFIERS.forEach(provider => {
        if (gameIdentifier.toLowerCase().indexOf(provider) !== -1) {
          this.secondaryButtonHref = ''
        }
      })
    },
    async loadGame (gameIdentifier) {
      let session = {}

      if (!gameIdentifier) {
        gameIdentifier = this.$route.query.game_id
        if (gameIdentifier) {
          this.gameBoxText = ''
          this.primaryButtonHref = ''
          this.secondaryButtonHref = ''
          setTimeout(() => {
            if (this.logoEnabled) {
              this.gameOverlayEnabled = true
            }
            setTimeout(() => {
              if (this.logoEnabled) {
                this.gameOverlayEnabled = false
              }
            }, 1000)
          }, 3000)
        } else {

          return
        }
      }

      let showError = () => {
        this.gameOverlayEnabled = true
        this.logoEnabled = false
        this.gameBoxText = this.$i18n.t('error_loading_game')
        this.primaryButtonText = this.$i18n.t('go_home')
        this.primaryButtonShowDepositModal = false
        this.primaryButtonHref = '/home'
        this.secondaryButtonText = this.$i18n.t('try_again')
        this.secondaryButtonHref = ''
      }

      if (!this.games.length) {
        await this.getPublicGamesList()
      }

      const gameToLaunch = this.games.find((game) => {
        return game.gameId === gameIdentifier
      })

      if (!gameToLaunch) {
        console.log("Game Not Found Error:");
        showError()
        return
      }

      let urlParams = this.$route.query
      let startGameOptions = {
        brandId: this.getBrandId,
        lang: i18n.locale,
        currency: this.getPlatformCurrency,
        depositUrl: window.location.origin + '/?deposit=true',
        cashierUrl: window.location.origin + '/?deposit=true',
        exitUrl: window.location.origin + '/exit_iframe',
      }
      if(gameToLaunch.providerId === "netent") {
        startGameOptions['type'] = gameToLaunch.type.toLowerCase();
      } else if (gameToLaunch.providerId === "playson") {
        startGameOptions['type'] = this.isMobile ? 'mobile' : 'desktop'
      }

      // Check player balance and free spin info if this is not a play for fun request
      if (!urlParams.demo) {
        let noBalance = async () => {
          if (urlParams.freespin) {
            return false
          }
          try {
            if (!this.balanceLoaded) {
              await this.getUserBalance()
            }

            if (this.userTotalBalance > 0) {
              return false
            }

            // PLACEHOLDER: emulate response to get freespin games
            let freespin_games = await this.getUserFreeSpins()
            for (const freespin_index in freespin_games) {
              if (freespin_games[freespin_index].gameId === gameIdentifier) {
                return false
              }
            }
          } catch (e) {
            return false
          }
          return true
        }

        if (await noBalance()) {
          this.gameOverlayEnabled = false
          this.logoEnabled = false
          this.gameBoxText = this.$i18n.t('insufficient_balance')
          this.primaryButtonText = this.$i18n.t('deposit')
          this.primaryButtonHref = '#'
          this.secondaryButtonText = this.$i18n.t('free_play')
          this.secondaryButtonHref = '/3game?demo=true&game_id=' + gameIdentifier
          this.primaryButtonShowDepositModal = true
          let timeoutId
          this.applyLiveGameStyling(gameIdentifier)
          let checkBalance = () => {
            if (this.userTotalBalance > 0) {
              this.gameOverlayEnabled = false
              this.loadGame(gameIdentifier)
              if (timeoutId) {
                clearTimeout(timeoutId as any)
              }
            } else {
              this.gameOverlayEnabled = true
              timeoutId = setTimeout(() => {
                if (!document.hidden) checkBalance()
              }, 3000)
            }
          }
          checkBalance()
          return
        }
        this.logoEnabled = true
        session = await this.initializeThirdPartyGameSession({
          ...startGameOptions,
          gameUrl: gameToLaunch.startGameUrl
      })
      } else {
        this.logoEnabled = true
        session = await this.initializeThirdPartyGameSession({
          ...startGameOptions,
          gameUrl: gameToLaunch.startFunGameUrl
        })
      }
      if (session['error']) {
        console.log("Session Error:");
        console.log(session);
        showError()
      } else {
        try {
          let gameLaunchOptions = {
            target_element: 'third_party_game',
            launch_options: {
              strategy: 'detect',
              game_url: session['gameSessionURL']
            }
          }
          if (!this.isAppleDevice) {
            gameLaunchOptions.launch_options.strategy = 'iframe'
          }
          let success = () => {
            setTimeout(() => this.logoEnabled = false, 300)
            setTimeout(() => this.gameOverlayEnabled = false, 4000)
            let iframe = document.querySelector('#third_party_game>iframe')
            if (this.isMobile) {
              iframe.setAttribute('webkitAllowFullScreen', 'true')
              iframe.setAttribute('mozAllowFullScreen', 'true')
              iframe.setAttribute('allow', 'autoplay')
              let div = document.createElement('div')
              div.id='thirdPartyOverlay'
              div.style.cssText = 'top: 0; left: 0; right: 0; bottom: 0; position: fixed'
              div.addEventListener('click', () => {
                if (iframe) {
                  this.$refs.gamearea.setAttribute('webkitAllowFullScreen', 'true')
                  this.$refs.gamearea.setAttribute('mozAllowFullScreen', 'true')
                  this.$refs.gamearea.requestFullscreen()
                }
                div.remove()
              })
              document.body.appendChild(div)
            }
            if(this.providerIdsForNoScrollingProperties.includes(gameToLaunch.providerId)) {
              iframe.setAttribute('scrolling', 'no');
            }
            iframe.setAttribute('allow', 'autoplay')
          }
          let error = () => {
            this.gameOverlayEnabled = true
            this.logoEnabled = false
          }
          window['sg'].launch(gameLaunchOptions, success.bind(this), error.bind(this))
        } catch (e) {
          console.log('Error:');
          console.log(e)
          showError()
        }
      }
    },
    initGame() {
      if (this.isLoggedIn) {
        this.isAccountLoaded = true;
      }
      let urlParams = this.$route.query
      let iframeScript = document.createElement('script')
      iframeScript.setAttribute('src', 'https://casino.cur.a8r.games/public/sg.js?' + Date.now())
      iframeScript.async = false;
      iframeScript.addEventListener('load', () => {
        this.loadGame(urlParams.game_id)
      });
      document.head.appendChild(iframeScript)
    }
  },
  created () {
    //@ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(true)
  },
  destroyed() {
    $(".notices.is-top").detach().appendTo("body")
    $('#thirdPartyOverlay').remove()
  },
  async mounted () {
    // Move the alert inside the gamearea in order for alerts to show up
    // when fullscreen. If notice is already present in the app, move it 
    // to the game area. otherwise create a div.
    if ($(".notices.is-top").length) {
      $(".notices.is-top").detach().appendTo("#gamearea_wrapper #gamearea_bordered")
    } else {
      $('#gamearea_wrapper #gamearea_bordered').append("<div class='notices is-top'></div>");
    }
    if(!this.userCountryCodeLoaded) {
      await this.userIpCheck();
    }

    if (!this.games.length) {
      await this.getPublicGamesList()
    }

    let urlParams = this.$route.query;
    let gameIdentifier = urlParams.game_id;
    if(gameIdentifier) {
      const game = this.games.find((game) => {
        return game.gameId === gameIdentifier
      })
      if(this.providerIdsForSquareGameArea.includes(game.providerId)) {
        this.isSquareGameArea = true;
      }
    }
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init 3rd party game when the token
    // is available
    this.initGame()
  }
})

@Component({
  components: {
    GameBox,
    GameboxNoLoad,
    FastGameMenu,
    GameController,
    QuickDeposit,
  }
})
export default class ThirdPartyGame extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @GameAction initializeThirdPartyGameSession
  @GameAction getPublicGamesList
  @GameGetter games
  @SettingsGetter getBrandId
  @SettingsGetter getPlatformCurrency
  @AccountAction getUserBalance
  @AccountGetter balanceLoaded
  @AccountGetter userTotalBalance
  @AccountGetter userToken
  @AccountGetter isLoggedIn
  @AccountGetter userCountryCodeLoaded
  @AccountAction userIpCheck
  @AccountAction getUserFreeSpins
}
