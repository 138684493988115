











































import { Component, Vue } from 'vue-property-decorator'
import Button from '../global/Button.vue'

const AppProps = Vue.extend({
  props: {
    aggregators: {
      type: Object,
      required: true
    },
    aggregatorFilterCount: {
      type: Number,
      required: true
    }
  },

  methods: {
    selectedAggregator(name): void {
      this.$emit('selected-aggregator', name)
    },

    applyFilters(): void {
      this.$emit('apply-filters')
      this.closeModal()
    },

    closeModal(): void {
      this.$emit('close-modal')
    },

    resetAggregatorFilters(): void {
      this.$emit('reset-aggregator-filters')
    },

    clickOutside(e): void {
      if (e.target.id === 'modal-bg') this.closeModal()
    }
  },
  
  computed: {
    clearOrCancel(): string {
      if (this.aggregatorFilterCount > 0) {
        return this.$t('reset_filters')
      } else {
        return this.$t('cancel_nma')
      }
    }
  }
})

@Component({
  components: { Button }
})
export default class MobileAggregatorModal extends AppProps {}
