import { render, staticRenderFns } from "./MobileTargetedPromotionDialog.vue?vue&type=template&id=6910dd10&scoped=true&"
import script from "./MobileTargetedPromotionDialog.vue?vue&type=script&lang=ts&"
export * from "./MobileTargetedPromotionDialog.vue?vue&type=script&lang=ts&"
import style0 from "./MobileTargetedPromotionDialog.vue?vue&type=style&index=0&id=6910dd10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6910dd10",
  null
  
)

export default component.exports