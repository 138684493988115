

















import { Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  props: [
    'logoEnabled',
    'gameBoxText',
    'primaryButtonText',
    'primaryButtonHref',
    'secondaryButtonText',
    'secondaryButtonHref',
    'primaryButtonShowDepositModal',
    'isLoggedIn'],
  methods: {
    logoImagePath() {
      return require('@/assets/images/common/' + (this.$store.getters['settings/getPlatformName'] === 'cashgames' ? 'cash_games_logo' : 'btc_games_logo') + '.webp')
    },
    primaryButtonClick() {
      if (this.$store.getters['account/limitedAccount']) {
        this.$bvModal.show('new-restricted-region-modal')
      } else if (this.primaryButtonShowDepositModal) {
        if(this.isLoggedIn) {
          this.$store.dispatch('account/getDepositAddress')
        } else {
          this.$router.push('player-login');
        }
      } else {
        this.$router.push(this.primaryButtonHref)
      }
    }
  }
})
export default class GameBoxNoLoad extends AppProps {
}
