const axios = require('axios');
const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, AvailablePromotionsCampaignState } from './types'
import apolloClient from '../vue-apollo';
import gql from "graphql-tag";

type AvailablePromotionsCampaignGetter = GetterTree<AvailablePromotionsCampaignState, RootState>

export const state: AvailablePromotionsCampaignState = {
  size: 20,
  number: 0,
  totalElements: 1,
  totalPages: 1,
  first: true,
  last: false,
  numberOfElements: 1,
  content: [],
  autoOptInCampaignId: ''
}

export const getters: AvailablePromotionsCampaignGetter = {
  getAvailablePromotionsContent: state => state.content,
  getAvailablePromotionsContentWithoutOptInDate: state => state.content.filter(p => !p.optInDate ),
  getAvailablePromotionsContentWithOptInDate: state => state.content.filter(p => p.optInDate ),
  getAutoOptInCampaign: state => state.autoOptInCampaignId
}

export const mutations: MutationTree<AvailablePromotionsCampaignState> = {
  async setAvailablePromotions (state, content_data) {

    const content = content_data['content'];
    let banner_id = content_data['banner_id'];

    if(banner_id === undefined){
      banner_id = 'default';
    }
    const response = await apolloClient.query({
      query: gql`
        query Promos($casinoCode: String!, $status: String!, $bannerId: String!) {
          casinos(where: { name: $casinoCode }) {
            name
            promotions(
              where: { status: $status, advertisements: { banner_id: $bannerId } }
            ) {
              id
              index
              description
              description_ru
              description_es
              description_pt
              wager_requirement
              campaign_name
              requirements
              requirements_ru
              requirements_es
              requirements_pt
              terms_and_condition
              terms_and_condition_ru
              terms_and_condition_es
              terms_and_condition_pt
              buttonText
              buttonText_ru
              buttonText_es
              buttonText_pt
              auto_opt_in
              redirectToAfterClaim
              uuid
              status
              is_deposit
              is_playnow
              is_freespins
              is_disabled
              is_general_promotion
              advertisements(where: { banner_id: $bannerId }) {
                banner_id
              }
              image {
                formats
              }
              image_ru {
                formats
              }
              image_es {
                formats
              }
              image_pt {
                formats
              }
              min_deposit_BTC
              max_deposit_BTC
              min_deposit_BCH
              max_deposit_BCH
              is_only_on_specific_day
              specific_day
            }
          }
        }
    `,
    variables: {
      status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published",
      casinoCode: cookies.get('casino_code'),
      bannerId: banner_id
    },
    fetchPolicy: "no-cache"
    });

    if(content) {
      //Get the banner data to filter the available campaigns
      const casino = response.data.casinos.find(c => c.name == cookies.get('casino_code'))

      let temp_content = [];
      let temp_counter = 0;
      for(let i=0; i<content.length; i++) {
        let campaignData = casino.promotions.filter(
          function(data){ return data.uuid == content[i].uuid }
        );
        campaignData = campaignData.sort( (a, b) => parseInt(a.index) - parseInt(b.index) );
        if(campaignData.length > 0) {
          if(campaignData['auto_opt_in']) {
            state.autoOptInCampaignId = content[i].uuid;
            content[i]['optInDate'] = 'optin';
          }
          temp_content[temp_counter] = {...content[i], ...campaignData[0]};
          temp_counter += 1;
        }
      }
      temp_content = temp_content.sort((a, b) => (a.index > b.index) ? 1 : -1)
      state.content = temp_content
    }
  },
  resetAutoOptInCampaign(state) {
    state.autoOptInCampaignId = '';
  }
}

export const actions: ActionTree<AvailablePromotionsCampaignState, RootState> = {
  async getAvailablePromotions ({ commit, dispatch, rootGetters }, banner_id) {
    try {
      const endpoint = `/campaign/eligible/${rootGetters['account/userId']}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        const content_data = {
          'content': res.data.content,
          'banner_id': banner_id
        }
        // If the user already opted in a Welcome Offer it cannot opt into another one, this filters the UI for campaigns with name NWO
        // coming from our Strapi CMS
        const indexOptedInNWO = content_data.content.findIndex((el) => el.optInDate && el.name.toLowerCase().includes("nwo"))
        if(indexOptedInNWO >= 0) content_data.content = content_data.content.filter((el, i) => !el.name.toLowerCase().includes("nwo") || i === indexOptedInNWO);
        commit('setAvailablePromotions', content_data)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('could not get available promotions')
    }
  },
  async claimPromotionCampaign({commit, rootGetters}, campaignUUID) {
    try {
      const endpoint = `/campaign_player_state/${campaignUUID}/optin/${rootGetters['account/userId']}?deviceType=ALL`
      const rqOptions = rqBuilder(
        'PUT',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        return {"success":true, "message":"campaign_claimed_successfully"}
      } else throw new Error('Invalid response from server')
    } catch (e) {
      return {"success":false, "message":"campaign_not_claimed_successfully", "error": e}
    }
  },
  async claimPromotionUsingBannerId({commit, dispatch, rootGetters}, autoOptInCampaignId) {
    if(autoOptInCampaignId !== '') {
      dispatch('claimPromotionCampaign', autoOptInCampaignId)
      commit('resetAutoOptInCampaign')
    }
  },
  async getEligibleCampaigns({rootGetters}) {
    if (!rootGetters['account/userId']) return
    try {
      const endpoint = `/campaign/eligible/${rootGetters['account/userId']}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        const content_data = {
          'content': res.data.content
        }
        return content_data
      } else throw new Error('Invalid response from server')
    } catch (e) {
      throw new Error(e)
    }
  },
  async getOptedInCampaigns({rootGetters}) {
    if (!rootGetters['account/userId']) return
    try {
      const endpoint = `/campaign/eligible/${rootGetters['account/userId']}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId'],
          playerStatus: 'OPT_IN'
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        const content_data = {
          'content': res.data.content
        }
        return content_data
      } else throw new Error('Invalid response from server')
    } catch (e) {
      throw new Error(e)
    }
  },
  // Get all the general promotions that should be visible to non-logged in users, by
  async getGeneralPromotions({ commit }) {
    try {
      const casinos = await apolloClient.query({
        query: gql`
        query GeneralPromos($casinoCode: String!, $status: String!) {
          casinos(where: { name: $casinoCode }) {
            name
            promotions(
              where: { status: $status, is_general_promotion: true }
            ) {
              id
              index
              description
              description_ru
              description_es
              description_pt
              wager_requirement
              campaign_name
              requirements
              requirements_ru
              requirements_es
              requirements_pt
              terms_and_condition
              terms_and_condition_ru
              terms_and_condition_es
              terms_and_condition_pt
              buttonText
              buttonText_ru
              buttonText_es
              buttonText_pt
              auto_opt_in
              redirectToAfterClaim
              uuid
              status
              is_deposit
              is_playnow
              is_freespins
              is_disabled
              is_general_promotion
              image {
                formats
              }
              image_ru {
                formats
              }
              image_es {
                formats
              }
              image_pt {
                formats
              }
            }
          }
        }
      `,
        variables: {
          status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published",
          casinoCode: cookies.get('casino_code'),
        },
        fetchPolicy: "no-cache"
      });

      const casino = casinos.data.casinos.find(c => c.name == cookies.get('casino_code'))
      const promotions = casino.promotions.sort( (a, b) => parseInt(a.index) - parseInt(b.index) );
      const content_data = {
        'content': promotions
      }
      commit('setAvailablePromotions', content_data)
    } catch (e) {
      console.log('Could not get general promotions')
    }
  },
  async getBannerCampaigns({rootGetters},banner_id) {
    const response = await apolloClient.query({
      query: gql`
                  query Campaign($bannerId: String!) {
                    campaigns(
                      where: {advertisements: { banner_id: $bannerId } }
                    ) {
                      id
                      campaign_uuid
                      is_freespins
                      email_verification_required
                    }
                  }
            `,
      variables: {
        bannerId: banner_id
      },
      fetchPolicy: "no-cache"
    });
    return response.data.campaigns;
  },
  async isBannerCampaignEligible({rootGetters}, payload) {
    const campaignsData = payload['campaignsData'];
    const bannerCampaignData = payload['bannerCampaignData'];
    const result = campaignsData.filter(
      function(data){ return data.uuid === bannerCampaignData.campaign_uuid }
    );
    if(result.length > 0) {
      return true
    }
    return false
  },
  async checkIsCampaignEligible({rootGetters}, payload){
    const endpoint = `/campaign/${payload['campaignUUID']}/check-availability/${payload['userId']}`;
    const rqOptions = rqBuilder(
      'GET',
      rootGetters['settings/getApiUrl'] + endpoint,
      {
        brandId: rootGetters['settings/getBrandId']
      },
      rootGetters['account/userToken']
    )
    const res = await axios(rqOptions)
    return res
  },
  async fetchLeaderboardData({commit, rootGetters}, tournamentId) {
    try {
      const endpoint = `/tournament/public/leaderboard/${tournamentId}`;
      const rqOptions = rqBuilder(
        'GET',
        "https://api.prod05.beti.io" + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      return res.data
    } catch (e) {
      console.warn(e)
    }
  },
}

export const availablePromotionsCampaign: Module<AvailablePromotionsCampaignState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
