import Observable from '../Util/observable.js'
import GameCategoryData from './gameCategoryData.js'

export default class GameModel extends Observable {

  constructor (gameData) {
    super()
    this.gameData = JSON.stringify(gameData)
    this.id = gameData.internalGameId
    this.title = ['bitcoincom', 'bitcoin'].includes(gameData.providerId) ? window.i18n.t(gameData.gameId) : gameData.fullGameName
    this.identifier = gameData.internalGameId
    this.desktopImage = gameData.desktopImage
    this.animatedImage = gameData.animatedImage
    this.mobileImage = gameData.mobileImage
    this.game_provider = gameData.game_provider
    this.provider_ident = gameData.provider_id
    this.priority = gameData.priority
    this.rating = parseInt(gameData.review)
    this.favorite = gameData.favorite
    this.favoriteRequestActive = false
    this.categoryData = new GameCategoryData(gameData.category)
    this.jackpot = gameData.jackpot
    this.imageSubs = []
    this.loaded = false
    this.isExclusive = gameData.isExclusive
    this.gameId = gameData.gameId
  }
}
