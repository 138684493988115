import Vue from 'vue';
import Vuex from 'vuex';
import { settings } from './settings';
import { account } from './account';
import { game } from './game';
import { ui } from './ui';
import { deposit } from './deposit';
import { withdrawal } from './withdrawal';
import { gameLogs } from './game-logs';
import { bonusWager } from '@/store/bonus-wager'
import { availablePromotionsCampaign } from '@/store/available_promotions'
import { freeSpins } from '@/store/freespin'
import { activePromotionsCampaign } from '@/store/active_promotions'
import { pastPromotionsCampaign } from '@/store/past_promotions'
import { paymentMethods } from '@/store/payment_methods'
import { worldCup } from '@/store/worldcup';
import createPersistedState from 'vuex-persistedstate';
import { WorldCupPrediction } from '@/store/worldcup_prediction';
Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState({
    reducer (val) {
      if(!val.account.isLoggedIn) {
        return {}
      }
      return val
    },
    paths: ['game.favoriteGames',
      'game.lastNumbersDice',
      'account.isLoggedIn',
      'account.token',
      'account.magicToken',
      'account.hasMagicToken',
      'account.userId',
    ]
  })],
  modules: {
    settings,
    account,
    game,
    ui,
    deposit,
    withdrawal,
    gameLogs,
    bonusWager,
    availablePromotionsCampaign,
    freeSpins,
    activePromotionsCampaign,
    pastPromotionsCampaign,
    paymentMethods,
    WorldCupPrediction,
    worldCup
  }
})

// requirement to access store in JS outside of webpack
// @ts-ignore
window.VueStore = store
export default store
