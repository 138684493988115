



































































import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({
  props: {},
  mounted () {

  },
  methods: {
    closeDialog (idx) {
      $('#verify_game_dialog_' + idx).trigger('close');
    }
  }
})
@Component({
  components: {}
})
export default class VerifyGame extends AppProps {
}
