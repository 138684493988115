















































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import {namespace} from "vuex-class";
import { QrcodeStream } from 'vue-qrcode-reader';
import moment from 'moment';
import CopySpan from '@/components/macros/CopySpan.vue';
import VueJsonToCsv from 'vue-json-to-csv'

const SettingsGetter = namespace('settings').Getter
const DepositAction = namespace("deposit").Action
const WithdrawalAction = namespace("withdrawal").Action
const DepositGetter = namespace("deposit").Getter
const WithdrawalGetter = namespace("withdrawal").Getter
const GameLogsAction = namespace("gameLogs").Action
const GameLogsGetter = namespace("gameLogs").Getter
const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action
const SettingsAction = namespace('settings').Action

const AppProps = Vue.extend({
  data: () => {
    return {
        range: {},
        isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
          navigator.userAgent
        ),
        expanded: [],
        page: 1,
        pageCount: 0,
        transactionsNavOptions: ['All', 'Deposits', 'Withdrawals'],
        transactionsNavOptionsI18nKeys: ['all', 'deposits_nma', 'withdrawals_nma'],
        allTableData: [],
        itemsPerPage: 10,
        depositsTableData: [],
        withdrawalsTableData: [],
        currentTransactionsSelectedTabIndex: 0,
        viewPassword: 0,
        date: new Date().toISOString().substr(0, 10),
        dates: [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        depositDates: [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        withdrawalDates: [moment().subtract(10, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
        dateFormatted: null,
        menu1: false,
        menu2: false,
        showQRScanner: false,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        today: new Date(),
        headers: [],
        depositsHeaders: [
        ],
        withdrawalsHeaders: [
        ]
      }
  },
  watch: {
    locale() {
      this.headers = [
        { text: this.$i18n.t('side_nma'), value: 'dataType' },
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount'},
        { text: this.$i18n.t('send_to_withdrawal_only_nma'), value: 'paymentAccount', hide: 'smAndDown'  },
        { text: this.$i18n.t('credited_deposit_only_nma'), value: 'credited', hide: 'smAndDown'  },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown'  },
      ];

      this.depositsHeaders = [
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount' },
        { text: this.$i18n.t('credited_deposit_only_nma'), value: 'credited', hide: 'smAndDown' }
      ];
      this.withdrawalsHeaders = [
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount' },
        { text: this.$i18n.t('send_to_withdrawal_only_nma'), value: 'paymentAccount', hide: 'smAndDown' },
      ];
    }
  },
  computed: {
    computedHeaders () {
      let headers = [...this.headers];
      headers = [
        { text: this.$i18n.t('side_nma'), value: 'dataType' },
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount'},
        { text: this.$i18n.t('send_to_withdrawal_only_nma'), value: 'paymentAccount', hide: 'smAndDown'  },
        { text: this.$i18n.t('credited_deposit_only_nma'), value: 'credited', hide: 'smAndDown'  },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown'  },
      ];
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    computedDepositsHeaders () {
      let depositHeaders = [...this.depositsHeaders]
      depositHeaders = [
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount' },
        { text: this.$i18n.t('credited_deposit_only_nma'), value: 'credited', hide: 'smAndDown' }
      ];
      return depositHeaders.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    computedWithdrawalsHeaders () {
      let withdrawalsHeaders = [...this.withdrawalsHeaders]
      withdrawalsHeaders = [
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount' },
        { text: this.$i18n.t('send_to_withdrawal_only_nma'), value: 'paymentAccount', hide: 'smAndDown' },
      ];
      return withdrawalsHeaders.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },
    dateRangeText () {
      if(this.dates && Array.isArray(this.dates)) {
        let dates = [...this.dates];
        return this.normalizeDates(dates).join(' ~ ');
      }
      else
        return ""
    },
    depositsDateRangeText () {
      if(this.depositDates && Array.isArray(this.depositDates)) {
        let dates = [...this.depositDates];
        return this.normalizeDates(dates).join(' ~ ');
      }
      else
        return ""
    },
    withdrawalsDateRangeText () {
      if(this.withdrawalDates && Array.isArray(this.withdrawalDates)) {
        let dates = [...this.withdrawalDates];
        return this.normalizeDates(dates).join(' ~ ');
      }
      else
        return ""
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    formattedStartDate () {
      return moment(this.startDate).format('L')
    },
    formattedEndDate () {
      return moment(this.endDate).format('L')
    },
  },
  props: ['locale'],
  async mounted () {
    await this.updateDates('all')

    this.headers = [
      { text: this.$i18n.t('side_nma'), value: 'dataType' },
      { text: this.$i18n.t('time_nma'), value: 'creationTime' },
      { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount'},
      { text: this.$i18n.t('send_to_withdrawal_only_nma'), value: 'paymentAccount', hide: 'smAndDown', width: '220px'},
      { text: this.$i18n.t('credited_deposit_only_nma'), value: 'credited', hide: 'smAndDown' },
      { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown', width: '220px'},
    ];

    this.depositsHeaders = [
        { text: this.$i18n.t('time_nma'), value: 'creationTime' },
        { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown' },
        { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount' },
        { text: this.$i18n.t('credited_nma'), value: 'credited', hide: 'smAndDown' }
    ];
    this.withdrawalsHeaders = [
      { text: this.$i18n.t('time_nma'), value: 'creationTime' },
      { text: this.$i18n.t('hash_nma'), value: 'externalRef', hide: 'smAndDown' },
      { text: this.$i18n.t('net_amount_nma'), value: 'amount.amount' },
      { text: this.$i18n.t('send_to_withdrawal_only_nma'), value: 'paymentAccount', hide: 'smAndDown' },
    ];
  },
  methods: {
    /**
     * @param arr Array of objects
     * Removes falsy properties from objects in the array
     * It also specifically treats amount as HRZN returns amount as:
     * {amount: { amount: 0.01, currency: 'BTC'} }
     * @example removeEmpty([{a: null, b: undefined, c: '', d: 100}])
     * @returns [{d: 100}]
     */
    removeEmpty(arr: any[]) {
      const newArr = arr.map((el: any) => ({...el}));
      newArr.forEach((obj, i) => {
        if (typeof(obj.amount) === 'object') {
          obj.currency = obj.amount.currency;
          obj.amount = obj.amount.amount;
        }
      // eslint-disable-next-line no-unused-vars
      newArr[i] = Object.fromEntries(Object.entries(obj).filter(([_, v]) => 
            (!Array.isArray(v) && v)
            || (Array.isArray(v) && v.length)
        )); 
      });
      return newArr;
    },
    normalizeDates(dates) {
      if (Date.parse(dates[1]) < Date.parse(dates[0])) {
              let temp = dates[1];
              dates[1] = dates[0];
              dates[0] = temp;
      }
      return dates;
    },
    async updateDates(dataType:string) {
      switch (dataType) {
        case "all":
          this.depositDates = [...this.normalizeDates(this.dates)];
          this.withdrawalDates = [...this.normalizeDates(this.dates)];
          await this.getDeposits(false, this.normalizeDates(this.dates));
          await this.getCashouts(false, this.normalizeDates(this.dates));
          this.withdrawalsTableData = [...this.withdrawalHistory.map(el => {
            el.dataType = 'withdrawal';
            return el
          })];
          break;
        case "deposits":
          this.dates = [...this.normalizeDates(this.depositDates)];
          this.withdrawalDates = [...this.normalizeDates(this.depositDates)];
          await this.getDeposits(false, this.normalizeDates(this.depositDates));
          await this.getCashouts(false, this.normalizeDates(this.depositDates));
          break;
        case "withdrawals":
          this.dates = [...this.normalizeDates(this.withdrawalDates)];
          this.depositDates = [...this.normalizeDates(this.withdrawalDates)];
          await this.getDeposits(false, this.normalizeDates(this.withdrawalDates));
          await this.getCashouts(false, this.normalizeDates(this.withdrawalDates));
          break;
      }

      this.allTableData = [...this.depositHistory.map(
        el => {
          el.dataType = 'deposit';
          el.hashOrGameName = el.externalRef;
          return el 
      }),
      ...this.withdrawalHistory.map(el => { el.dataType = 'withdrawal'; return el })];
      this.depositsTableData = [...this.depositHistory.map(el => { el.dataType = 'deposit'; el.createdAt = el.creationTime; el.hashOrGameName = el.externalRef; return el })];
      this.withdrawalsTableData = [...this.withdrawalHistory.map(el => { el.dataType = 'withdrawal'; return el })];
    },
    moment: function () {
      return moment();
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    selectTransactionsTab(index:number) {
      this.currentTransactionsSelectedTabIndex = index;
    },
    downloadCsvCashoutsClickHandler() {
      // eslint-disable-next-line no-unused-vars
      let filename = 'BitcoinCom_Games_withdrawal_history-'
      if ($('#btn_see_all_cashouts').data('seeAllCashouts') === 'true') {
        filename += 'all'
      } else if (this.formattedStartDate === this.formattedEndDate) {
        filename += this.formattedStartDate
      } else {
        filename += this.formattedStartDate + '-' + this.formattedEndDate
      }
      //@ts-ignore
      // eslint-disable-next-line no-undef
      export_table_to_csv('#cashouts-history-tbl', `${filename}` + '.csv')
    },
    downloadCsvGameLogsClickHandler() {
      // eslint-disable-next-line no-unused-vars
      let filename = 'BitcoinCom_Games_Logs-'
      if ($('#btn_see_all_game_logs').data('seeAllGameLogs') === 'true') {
        filename += 'all'
      } else if (this.formattedStartDate === this.formattedEndDate) {
        filename += this.formattedStartDate
      } else {
        filename += this.formattedStartDate + '-' + this.formattedEndDate
      }
      //@ts-ignore
      // eslint-disable-next-line no-undef
      export_table_to_csv('#mygames', `${filename}` + '.csv')
    },
    downloadCsvDepositsClickHandler() {
      // eslint-disable-next-line no-unused-vars
      let filename = 'BitcoinCom_Games_deposits_history-'
      if ($('#btn_see_all_deposits').data('seeAllDeposits') === 'true') {
        filename += 'all'
      } else if (this.formattedStartDate === this.formattedEndDate) {
        filename += this.formattedStartDate
      } else {
        filename += this.formattedStartDate + '-' + this.formattedEndDate
      }
      //@ts-ignore
      // eslint-disable-next-line no-undef
      export_table_to_csv('#deposits-history-tbl', `${filename}` + '.csv')
    },
    async getDeposits(getAll, dateRangeArr) {
      let payload = {
        'startDate': dateRangeArr[0],
        'endDate': dateRangeArr[1] || dateRangeArr[0], // in case only one date is selected
        'getAll': getAll,
        'platformCurrency': this.getPlatformCurrency
      }
      await this.getDepositsData(payload);
    },

    async getCashouts(getAll, dateRangeArr) {
      let payload = {
        'startDate': dateRangeArr[0],
        'endDate': dateRangeArr[1] || dateRangeArr[0],
        'getAll': getAll,
        'platformCurrency': this.getPlatformCurrency
      }
      await this.getWithdrawalsData(payload);
    },

    async getGameLogs(getAll, dateRangeArr) {
      let payload = {
        'startDate': dateRangeArr[0],
        'endDate': dateRangeArr[1] || dateRangeArr[0],
        'getAll': getAll,
        'platformCurrency': this.getPlatformCurrency
      }
      await this.getGameLogsData(payload);
    },
    copyLink (payload) {
      try {
        this.isCopied = true;
        this.$copyText(payload)
        setTimeout(function () { this.isCopied = false }.bind(this), 1000)
      } catch (err) {
        console.log('Oops, unable to copy');
      }
    },
    dateToHumanReadable (dateStr) {
      return moment(dateStr).format("MM/DD/YYYY hh:mm")
    },
    formatGameLogResult(winAmount) {
      return winAmount;
    },
    formatGrossAmount(record) {
      return record.amount
    },
    noFormatFn(record) {
      return record;
    },
    formatGameName(record) {
      return record.gameId ? record.gameId.charAt(0).toUpperCase() + record.gameId.slice(1) : record.externalRef;
    },
    formatRoundId(record) {
      return record.macroRoundId
    },
  }
})

@Component({
  components: {
    QrcodeStream,
    CopySpan,
    VueJsonToCsv
  }
})

export default class TransactionsHistoryPanel extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @SettingsGetter getWithdrawalServiceFee
  @SettingsAction getWithdrawRules
  @AccountGetter hasUnconfirmedTx
  @AccountGetter twofaAuthEnabled
  @AccountGetter securedByPassword
  @AccountGetter legacyPassword
  @AccountGetter userBalance: Number
  @AccountAction getUserData
  @AccountAction getTotpSecretKey
  @AccountAction getFixedWithdrawalAddress
  @AccountAction getMagicTokenAndAuthData
  @AccountGetter userId!: String
  @SettingsGetter getPlatformName!: String
  @AccountAction createWithdrawal
  @AccountGetter magicToken!: String
  @AccountGetter fixedWithdrawalAddress!: String
  @DepositAction("getDeposits") getDepositsData
  @WithdrawalAction('getWithdrawals') getWithdrawalsData
  @DepositGetter depositHistory
  @WithdrawalGetter withdrawalHistory
  @GameLogsAction('getGameLogs') getGameLogsData
  @GameLogsGetter gameLogs
  @GameLogsGetter gamesByInternalGameId
}
