






















































































































































































































































































































































































import RouletteTable from './modules/RouletteTable.vue'
import { Component, Vue } from 'vue-property-decorator'
import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import { namespace } from 'vuex-class'
import Common from '@/mixins/Common.vue'
import axios from 'axios'
const UIMutation = namespace('ui').Mutation

const AccountGetter = namespace('account').Getter
const SettingsGetter = namespace('settings').Getter

const AppProps = Vue.extend({
  data:() => {
    return {
      progressive_paytable: [],
      progressive_bets: [],
      last_number_count: 0,
      progressive_init: 10000,
      number_of_zeros: 1,
      valid_credit_sizes: [],
      credit_btc_value: 0,
      currency_value_dec: 0,
      isAccountLoaded: false
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Classic Progressive Roulette Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Roulette`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  mixins: [Common],
  props: {},
  created () {
    //@ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
  },
  methods: {
    async getRuleset() {
      try{
        let res = await axios.get('/api/roulette/ruleset')
        this.progressive_paytable = res.data.result.progressive_paytable
        this.progressive_bets = res.data.result.progressive_bets
        this.last_number_count = res.data.result.last_number_count
        this.number_of_zeros = res.data.result.number_of_zeros
        this.valid_credit_sizes = res.data.result.valid_credit_sizes
        this.progressive_init = 0
        this.credit_btc_value = this.valid_credit_sizes[this.valid_credit_sizes.length - 1]
        return res.data.result
      } catch (e) {
        this.$router.push('maintenance')
        console.log("Not able to fetch the rulesets")
        return e
      }
    },
    async reseed() {
      try {
          let res = await axios.post('/api/roulette/seed')
          return res.data.server_seed_hash
      } catch (e) {
        return e
      }
    },
    async initGame() {
      this.isAccountLoaded = true;
      this.initializeGameScale()
      //@ts-ignore
      // eslint-disable-next-line no-undef
      let ruleset = await this.getRuleset()
      let server_seed_hash = await this.reseed()
      // There is 100 million satoshis in one coin, both for BCH and BTC
      this.currency_value_dec = this.credit_btc_value / 100000000
      ruleset.maximum_bet = this.getPlatformCurrency === "BCH" ? 10000000 : 1000000

      //@ts-ignore
      await this.$loadScript('/js/legacy/roulette.js');
      // @ts-ignore
      // eslint-disable-next-line no-undef
      init_roulette(this.userId, "abc", "1234", server_seed_hash, [], [], [], ruleset, this.last_number_count, this.progressive_init, 10, this.credit_btc_value)
    }
  },
  async mounted () {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init roulette game when the token
    // is available
   this.initGame();
  }
})
@Component({
  components: {
    RouletteTable,
    FastGameMenu
  }
})
export default class Roulette extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @AccountGetter userId!: String
  @SettingsGetter getPlatformName!: String
  @SettingsGetter getPlatformCurrency
  @AccountGetter userToken
}
