


















































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import BaseModal from './BaseModal.vue'
import CopySpan from '@/components/macros/CopySpan.vue';

const AccountAction = namespace('account').Action
const AccountGetter = namespace('account').Getter
const SettingsGetter = namespace('settings').Getter
const AccountMutation = namespace('account').Mutation

const AppProps = Vue.extend({
  data: () => {
    return {
      accountSubMenu: [
      'Account Details',
      'Email Address',
      ],
      selectedSubMenu: 0,

      // username form
      newUsername: '',
      canEditUsername: false,
      usernameMessage: '',
      usernameMessageColor: '',
      usernameInputRed: false,

      // email form
      newEmail: '',
      censoredEmail: '',
      canEditEmail: false,
      emailMessage: '',
      emailInputRed: false,

      renderModal: false,
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent)
    }
  },
  watch: {
    wantsToLogout (newValue) {
     if (newValue) {
       this.selectSubMenu(0)
       this.setWantsToLogout(false)
     }
    }
  },
  methods: {
    selectTab(index:number) {
      this.$emit('setAccountModalTab', index);
    },
    selectSubMenu(id:number) {
      this.selectedSubMenu = id
      this.usernameMessage = ''
      this.usernameInputRed = false
    },

    editUsername(bool:boolean) {
      this.canEditUsername = bool
      this.usernameMessage = ''

      this.$nextTick(() => {
        if (this.$refs.username) this.$refs.username.focus()
      });

      if (!bool) {
        this.usernameInputRed = false
        this.newUsername = ''
      }
    },

    usernameValidation(username:string) {
      if (!/^[a-zA-Z0-9_]*$/.test(username)) {
        this.usernameInputRed = true
        this.usernameMessageColor = '#FF0000'
        this.usernameMessage = 'Only alphanumeric characters and underscores allowed'

        return false
      }

      if (username.length < 3 || username.length > 10) {
        this.usernameInputRed = true
        this.usernameMessageColor = '#FF0000'
        this.usernameMessage = 'Username must be between 3-10 characters'

        return false
      }

      this.usernameInputRed = false

      return true
    },

    async usernameUpdate() {
      if (!this.usernameValidation(this.newUsername)) return

      const res = await this.updateUserProfile({
        login: this.newUsername
      })

      if (res.success) {
        this.usernameMessageColor = '#0AC18E'
        this.usernameMessage = `${this.newUsername} is your new username`
        this.canEditUsername = false
      } else {
        this.usernameInputRed = true;
        this.usernameMessageColor = '#FF0000'
        this.usernameMessage = res && res.error == "error.validation.login.exist" ? 'The username already exists. Please try another name' :  "Error changing your username, please try later or contact administrator"
      }
    },

    editEmail(bool:boolean) {
      this.canEditEmail = bool

      this.$nextTick(() => {
        if (this.$refs.email) this.$refs.email.focus()
      });

      if (!bool) {
        this.newEmail = ''
        this.emailInputRed = false
      }
    },

    emailCensor(email:string) {
      const re = email.match(/^(.).+(.@.+)$/)
      this.censoredEmail = `${re[1]}***${re[2]}`
    },

    emailValidation(email:string) {
      return /.+@.+\..+/.test(email)
    },

    async emailUpdate() {
      if (!this.emailValidation(this.newEmail)) {
        // if email isn't valid
        this.emailInputRed = true
        this.messageColor = '#FF0000'
        this.emailMessage = this.$i18n.t('invalid_email_address')

        return
      }

      try {
        const res = await this.updateEmail(this.newEmail)

        if (res.success) {
          this.renderModal = true
          this.emailInputRed = false
          this.messageColor = '#FFCC4A'
          this.modalName = this.$i18n.t('verify_email')
          this.emailCensor(this.newEmail)
          this.emailMessage = this.$i18n.t('pending_verification_nma')
          this.canEditEmail = false

          try {
            await this.confirmEmail(this.newEmail)
          } catch (e) {
            console.log(e);
          }
        } else if (res.error.response.data.error == "error.validation.email.exist") {
          this.emailInputRed = true
          this.messageColor = '#FF0000'
          this.emailMessage = this.$i18n.t('this_email_address_already')
        }
      } catch (e) {
        this.emailInputRed = true
        this.messageColor = '#FF0000'
        this.emailMessage = e
      }
    },

    async resendEmailUpdate() {
      try {
        const res = await this.confirmEmail(this.email)

        if (res.success) {
          this.messageColor = '#FFCC4A'
          this.emailCensor(this.email)
          this.modalName = this.$i18n.t('verify_email')
          this.renderModal = true
        }
      } catch (e) {
        this.emailInputRed = true
        this.messageColor = '#FF0000'
        this.emailMessage = this.$i18n.t('something_went_wrong')

        console.log(e)
      }
    },

    showModal() {
      this.renderModal = true
      this.modalName = 'Logout?'
    },

    closeModal() {
      this.renderModal = false
    },
  },

  computed: {
    textColor() {
      return {
        color: this.usernameMessageColor,
        margin: '4px 0 0 0'
      }
    },

    hasChangedEmail() {
      return this.email && !this.email.endsWith('@hrzn.io')
    },

    pendingVerification() {
      return this.hasChangedEmail && !this.emailVerified
    },
  },

  mounted() {
    if (this.pendingVerification) {
      this.emailMessage = this.$i18n.t('pending_verification_nma')
      this.messageColor = '#FFCC4A'
    }
    this.$root.$on('setMyAccountTabSection', idx => {
      this.selectSubMenu(idx)
    })
  }
 })

@Component({
  components: {
    BaseModal,
    CopySpan
  }
})

export default class MyAccountPanel extends AppProps {
  @AccountAction updateUserProfile
  @AccountGetter username!: String;

  @AccountGetter email!: String
  @AccountAction updateEmail
  @AccountAction confirmEmail
  @AccountGetter emailVerified
  @AccountGetter hasMagicToken
  @AccountGetter wantsToLogout
  @AccountGetter isLegacyUser:boolean
  @SettingsGetter getPlatformUrl!: String
  @AccountGetter magicToken!: String
  @AccountMutation setWantsToLogout
}
