export default class Observable {

  constructor () {
    this.observers = []
    this.initialized = false
  }

  registerObserver (observer) {
    this.observers.push(observer)
  }

  initialize () {
    if (this.initialized) return
    this.initialized = true
    // eslint-disable-next-line no-undef
    let clone = $.extend(true, {}, this)
    this.observers.forEach(observer => observer.init ? observer.init(clone) : null)
  }

  notifyAll () {
    // eslint-disable-next-line no-undef
    let clone = $.extend(true, {}, this)
    this.observers.forEach(observer => observer.update(clone))
  }
}
