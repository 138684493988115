

























































































import { Component, Vue } from 'vue-property-decorator'
import Common from "../../mixins/Common.vue"
import { namespace } from 'vuex-class'
const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace("account").Action
const AccountGetter = namespace("account").Getter

const AppProps = Vue.extend({
  mixins: [Common],
  props: {
    showOverlay: Boolean,
  },
  data: function() {
      return {
          show: false
      }
  },
  methods: {
    async depositClickHandler() {
      if (!this.limitedAccount) {
        $("#header-control-deposit").click();
        this.$emit('openOverlay', false);
        await this.getDepositAddress();
      } else {
        this.$bvModal.show('new-restricted-region-modal')
      }
    },
    async openMoonpayDialog() {
      this.$emit('openOverlay', false);
      this.updateParamsInLocation({ buy: true })
    },
    openSideShift(originIsPlayForRealDialog) {
      if (this.getPlatformCurrency == "BTC") {
        // @ts-ignore
        window.__SIDESHIFT__.defaultDepositMethodId = "bch";
        // @ts-ignore
        window.__SIDESHIFT__.defaultSettleMethodId = "btc";
      } else if (this.getPlatformCurrency == "BCH") {
        // @ts-ignore
        window.__SIDESHIFT__.defaultDepositMethodId = "btc";
        // @ts-ignore
        window.__SIDESHIFT__.defaultSettleMethodId = "bch";
      }
      // @ts-ignore
      window.__SIDESHIFT__.settleAddress = this.userAddress;
      if (originIsPlayForRealDialog) {
        // @ts-ignore
        $(this.$refs.modal).modal('hide')
      }
      // @ts-ignore
      window.sideshift.show();
      this.$emit('openOverlay', false);

      this.updateParamsInLocation('remove')
    },
    async openDepositDialog() {
      await this.getDepositAddress()
    },
    async openSideshiftDialog() {
      this.updateParamsInLocation({ convert: true, playforrealdialog: true})
    },
  }
});

@Component
export default class FirstTimeDialog extends AppProps {
  @SettingsGetter getPlatformCurrency
  @AccountAction getDepositAddress
  @AccountGetter limitedAccount
}
