import { GetterTree, MutationTree, Module } from 'vuex'
import { RootState, UIState } from './types'

type UIGetter = GetterTree<UIState, RootState>

export const state: UIState = {
  simpleFooter: false,
  footerDisabled: false,
  balanceDisabled: false,
  vueAppKey: 1
}

export const getters: UIGetter = {
  simpleFooterEnabled: state => state.simpleFooter,
  footerDisabled: state => state.footerDisabled,
  balanceDisabled: state => state.balanceDisabled,
  vueAppKey: state => state.vueAppKey
}

export const mutations: MutationTree<UIState> = {
  setSimpleFooter (state, newValue: boolean) {
    state.simpleFooter = newValue
  },
  setFooterDisabled (state, newValue: boolean) {
    state.footerDisabled = newValue
  },
  setBalanceDisabled (state, newValue: boolean) {
    state.balanceDisabled = newValue
  },
  increaseVueAppKey(state) {
    state.vueAppKey++
  }
}

export const ui: Module<UIState, RootState> = {
  state,
  getters,
  mutations,
  namespaced: true
}
