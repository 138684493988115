import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import { RootState, SettingsState } from './types'

const rqBuilder = require('../helpers/store').rqBuild
const cookies = require('js-cookie')
const axios = require('axios')

type SettingsGetter = GetterTree<SettingsState, RootState>

export const state: SettingsState = {
  brandId: process.env.VUE_APP_BRAND_ID,
  gamesRepoUrl: process.env.VUE_APP_GAMES_REPO,
  platformCurrency: process.env.VUE_APP_PLATFORM_CURRENCY,
  platformName: process.env.VUE_APP_PLATFORM_NAME,
  platformUrl: process.env.VUE_APP_PLATFORM_URL,
  version: process.env.VUE_APP_VERSION,
  walletManagerUrl: process.env.VUE_APP_WMS_URL,
  casinoApiUrl: process.env.VUE_APP_CASINO_API_URL, // our API (i.e. Casino-API)
  xanpoolUrl: process.env.VUE_APP_XANPOOL_URL,
  xanpoolApiKey: process.env.VUE_APP_XANPOOL_API_KEY,
  apiUrl: process.env.VUE_APP_API_URL, // third party API (i.e. HRZN or SoftSwiss)
  apiSocialAuthUrl: process.env.VUE_APP_SOCIAL_AUTH_API_URL,
  gaCode: process.env.VUE_APP_ANALYTICS_ID,
  withdrawSettings: {
    minimum_withdrawal: 0,
    deposit_confirmations: 0,
    withdrawal_confirmations: 0,
    withdrawal_service_fee: 0
  },
  moonPayApiKey: process.env.VUE_APP_MOONPAY_API_KEY,
  moonPayUrl: process.env.VUE_APP_MOONPAY_URL,
  GTMCode: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID
}

export const mutations: MutationTree<SettingsState> = {
  updateWithdrawSettings: (state, withdrawSettings) => {
    state.withdrawSettings.minimum_withdrawal = withdrawSettings.minimum_withdrawal
    state.withdrawSettings.deposit_confirmations = withdrawSettings.deposit_confirmations
    state.withdrawSettings.withdrawal_confirmations = withdrawSettings.withdrawal_confirmations
    state.withdrawSettings.withdrawal_service_fee = withdrawSettings.withdrawal_service_fee
    cookies.set("withdrawSettings", withdrawSettings,  { expires: 7 })
  }
}
export const getters: SettingsGetter = {
  getGaCode: (state: SettingsState) => state.gaCode,
  getGTMCode: (state: SettingsState) => state.GTMCode,
  getBrandId: (state: SettingsState) => state.brandId,
  getPlatformUrl: (state: SettingsState) => state.platformUrl,
  getPlatformCurrency: (state: SettingsState) => state.platformCurrency,
  getPlatformVersion: (state: SettingsState) => state.version,
  getPlatformName: (state: SettingsState) => state.platformName,
  getApiUrl: (state: SettingsState) => state.apiUrl,
  getCasinoApiUrl: (state: SettingsState) => state.casinoApiUrl || "",
  getApiSocialAuthUrl: (state: SettingsState) => state.apiSocialAuthUrl,
  getWalletManagerUrl: (state: SettingsState) => state.walletManagerUrl,
  getMinimumWithdrawal: (state: SettingsState) => state.withdrawSettings.minimum_withdrawal,
  getDepositConfirmations: (state: SettingsState) => state.withdrawSettings.deposit_confirmations,
  getWithdrawalConfirmations: (state: SettingsState) => state.withdrawSettings.withdrawal_confirmations,
  getWithdrawalServiceFee: (state: SettingsState) => state.withdrawSettings.withdrawal_service_fee,
  getMoonpayApiKey: (state: SettingsState) => state.moonPayApiKey,
  getMoonpayUrl: (state: SettingsState) => state.moonPayUrl,
  getXanpoolUrl: (state: SettingsState) => state.xanpoolUrl,
  getXanpoolApiKey: (state: SettingsState) => state.xanpoolApiKey,
  getGamesRepoUrl: (state: SettingsState)  => {
    const mirrorDomainsList = process.env.VUE_APP_MIRROR_DOMAINS || '';
    const mirrorDomains=mirrorDomainsList.split(',').map(s => s.trim()).filter(Boolean);
    const matchedDomain = mirrorDomains.filter(domain => location.hostname.includes(domain));

    if(matchedDomain.length > 0) {
      const url:URL = new URL(location.href);
      return `${url.protocol}//play01.${matchedDomain[0]}`
    }
    
    return state.gamesRepoUrl;
  },
}

export const actions: ActionTree<SettingsState, RootState> = {
  async getWithdrawRules ({ dispatch, commit, getters, rootGetters }) {
    if(!rootGetters['account/isLoggedIn']) {
      return;
    }
    if(cookies.get("withdrawSettings")) {
      console.log("load in withdraw settings from cookies")
      commit('updateWithdrawSettings', cookies.get("withdrawSettings"))
    }
    try {
      const endpoint = `/api/wms/user/rules?currency=${rootGetters['settings/getPlatformCurrency']}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getWalletManagerUrl'] + endpoint,
        {},
        cookies.get('token')
      )
      const res = await axios(rqOptions)
      if(res.data) {
        commit('updateWithdrawSettings', res.data)
      } else {
        throw new Error("could not retrieve rules for withdrawing")
      }
    } catch (e) {
      console.log(e)
      console.log("Could not get withdraw rules")
    }
  }
}
export const settings: Module<SettingsState, RootState> = {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
