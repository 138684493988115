<template>
  <div 
    :style="backgroundStyle"
    class="background"
  >
    <div 
      :style="modalStyle"
      class="pop-up"
    >
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: "PopUp",
  props: {
    // for background
    backdropFilter: {
      type: String,
      required: false,
      default: "blur(8px)"
    },
    backgroundBackgroundColor: {
      type: String,
      required: false,
      default: "rgba(34, 37, 42, 0.6)"
    },

    // for modal
    color: {
      type: String,
      required: false,
      default: "white"
    },
    modalBackgroundColor: {
      type: String,
      required: false,
      default: "black"
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: false,
      default: "auto"
    },
    padding: {
      type: String,
      required: false,
      default: "24px 32px"
    },
    margin: {
      type: String,
      required: false,
      default: "40px auto auto auto"
    },
    mobileMargin: {
      type: String,
      required: false,
      default: "40px 16px auto 16px"
    }
  },

  data: () => {
    return {
      isMobile:/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent)
    }
  },

  computed: {
    backgroundStyle() {
      return {
        backdropFilter: this.backdropFilter,
        backgroundColor: this.backgroundBackgroundColor
      };
    },

    modalStyle() {
      return {
        color: this.color,
        backgroundColor: this.modalBackgroundColor,
        width: this.isMobile ? '' : this.width,
        height: this.height,
        padding: this.isMobile? '32px 16px' : this.padding,
        margin: this.isMobile ? this.mobileMargin : this.margin
      };
    },
  },
}
</script>

<style scoped lang="scss">
html {
  font-family: Gilroy-Normal, sans-serif;
}

.background {
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  position: fixed;
}

.pop-up {
  z-index: 9999;
  border-radius: 8px;
}
</style>