





































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from "vuex-class";
import Common from "../../mixins/Common.vue"
const AccountAction = namespace("account").Action;
const BonusWagerGetter = namespace("bonusWager").Getter;
const AccountGetters = namespace("account").Getter;
const SettingsGetter = namespace('settings').Getter
const AccountMutation = namespace('account').Mutation

const AppProps = Vue.extend({
  mixins: [Common],
  methods: {
    async showIframeDepositPopup () {
      $('body').prepend('<div class="bs-canvas-overlay bg-dark position-fixed w-100 h-100"></div>');
      $('.offcanvas-collapse').addClass('open');
      this.setCanvasOpen(true);
      window.scrollTo(0, 0);
      if (this.limitedAccount) {
        this.$bvModal.show('new-restricted-region-modal')
      } else if (!this.userAddress.length) {
        await this.getDepositAddress();
      }
    },
    toggleBonusBar() {
      if($('#toggleButton').hasClass('fa-angle-up')){
        $('#toggleButton').removeClass('fa-angle-up');
        $('#toggleButton').addClass('fa-angle-down');
      } else {
        $('#toggleButton').removeClass('fa-angle-down');
        $('#toggleButton').addClass('fa-angle-up');
      }
      document!.getElementById('bonusBarBottom')!.classList.toggle("show");
    }
  }
})
@Component({
  components: {},
})
export default class FooterMobile extends AppProps {
  @BonusWagerGetter getBonusClaimTotalWager;
  @BonusWagerGetter getBonusClaimMaxWager;
  @BonusWagerGetter getIsAvailableBonus;
  @BonusWagerGetter getBonusWagerCampaignName;
  @AccountAction getDepositAddress;
  @AccountGetters userBonusBalance;
  @AccountGetters hasUnconfirmedTx
  @AccountGetters userAddress;
  @AccountGetters limitedAccount;
  @AccountGetters userBalance;
  @AccountGetters isLoggedIn: boolean
  @SettingsGetter getPlatformCurrency!: String;
  @AccountMutation setCanvasOpen: boolean
}
