import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import store from '@/store'
import en from '../localLangFiles/en.json'
import es from '../localLangFiles/es.json'
import pt from '../localLangFiles/pt.json'
import ru from '../localLangFiles/ru.json'
const cookies = require('js-cookie')

Vue.use(VueI18n);

export const i18n = new VueI18n({
  // Try to set language first from the cookie, then from Hrzn user profile;
  // then from the user's browser settings, with the last fallback in line being  English
  locale: cookies.get("userLanguage") || store.getters['account/userLanguage'] || navigator.language.split('-')[0],
  fallbackLocale: 'en',
  messages: {
    en,
    es,
    ru,
    pt
  },
  silentTranslationWarn: true // set locale messages
})

const loadedLanguages = []; // default language that is preloaded

function setI18nLanguage (lang: string) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  window.postMessage(lang, window.location.origin);
  return lang;
}

export async function loadLanguageAsync(lang: string) {

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  const response = await fetch(`${process.env.VUE_APP_STRAPI_API_URL}/lang-strings?_limit=6000`)
  const strings = await response.json();
  const stringsByLanguage: any = strings.reduce((acc: any, data: any) => {
      if (!acc[data.lang])
          acc[data.lang] = {}
      acc[data.lang][data.key] = data.text
      return acc
  }, {});
  const supportedLanguages = JSON.parse(process.env.VUE_APP_STRAPI_LANGUAGES)

  supportedLanguages.forEach(lang => {
    const messages = stringsByLanguage[lang];
    i18n.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
  });

  if(!loadedLanguages.includes(lang) && !cookies.get("userLanguage")) {
    lang = 'en';
    cookies.set("userLanguage", lang);
  }
  return Promise.resolve(setI18nLanguage(lang));
}

// @ts-ignore
window.i18n = i18n;
