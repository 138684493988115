
































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import QrcodeVue from "qrcode.vue";

const SettingsGetter = namespace('settings').Getter;
const AccountGetter = namespace('account').Getter;

const AppProps = Vue.extend({
  data: () => {
    return {
      isAppleDevice: /iphone|ipod|ipad/i.test(navigator.userAgent),
    }
  },
  methods: {
    closeModal () {
      ($(this.$refs.modal) as any).modal('hide')
      // this.setSelectedPaymentMethod('')
    },
  },
});
@Component({
  components: { QrcodeVue },
})
export default class DepositDialog extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @AccountGetter userAddress
}

