import 'jquery'
import moment from 'moment'
import underscore from 'vue-underscore'
import Vue from 'vue'
import App from './App.vue'
import './assets/scss/vuetify.scss'
import vuetify from './plugins/vuetify';
import router from './router'
import { store } from './store'
import VueClipboard from 'vue-clipboard2'
import VCalendar from 'v-calendar'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email, is } from 'vee-validate/dist/rules';
import VueScrollTo from 'vue-scrollto'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";
import vSelect from "vue-select";
import VModal from 'vue-js-modal';
import VueAnalytics from 'vue-analytics';
import VueGtm from 'vue-gtm';
import VueMeta from 'vue-meta';
import 'v-calendar/lib/v-calendar.umd.min'
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import VueI18n from 'vue-i18n';
import { i18n } from "@/helpers/i18n"
import LazyLoadDirective from "./directives/LazyLoadDirective";
import vClickOutside from 'v-click-outside';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const WAValidator = require('wallet-address-validator')
const bchaddr = require('bchaddrjs');

const isDev = process.env.NODE_ENV === "development"

Vue.config.productionTip = false

// register any package as a vue module mounted on the scope
// @ts-ignore

import lightbox_me from './vendor/lightbox/jquery.lightbox_me.js'
Object.defineProperty(Vue.prototype, '$lightbox', { value: lightbox_me })

Vue.component("v-select-non-vuetify", vSelect);
Vue.use(VCalendar);
Vue.use(VueScrollTo);
Vue.use(VueToast);
Vue.use(VueMeta);
Vue.use(VueGoodTablePlugin);
Vue.use(BootstrapVue);

// Using/extending vee-validate rules section
extend('email', {
  ...email,
  message: 'invalid_email_address'
});

extend('password', {
  validate: value => value.match(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32})$/) !== null,
  message: 'must_have'
});

extend('eight_char', {
  validate: value => value.length >= 8,
  message: '8_characters_minimum_nma'
});

extend('thirty_two_char', {
  validate: value => value.length <= 32,
  message: 'thirtytwo_characters_max'
});

extend('one_uppercase_char', {
  validate: value => value.match(/[A-Z]/g) !== null,
  message: '1_uppercase_nma'
});

extend('one_lowercase_char', {
  validate: value => value.match(/[a-z]/g) !== null,
  message: '1_lowercase_nma'
});

extend('one_digit', {
  validate: value => value.match(/\d/g) !== null,
  message: 'one_digit'
});

extend('one_special_char', {
  validate: value => value.match(/[!@#$%^&*)(+=._-]/g) !== null,
  message: '1_special_character'
});

extend('required', {
  ...required,
  message: 'this_field_is_required'
});

extend('is', {
  ...is,
  message: 'this_field_must_match'
});

extend('username', {
  validate: value => value.match(/^[a-zA-Z0-9_]+$/) !== null && value.length >= 3 && value.length <= 10,
  message: 'length_must_be_between'
});

// Custom validators for BTC/BCH addresses
extend('BTC', {
  validate: value => WAValidator.validate(value, 'BTC'),
  message: 'please_insert_valid_btc'
});

extend('BCH', {
  validate: value => bchaddr.isValidAddress(value) && !bchaddr.isBitpayAddress(value),
  message: 'please_insert_valid_bch'
});

// Filters Section

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  return moment.utc(String(value)).clone().local().format("MM/DD/YYYY h:mm:ss A")
});

//Capitalize only the first letter of the string
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter('formatNumber', function (num) {
  if (!num) return 0
  return parseFloat(num.toFixed(7))
})

//Capitalize all words of a string.
Vue.filter('capitalizeallwords', function (value) {
  if (!value) return ''
  value = value.toLowerCase()
  return value.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
})

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VueApollo);
Vue.use(VModal);
Vue.use(VueI18n);
Vue.use(vClickOutside);
Vue.use(VModal);
Vue.use(VueLoading,{
  color: "#0ac18e",
  backgroundColor: "#0ac18e",
  width: 150,
  height: 150
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

// @ts-ignore
const gaCode = window.VueStore.getters["settings/getGaCode"]
// @ts-ignore
const gtmCode = window.VueStore.getters["settings/getGTMCode"]
Vue.use(VueAnalytics, {
  id: gaCode,
  disabled: isDev,
  router
})

Vue.use(VueGtm, {
  id: gtmCode,
  vueRouter: router,
  enabled: !isDev
})

Vue.directive("lazyload", LazyLoadDirective);

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
  vuetify,
  i18n
}).$mount('#app')

// router.beforeEach((to, from, next) => {
//   // Redirect to login/signup page if it's not a legacy magic token user's login attempt and the route needs authentication
//   if (!store.getters["account/isLoggedIn"] && !to.query.account_key && to.matched.some(record => record.meta.requiresLogin)) {
//     next({ name: 'loginorsignup', params: { tabIndex: '0' }, query: to.query })
//     return
//   } else {
//     //Ensure that the token is available for the legacy users using magic token to login
//     if (store.getters["account/isLoggedIn"] && store.getters["account/hasMagicToken"] && !store.getters['account/userToken']) {
//       const unwatch = store.watch(
//         () => store.getters['account/userToken']
//         , userToken => {
//           if (userToken) {
//             unwatch()
//             next()
//           }
//         }
//       )
//     } else {
//       next()
//     }
//   }
// })

router.afterEach((to, from) => {

});

Vue.use(VueClipboard)
Vue.use(underscore)
