import { buildGameUrl } from '@/helpers/url'

const axios = require('axios')
const rqBuilder = require('../helpers/store').rqBuild
const _ = require('underscore')
const cookies = require('js-cookie')


import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, GameState } from './types'

type GameGetter = GetterTree<GameState, RootState>

export const state: GameState = {
  games: [],
  lastNumbersDice: [],
  favoriteGames: []
}

export const getters: GameGetter = {
  games: state => state.games,
  lastNumbersDice: state => state.lastNumbersDice,
  favoriteGames: state => state.favoriteGames
}

export const mutations: MutationTree<GameState> = {
  setGames (state, newGames) {
    //push any new non-existing games to the gamelist state
    newGames.forEach((game) => {
      const index = state.games.findIndex(x => x.gameId === game.gameId)
      if (index === -1){
        state.games.push(game);
      } else {
        // update game data if game configurations are changed
        if (!(_.isEqual(game, state.games[index]))) {
          state.games[index] = game;
        }
      }
    })

    for(let i = (state.games.length - 1); i >= 0; i--) {
      const index = newGames.findIndex(x => x.gameId === state.games[i].gameId)
      if( index === -1) {
        state.games.splice(i, 1);
      }
    }

  },
  addDiceNumber(state, number) {
    state.lastNumbersDice.push(number)
    if (state.lastNumbersDice.length > 20) {
      state.lastNumbersDice.shift()
    }
  },
  setFavorites (state, favorites) {
    state.favoriteGames = favorites
  },
  updateFavorite (state, payload) {
    const gameId = payload.game
    const favorite = payload.favorite
    if (favorite) {
      state.favoriteGames.push(gameId)
    } else {
      state.favoriteGames = state.favoriteGames.filter(fav => fav !== gameId)
    }
  }
}

export const actions: ActionTree<GameState, RootState> = {
  async getFavorites ({ dispatch, commit, getters, rootGetters }) {
    if (!rootGetters['account/isLoggedIn']) return;
    try {
      const endpoint = `/favorite/targets/${rootGetters['account/userId']}`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setFavorites', res.data)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('could not get favorites')
    }
  },
  async favoriteGame ({ dispatch, commit, getters, rootGetters }, gameUUID) {
    if (!rootGetters['account/isLoggedIn']) return;
    try {
      // updated favorite locally
      const payload = {
        game: gameUUID,
        favorite: true
      }
      commit('updateFavorite', payload)

      const endpoint = `/favorite/targets?brandId=${rootGetters['settings/getBrandId']}`
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          playerUUID: rootGetters['account/userId'],
          id: gameUUID,
        },
        rootGetters['account/userToken']
      )
      await axios(rqOptions)
    } catch (e) {
      console.log('Could not favorite game')
    }
  },
  async unfavoriteGame ({ dispatch, commit, getters, rootGetters }, gameUUID) {
    if (!rootGetters['account/isLoggedIn']) return;
    const payload = {
      game: gameUUID,
      favorite: false
    }
    commit('updateFavorite', payload)
    try {
      const endpoint = `/favorite/targets/${cookies.get('userId')}/${gameUUID}?brandId=${rootGetters['settings/getBrandId']}`
      const rqOptions = rqBuilder(
        'DELETE',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        rootGetters['account/userToken']
      )
      await axios(rqOptions)
    } catch (e) {
      console.log('Could not unfavorite game')
    }
  },
  async getPublicGamesList ({ dispatch, commit, getters, rootGetters }, refresh) {
    try {
      const endpoint = `/game_info_view/public/games?size=1000&countryCode=${rootGetters['account/userCountryCode']}&brandId=bccashgames&currencyCode=${rootGetters['settings/getPlatformCurrency']}&status=ENABLED`;

      // const endpoint = "/api/games/" + rootGetters['settings/getPlatformCurrency']

      const rqOptions = rqBuilder('GET', rootGetters['settings/getApiUrl'] + endpoint)
      const res = await axios(rqOptions)
      if (res.data) {
        res.data.content.filter(game => game.enabled).forEach(game => {
          const category = _.find(game.tags, { 'key': 'category' })
          const review = _.find(game.tags, { 'key': 'review' })
          const promo = _.find(game.tags, { 'key': 'promo' })
          const featured = _.find(game.tags, { 'key': 'featured' })
          const exclusive = _.find(game.tags, { 'key': 'bitcoincomExclusiveGame' })
          const tournament = _.find(game.tags, { 'key': 'tournament' })
          game.category = category ? category.value : []
          game.review = review ? review.value[0] : 5
          game.featured = featured ? featured.value[0] : 'false'
          game.promo = promo ? promo.value[0] : 'false'
          game.isExclusive = exclusive ? exclusive.value[0] : 'false'
          game.tournament = tournament ? tournament.value[0] : 'false'
        })
        commit('setGames', res.data.content)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log(e);
      console.log('Could not get public games list')
    }
  },
  /**
   *
   * Retrieve game session data (Both for real and fun game sessions, fun game is if gameUrl has '/public/' in it)
   *
   * @param dispatch
   * @param commit
   * @param getters
   * @param rootGetters
   * @param startGameOptions Object Game url and necessary properties
   */
  async initializeThirdPartyGameSession (
    { rootGetters },
    { gameUrl, ...options }
  ) {
    const endpoint = rootGetters['settings/getApiUrl'] + buildGameUrl(gameUrl, options)

    try {
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {},
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        return res.data
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('Could not retrieve game session data')
      return { 'error': e }
    }
  },
  async initializeCasinoSession({dispatch, commit, getters, rootGetters}) {
    try {
      const endpoint = `/api/ping`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {},
        {}
      )
      const res = await axios(rqOptions)
    } catch (e) {
      console.log('could not setup casino session')
      return {'error': e}
    }
  }
}

export const game: Module<GameState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
