

































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class'

const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace('account').Action
const AccountGetter = namespace('account').Getter
const AppProps = Vue.extend({
  data: () => {
    return {
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
        navigator.userAgent
      ),

    }
  },
  async created() {
    await this.getBlockedCountries()
  },
  computed: {
    checkBscBlockedCountries: function () {

      return this.blockedCountriesList.find(country => country.countryCode === this.userCountryCode)
    }
  },
  watch: {
    userCountryCode: function() {
      if (this.blockedCountriesList.find(country => country.countryCode === this.userCountryCode)) {
        setTimeout(() => {
          this.redirectToBitcoinGambling()
        }, 5000);
      }
    }
  },
  methods: {
    redirectToBitcoinGambling() {
      window.location.href = 'https://www.bitcoin.com/gambling/';
    },
  }
})
@Component
export default class NewRestrictedRegion extends AppProps {
  @SettingsGetter getPlatformName!: String
  @AccountGetter userCountryCode
  @AccountGetter blockedCountriesList
  @AccountAction getBlockedCountries
}


