



















import { Component, Vue } from "vue-property-decorator";

const AppProps = Vue.extend({
  props: {},
  mounted() {
    this.$bvModal.show('create-account-dialog')
  },
  methods: {
    login: function() {
      this.$router.push('/player-login')
    },
    signup: function() {
      this.$router.push('/signup')
    }
  }
})
@Component({
  components: {}
})
export default class ProvablyFairDialog extends AppProps {}
