<template>
  <div class="skeleton">
    <div :class="[isMobile ? `skeleton-image-mobile` : `skeleton-image`]" />
    <div class="skeleton-title" />
    <div class="skeleton-rating" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
        navigator.userAgent
      ),
    }
  },
}
</script>

<style scoped>
.skeleton {
  flex-grow: 1;
}

.skeleton-image {
  height: 148px;
  max-width: 95%;
  overflow: hidden;
  position: relative;
  background: #38454d;
}

.skeleton-image::before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  transform: translateX(-50%);
  animation: loading 1.5s infinite;
  background: linear-gradient(to right, transparent, #4c5961, transparent);
}

.skeleton-image-mobile {
  height: 89px;
  max-width: 95%;
  overflow: hidden;
  position: relative;
  background: #38454d;
}

.skeleton-image-mobile::before {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  transform: translateX(-50%);
  animation: loading 1.5s infinite;
  background: linear-gradient(to right, transparent, #4c5961, transparent);
}

.skeleton-title,
.skeleton-rating {
  height: 20px;
  margin: 6px 0 !important;
}

.skeleton-title {
  background: linear-gradient(
    90deg,
    rgba(56, 69, 77, 1) 30%,
    rgba(9, 9, 121, 0) 69%,
    rgba(0, 212, 255, 0) 93%
  );
}

.skeleton-rating {
  background: linear-gradient(
    90deg,
    rgba(56, 69, 77, 1) 17%,
    rgba(9, 9, 121, 0) 42%,
    rgba(0, 212, 255, 0) 66%
  );
}

@keyframes loading {
  50% {
    transform: translateX(100%);
  }
}
</style>
