





















import {Vue, Component} from 'vue-property-decorator';
import BaseProgression from '@/components/widgets/WorldCupProgression/BaseProgression.vue';
const AppProps = Vue.extend({
  mixins: [BaseProgression],
  data() {
    return {
      intervalId: null,
      current: 0,
    }
  },
  async mounted() {
    await this.fetchPoints()
    this.intervalId = setInterval(this.pollAvailablePredictions, 20000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
})
@Component
export default class Navbar extends AppProps {
}
