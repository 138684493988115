//Todo: This should all be merged with active_promotions.ts as the requests are repeated / code is not DRY?

const axios = require('axios')
const rqBuilder = require('../helpers/store').rqBuild
const cookies = require('js-cookie')
const _ = require('underscore')

import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, BonusClaimsState } from './types'
import apolloClient from '../vue-apollo';
import gql from "graphql-tag";

type BonusWagerGetter = GetterTree<BonusClaimsState, RootState>

export const state: BonusClaimsState = {
  wagered: {
    amount: 0,
    currency: ''
  },
  amountToWage: {
    amount: 0,
    currency: ''
  },
  campaignName: '',
  isAvailable: false,
  activeFreeSpinsGamesIds: []
}

export const getters: BonusWagerGetter = {
  getBonusClaimTotalWager: state => state.wagered.amount,
  getBonusClaimMaxWager: state => state.amountToWage.amount,
  getIsAvailableBonus: state => state.isAvailable,
  getBonusWagerCampaignName: state => state.campaignName,
  // Used on games list view, to redirect the games that user has no free spins on, to deposit dialog if the user balance is 0
  activeFreeSpinsGamesIds: state => state.activeFreeSpinsGamesIds,
}

export const mutations: MutationTree<BonusClaimsState> = {
  setActiveFreeSpinsGamesIds (state, content) {
    state.activeFreeSpinsGamesIds = []
    content.forEach((promo) => {
      if (promo.rewardUUID.startsWith('FREE-SPIN') && promo.status === "ACTIVE") {
        state.activeFreeSpinsGamesIds.push(promo.data.gameId)
      }
    })
  },
  async setBonusClaims(state, content) {
    let banner_id = cookies.get('banner_id');
    if(banner_id === undefined) {
      banner_id = 'default';
    }

    const response = await apolloClient.query({
      query: gql`
            query Promos($casinoCode: String!, $status: String!, $bannerId: String!) {
              casinos(where: { name: $casinoCode }) {
                name
                promotions(
                  where: { status: $status, advertisements: { banner_id: $bannerId } }
                ) {
                  id
                  index
                  description
                  wager_requirement
                  campaign_name
                  requirements
                  terms_and_condition
                  terms_and_condition_ru
                  terms_and_condition_es
                  terms_and_condition_pt
                  buttonText
                  auto_opt_in
                  redirectToAfterClaim
                  uuid
                  status
                  is_deposit
                  is_playnow
                  is_freespins
                  is_disabled
                  advertisements(where: { banner_id: $bannerId }) {
                    banner_id
                  }
                  image {
                    formats
                  }
                }
              }
            }
        `,
      variables: {
        status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published",
        casinoCode: cookies.get('casino_code'),
        bannerId: banner_id
      },
      fetchPolicy: "no-cache"
    });
    const casino_campaigns = response.data.casinos.find(c => c.name == cookies.get('casino_code'))
    let campaignData = [];
    if(content.length > 0) {
      if(content[0].rewardUUID.startsWith('BONUS-')) {
        if(content[0].data.wagered != null) {
          state.wagered = content[0].data.wagered;
        }
        state.amountToWage = content[0].data.amountToWage;
        campaignData = casino_campaigns.promotions.filter(
          function(data){ return data.uuid == content[0].authorUUID }
        );

        if(campaignData.length) {
          state.campaignName = campaignData[0].campaign_name;
        } else {
          state.campaignName = '';
        }
        state.isAvailable = true;
      } else {
        state.isAvailable = false;
      }
    } else {
      state.wagered = {
        amount: 0,
        currency: ''
      }
      state.amountToWage = {
        amount: 0,
        currency: ''
      }
      state.campaignName = ''
      state.isAvailable = false
    }
  }
};

export const actions: ActionTree<BonusClaimsState, RootState> = {
    async getActiveAndPendingPromotions({ commit, rootGetters }) {
      try {
        const endpoint = `/reward_view/${cookies.get('userId')}?status=ACTIVE,PENDING`;
        const rqOptions = rqBuilder(
          'GET',
          rootGetters['settings/getApiUrl'] + endpoint,
          {},
          rootGetters['account/userToken']
        );

        const res = await axios(rqOptions);
        if (res.data) {
            commit('setBonusClaims', res.data.content);
            commit('setActiveFreeSpinsGamesIds', res.data.content)
        } else throw new Error('Invalid response from server');
      } catch (e) {
        return {
          error: 'Could not get bonus claims'
        }
      }
    }
}

export const bonusWager: Module<BonusClaimsState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};
