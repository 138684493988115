












































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueMarkdownIt from "vue-markdown-it";
const cookies = require('js-cookie')
const AccountAction = namespace("account").Action;
const AvailablePromotionsCampaignAction = namespace('availablePromotionsCampaign').Action

const AppProps = Vue.extend({
  data: () => {
    return {
        showToS: false,
        VUE_APP_STRAPI_API_URL: process.env.VUE_APP_STRAPI_API_URL,
    }
  },
  props: {
      targetedPromotion: Object
  },
  async mounted () {},
  methods: {
    closeModal () {
        cookies.set(`tpromotiondialogshown-${this.targetedPromotion.id}`, "true", { expires: 365 });
        ($(this.$refs.targeted_promo_modal) as any).modal('hide')
        // this.setSelectedPaymentMethod('')
    },
    async acceptTargetedPromotion() {
        cookies.set(`tpromotiondialogshown-${this.targetedPromotion.id}`, "true", { expires: 365 });
        if(this.targetedPromotion.isOptIn){
          await this.claimPromotionCampaign(this.targetedPromotion.campaignUUID);
        }
        ($(this.$refs.targeted_promo_modal) as any).modal('hide');
        if (this.targetedPromotion.type === 'deposit_now'){
            await this.getDepositAddress();
            ($('#pre_deposit_dialog') as any).modal('show');
        } else {
            window.location.href = this.targetedPromotion.cta_link;
        }
    },
  },
  async created () {}
})
@Component({
  components: {
      VueMarkdownIt,
  }
})
export default class TargetedPromotionDialog extends AppProps {
    @AccountAction getDepositAddress
    @AvailablePromotionsCampaignAction claimPromotionCampaign
}
