

































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const SettingsGetter = namespace('settings').Getter
const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action
const UIMutation = namespace('ui').Mutation
const AppProps = Vue.extend({
  props: {},
})
@Component({
  name: 'home',
  components: {},
  async mounted () {
    $("#ultra_container").removeClass("heightlock");
    this.setSimpleFooter(false)
    this.setFooterDisabled(false)
    this.setBalanceDisabled(false)
    if (!this.usernameRetrieved) {
      // Wait for first time player to be initiated/logged in before retrieving details
      if (!this.userToken) {
        const unwatch = this.$store.watch(() => this.userToken, async (newToken) => {
          if (newToken) {
            await this.getUserData();
            unwatch()
          }
        }, {immediate: true})
      } else {
        await this.getUserData();
      }
    }
  }
})
export default class Promo extends AppProps {
  @UIMutation('setSimpleFooter') setSimpleFooter
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @AccountAction getUserData
  @AccountGetter usernameRetrieved!: boolean;
  @AccountGetter userToken!: boolean;
  @SettingsGetter getPlatformCurrency!: String
}
