



























































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {namespace} from "vuex-class";
import { QrcodeStream } from 'vue-qrcode-reader'

const cookies = require('js-cookie')
const SettingsGetter = namespace('settings').Getter
const SettingsAction = namespace('settings').Action
const AccountGetter = namespace('account').Getter
const AccountAction = namespace('account').Action
const WithdrawalAction = namespace("withdrawal").Action
const ActivePromotionsCampaignGetter = namespace('activePromotionsCampaign').Getter
const ActivePromotionsCampaignAction = namespace('activePromotionsCampaign').Action


const AppProps = Vue.extend({
  data: () => {
    return {
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
        navigator.userAgent
      ),
      withdrawal_amount: '',
      password: '',
      password_repeat: '',
      withdrawal_error_message: '',
      withdrawal_success_message: '',
      withdraw_loading: false,
      withdraw_everything: false,
      withdrawal_address: '',
      gauth_code: '',
      is_bonus_forfeit: false,
      error_message: {
        withdrawal_address: '',
        amount: '',
        password: '',
        gauth: ''
      },
      is_scan_address: false,
      is_response_load: false,
      is_withdrawal_success: false,
      is_auth_required: false
    }
  },
  async mounted () {
    await this.getWithdrawRules();
    await setTimeout(async () => {
      if(cookies.get('isMagicTokenLogin') === "true") {
        await this.getMagicTokenAndAuthData();
      }
      await this.getUserData();
      await this.getFixedWithdrawalAddress();
    }, 3000);
  },
  methods: {
    showModal () {
        this.$modal.show('contact-support-nma-modal');
    },
    hideModal () {
        this.$modal.hide('contact-support-nma-modal');
    },
    withdrawEverythingToggle () {
      this.withdrawal_amount = this.userBalance;
      this.limitWithdrawalAmountDecimalPlaces(8);
    },
    withdrawalDisabled() {
      return Object.values(this.error_message).some(x => (x !== '') || (this.userBalance - this.withdrawal_amount) < 0)
    },
    async withdraw() {
      this.withdrawal_error_message = ""
      this.withdrawal_success_message = ""
      if (!this.fixedWithdrawalAddress) {
        const errors = this.$refs.withdrawal_destination_address_observer.errors
        if (!this.withdrawal_amount || !this.withdrawal_address) {
          this.withdrawal_error_message = this.$i18n.t('please_fill_in_all')
          return
        } else if (errors.withdrawal_destination_address.length) {
          this.withdrawal_error_message = this.$i18n.t('invalid_address')
          return
        }
      } else if (!this.withdrawal_amount) {
          this.withdrawal_error_message = this.$i18n.t('please_fill_in_all')
          return
      }

      if(this.twofaAuthEnabled || this.securedByPassword || this.legacyPassword) {
        this.is_auth_required = true;
      } else {
        this.submitWithdrawal();
      }
    },
    async submitWithdrawal () {
      this.error_message.password = '';
      this.error_message.gauth = '';
      let code = '';
      for (let i = 1; i <= 6; i++) {
        code += $("#input-2fa > input[tabindex=" + i + "]").val()
      }
      this.gauth_code = code;
      if (!this.fixed_withdrawal_address) {
        if ((this.securedByPassword || this.legacyPassword) && !this.password) {
          this.error_message.password = this.$i18n.t('please_enter_password');
          return
        } else if (this.twofaAuthEnabled && !this.gauth_code) {
          this.error_message.gauth = this.$i18n.t('missing_totp_code');
          return
        } else if (this.twofaAuthEnabled && this.gauth_code.length < 6){
          this.error_message.gauth = this.$i18n.t('invalid_totp_code');
          return
        }
      }

      let payload = {
        amount: this.withdrawal_amount,
        address: this.fixedWithdrawalAddress,
        magic_token: this.magicToken,
        password: this.password,
        totp: this.gauth_code
      }
      if (!this.fixedWithdrawalAddress) payload.address = this.withdrawal_address

      try {
        this.withdraw_loading = true
        if(this.getAnyActivePromoRewardUUID.startsWith('FREE-SPIN')) {
          await this.cancelActiveFreeSpins(this.getAnyActivePromoRewardUUID)
        } else {
          await this.cancelActiveBonus(this.getAnyActivePromoRewardUUID)
        }
        await this.getActiveBonuses()
        let res = await this.createWithdrawal(payload)
        this.password = "";
        this.is_response_load = true;
        if (res.amount) {
          this.is_withdrawal_success = true;
          this.withdrawal_success_message = this.$i18n.t('withdrawal_submitted')
          let payload = {
            'getAll': true,
            'platformCurrency': this.getPlatformCurrency
          }
          await this.getWithdrawalsData(payload);
          await this.getUserBalance();
        } else if (res.error && res.error.data.error) {
          this.withdrawal_error_message = res.error.data.error
        } else {
          this.withdrawal_error_message = this.$i18n.t('something_went_wrong')
        }
        this.withdraw_loading = false
      } catch (e) {
        this.withdrawal_error_message = this.$i18n.t('something_went_wrong')
        this.withdraw_loading = false
      }
    },
    onQRDecode(content) {
      let prefix = {
        'BCH': 'bitcoincash:',
        'BTC': 'bitcoin:'
      }
      this.withdrawal_address = content.replace(prefix[this.getPlatformCurrency], '');
      this.is_scan_address = false;
    },
    backToCasino() {
      this.resetFields();
      $("#close_account_modal").click();
    },
    tryAgain() {
      this.resetFields();
    },
    resetFields() {
      this.error_message.withdrawal_address = '';
      this.withdrawal_error_message = "";
      this.withdrawal_success_message = "";
      this.withdrawal_address = "";
      this.withdrawal_amount = "";
      this.is_withdrawal_success = false;
      this.is_response_load = false;
      this.is_auth_required = false;
    },
    twoFaInput (fieldIndex) {
      let digit = this.$refs['twoFaInput_' + fieldIndex][0]
      if (digit.value == '' || isNaN(parseInt(digit.value))) {
        digit.value = ''
      } else if (fieldIndex < 6) {
        let twofa = this.$refs['twoFaInput_' + (fieldIndex + 1)][0]
        if (twofa) twofa.focus()
      }
    },
    limitWithdrawalAmountDecimalPlaces(count) {
      const numStr = String(this.withdrawal_amount);
      let decimalNumberCount = 0;
      if (numStr.includes('.')) {
        decimalNumberCount = numStr.split('.')[1].length;
      }
      if(decimalNumberCount > count) {
        this.withdrawal_amount = this.toFixedDown(this.withdrawal_amount, count);
      }
    },
    toFixedDown(num, decimalPlaces=0) {
      num = Math.floor(Number(num + "e" + decimalPlaces));
      num = Number(num + "e" + -decimalPlaces);
      num = num.toFixed(decimalPlaces);
      return num;
    }
  }
})

@Component({
  components: {
    QrcodeStream
  }
})

export default class WithdrawalPanel extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @SettingsGetter getWithdrawalServiceFee
  @SettingsAction getWithdrawRules
  @AccountGetter hasUnconfirmedTx
  @AccountGetter twofaAuthEnabled
  @AccountGetter securedByPassword
  @AccountGetter legacyPassword
  @AccountGetter userBalance: Number
  @AccountGetter hasMagicToken
  @AccountAction getUserData
  @AccountAction getUserBalance
  @AccountAction getFixedWithdrawalAddress
  @AccountAction getMagicTokenAndAuthData
  @AccountAction createWithdrawal
  @AccountGetter magicToken!: String
  @AccountGetter fixedWithdrawalAddress
  @WithdrawalAction('getWithdrawals') getWithdrawalsData
  @ActivePromotionsCampaignGetter getAnyActivePromoRewardUUID
  @ActivePromotionsCampaignAction getActiveBonuses
  @ActivePromotionsCampaignAction cancelActiveBonus
  @ActivePromotionsCampaignAction cancelActiveFreeSpins
}
