








































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { _ } from "vue-underscore";
import FadingImg from "@/components/menus/FadingImg.vue";

const GameAction = namespace("game").Action;
const AccountGetter = namespace('account').Getter
const BonusWagerGetter = namespace("bonusWager").Getter;

const AppProps = Vue.extend({
  props: [
    "gamesList", 
    "category", 
    "section", 
    "sideScroll", 
    'filterByAggregator',
    'titleSearch'
  ],
  data: () => {
    return {
      detailModal: false,
      detailGame: {}
    }
  },
  computed: {
    aggregators() {
      return this.gamesList.reduce((a, c) => {
        if (!a.includes(c.providerId)) a.push(c.providerId)
        return a
      }, [])
    },
    listAggregators(): string[] {
        const keys = Object.keys(this.aggregators)

        return keys.filter(key => {
          return this.aggregators[key]
        })
      },
  },
  methods: {
    openDetailScreen(game) {
      this.detailGame = game
      this.detailModal = true
    },

    removeAggregator(name): void {
      this.$emit('remove-aggregator', name);
    },

    clearSearch(): void {
      this.$emit('clear-search')
    },

    closeDetailScreen() {
      this.detailModal = false
    },
    async toggleFavorite(game) {
      if (!game.favorite) {
        let gameUpdate = _.find(this.gamesList, {
          internalGameId: game.internalGameId
        });
        gameUpdate.favorite = true;
        this.$forceUpdate();
        await this.favoriteGame(game.internalGameId);
      } else {
        let gameUpdate = _.find(this.gamesList, {
          internalGameId: game.internalGameId
        });
        gameUpdate.favorite = false;
        this.$forceUpdate();
        await this.unfavoriteGame(game.internalGameId);
      }
    },
    canPlayForReal(game) {
      // If player has real (or bonus if it's not an exclusive game) balance,
      // or free spins for that particular game, allow him to play it for real;
      // otherwise modify 'play now' text and its link to open the deposit modal
      return this.activeFreeSpinsGamesIds.includes(game.gameId)
              || this.userBalance > 0
              || (this.userBonusBalance > 0 && game.isExclusive == 'false')
    }
  }
});
@Component({
  components: {
    FadingImg
  }
})
export default class GamesListMobile extends AppProps {
  @GameAction favoriteGame
  @GameAction unfavoriteGame
  @AccountGetter isLoggedIn
  @AccountGetter userBalance
  @AccountGetter userBonusBalance
  @BonusWagerGetter activeFreeSpinsGamesIds
}
