





































































import { FastMenu } from '@/assets/js/legacy/MVC/Menu/menu.js'
import { Component, Vue } from 'vue-property-decorator'
import {namespace} from "vuex-class";

const UIGetter = namespace('ui').Getter;
const AccountGetter = namespace('account').Getter;

const AppProps = Vue.extend({
  data() {
    return {
      isDesktop: !/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
      navigator.userAgent
    )
    }
  },
  mounted () {
    new FastMenu()
  },
  updated () {
    new FastMenu()
  }
})
@Component({
  components: {}
})
export default class FastGameMenu extends AppProps {
  @UIGetter vueAppKey
  @AccountGetter userTotalBalance
  @AccountGetter isLoggedIn
}
