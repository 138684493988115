


































import FadingImg from "@/components/menus/FadingImg.vue";

export default {
  props: ['gamesList'],
  components: {
    FadingImg,
  },
}
