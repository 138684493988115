















































































import FadingImg from "@/components/menus/FadingImg.vue";

export default {
  props: ["gamesList"],
  data: () => {
    return {
      detailModal: false,
      detailGame: {}
    }
  },
  methods: {
    openDetailScreen(game) {
      this.detailGame = game
      this.detailModal = true
    },

    closeDetailScreen() {
      this.detailModal = false
    },
  },
  components: {
    FadingImg
  }
}
