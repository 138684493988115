const axios = require('axios');
const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
import { GetterTree, MutationTree, Module, ActionTree } from 'vuex';
import { RootState, DepositState } from './types';
import moment from 'moment'

type DepositGetter = GetterTree<DepositState, RootState>;

export const state: DepositState = {
  content: [], // Contains the deposits BUT they change if the date is modified in Transactions History Panel
  allDepositsContent: [], // Contains All the deposits from all the time
  allDepositsLoaded: false, // checks if All the deposits already loaded the first time
};

export const getters: DepositGetter = {
  depositHistory: state => state.content,
  depositsLoaded: state => state.allDepositsLoaded,
  depositsContent: state => state.allDepositsContent
};

export const mutations: MutationTree<DepositState> = {
  setDeposits(state, content) {
    state.content = state.content.concat(content)
  },
  setAllDeposits(state, content) {
    state.allDepositsContent = content;
  },
  setDepositsLoaded(state, isLoaded) {
    state.allDepositsLoaded = isLoaded;
  },
  clearDeposits(state) {
    state.content = []
  }
}

export const actions: ActionTree<DepositState, RootState> = {
  async updateLastDeposit ({getters}, data) {
    const endpoint = '/api/payment/lastdeposit'
    try {
        const rqOptions = rqBuilder (
            'POST',
            endpoint,
            data,
            getters.userToken
        )
        const res = await axios(rqOptions)
        if(res.data) {
            return {
                success: true,
                message: res.data.message 
            }
        } else {
            return {
                success: false,
                message: res.data.message
            }
        }
    } catch (e) {
        console.log("Exception updating last deposit")
        return {
            error: e
        }
    }
  },
  async getAllDeposits({ commit, rootGetters, getters}, payload) {
    if (!cookies.get('userId')) {
      return {
        error: 'No user id present'
      }
    }
    const currency = String(payload.platformCurrency);
    try {
      const startDate = moment(new Date("2019-01-01T00:00:00.000Z")).format('YYYY-MM-DDTHH:mm:ss')
      const endDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
      let endpoint = `/payment_view_ng/payments/${cookies.get('userId')}?currency=${currency}&type=Deposit`
      endpoint = endpoint + `&startDate=${startDate}&endDate=${endDate}`

      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        rootGetters['account/userToken']
      )

      const res = await axios(rqOptions)
      if (res.data) {
        let newDeposit = null;
        if (getters.depositsLoaded) {
          if(getters.depositsContent.length !== res.data.content.length) {
            newDeposit = res.data.content.slice(-1)[0];
          }
        }
        commit('setDepositsLoaded', true)
        commit('setAllDeposits', res.data.content)
        return newDeposit;
      } else throw new Error('Invalid response from server')
    } catch (e) {
      return {
        error: 'Could not retrieve deposits'
      }
    }
  },

  async getDeposits({ commit, rootGetters}, payload) {
    if (!cookies.get('userId')) {
      return {
        error: 'No user id present'
      }
    }
    const startDate = moment(payload.startDate).format('YYYY-MM-DDTHH:mm:ss')
    const endDate = moment(payload.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    const isGetAll = payload.getAll;
    const currency = String(payload.platformCurrency);
    try {
      let endpoint = `/payment_view_ng/payments/${cookies.get('userId')}?currency=${currency}&type=Deposit`

      if(!isGetAll){
        endpoint = endpoint + `&startDate=${startDate}&endDate=${endDate}`
      }
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        rootGetters['account/userToken']
      )

      const res = await axios(rqOptions)
      if (res.data) {
        commit('clearDeposits')
        commit('setDeposits', res.data.content)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      return {
        error: 'Could not retrieve deposits'
      }
    }
  }
}

export const deposit: Module<DepositState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
