const axios = require('axios');
const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
import { GetterTree, MutationTree, Module, ActionTree } from 'vuex';
import { RootState, WithdrawalState } from './types';
import moment from 'moment'

type WithdrawalGetter = GetterTree<WithdrawalState, RootState>;

export const state: WithdrawalState = {
  content: []
};

export const getters: WithdrawalGetter = {
  withdrawalHistory: state => state.content
};

export const mutations: MutationTree<WithdrawalState> = {
  setWithdrawals(state, content) {
    state.content = state.content.concat(content);
  },
  clearWithdrawals(state) {
    state.content = []
  }
};

export const actions: ActionTree<WithdrawalState, RootState> = {
  async getWithdrawals({ commit, rootGetters}, payload) {
    if (!cookies.get('userId')) {
      return {
        error: 'No user id present'
      }
    }
    const startDate = moment(payload.startDate).format('YYYY-MM-DDTHH:mm:ss')
    const endDate = moment(payload.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    const isGetAll = payload.getAll
    const currency = payload.platformCurrency
    try {
      let endpoint = `/payment_view_ng/payments/${cookies.get('userId')}?currency=${currency}&type=Withdraw`

      if(!isGetAll){
        endpoint = endpoint + `&startDate=${startDate}&endDate=${endDate}`
      }
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {},
        rootGetters['account/userToken']
      )

      const res = await axios(rqOptions)
      if (res.data) {
        commit('clearWithdrawals')
        commit('setWithdrawals', res.data.content)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      return {
        error: 'Could not get withdrawals'
      }
    }
  }
}

export const withdrawal: Module<WithdrawalState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
