const countryLocaleMapping : Record<string, string> = {
	'GE-AB': 'ru',
	AM: 'ru',
	BY: 'ru',
	KZ: 'ru',
	KG: 'ru',
	RU: 'ru',
	GS: 'ru',
	TO: 'ru',
	AO: 'pt',
	BR: 'pt',
	CV: 'pt',
	DO: 'pt',
	GQ: 'pt',
	GW: 'pt',
	MZ: 'pt',
	PT: 'pt',
	ST: 'pt',
	AR: 'es',
	BT: 'es',
	CL: 'es',
	CO: 'es',
	CR: 'es',
	CU: 'es',
	DM: 'es',
	EC: 'es',
	SV: 'es',
	GT: 'es',
	HN: 'es',
	MX: 'es',
	NI: 'es',
	PA: 'es',
	PY: 'es',
	PE: 'es',
	RE: 'es',
	ES: 'es',
	UY: 'es',
	VU: 'es',
};

export default countryLocaleMapping