<template>
  <img
    :class="imgIsLoaded ? 'show' : ''"
    :data-url="dataUrl"
    :alt="alt"
    :title="postTitle"
    :src="dataUrl"
    loading="lazy"
    @error="setDefaultImg"
    @load="imgLoadedMethod"
  />
</template>
<script>
// @todo: move to a constants file
const GAME_DEFAULT_THUMBNAIL = '/static/images/no_image.png';

export default {
  props: ['postTitle', 'dataUrl', 'alt', 'title'],
  data () {
    return {
      imgIsLoaded: false
    }
  },
  methods: {
    imgLoadedMethod () {
      this.imgIsLoaded = true
    },
    setDefaultImg(e) {
      e.target.src = GAME_DEFAULT_THUMBNAIL
    }
  }
}
</script>
<style scoped>
img {
  opacity: 0;
  min-height: 100%;
  transform: scale(0.97) !important;
  transition: 1s;
}

img.show {
  transform: scale(1) !important;
  opacity: 1;
}
</style>