import {GetterTree, MutationTree, Module, ActionTree} from 'vuex'

import {ExchangeRate, Match, Prediction, WorldCupState, RootState, PredictionResult} from './types'
import moment from "moment";

const rqBuilder = require('../helpers/store').rqBuild
const axios = require('axios');

type WorldCupStateGetter = GetterTree<WorldCupState, RootState>

export const state: WorldCupState = {
  rates: [],
  predictions: [],
  predictionsToday: [],
  predictionResults: [],
  matches: [],
  liveGameData: [],
  betPredictions: [],
  scoresByIds: [],
  areMatchesLoaded: false,
  betPredictionResult: []
}

export const getters: WorldCupStateGetter = {
  getRates: state => state.rates,
  getPredictions: state => state.predictions,
  getPredictionsToday: state => state.predictionsToday,
  getPredictionResults: state => state.predictionResults,
  getMatches: state => state.matches,
  getLiveGameData: state => state.liveGameData,
  getBetPredictions: state => state.betPredictions,
  getScoresByIds: state => state.scoresByIds,
  getMatchesLoaded: state => state.areMatchesLoaded,
  getBetPredictionResult: state  => state.betPredictionResult,
}

export const mutations: MutationTree<WorldCupState> = {
  addExchangeRate(state, payload) {
    state.rates = [...state.rates, payload]
  },
  setMatches(state, payload: Match[]) {
    state.matches = payload
  },
  addMatch(state, payload: Match) {
    state.matches = [...state.matches, payload]
  },
  setPredictions(state, payload) {
    state.predictions = payload
  },
  setPredictionResults(state, payload: PredictionResult[]) {
    state.predictionResults = payload
  },
  setScoresByIds(state, payload) {
    state.scoresByIds = payload
  },
  setLiveGameData(state, payload) {
    state.liveGameData = payload;
  },
  setBetPredictions(state, payload) {
    state.betPredictions = payload;
  },
  setMatchesLoaded(state, payload: boolean) {
    state.areMatchesLoaded = payload;
  },
  setBetPredictionResults(state, payload){
    state.betPredictionResult = payload;
  }
}

export const actions: ActionTree<WorldCupState, RootState> = {
  async getExchangeRate({commit, rootGetters}, date: Date) {
    // Check if the exchange rate is already in our store
    let exchangeRate = rootGetters['worldCup/getRates'].find((exchange: ExchangeRate) => {
      const exchangeDate = new Date(exchange.createdAt);

      if (exchangeDate.getDay() !== date.getDay()) {
        return false
      }

      if (exchangeDate.getMonth() !== date.getMonth()) {
        return false
      }

      return exchangeDate.getHours() === date.getHours()
    })
    if (!exchangeRate) {
      try {
        const isoDate = moment(date).format('YYYY-MM-DD')
        const isoTime = moment(date).format('HH:mm:ss')
        const endpoint = `/api/exchange-rate/${isoDate}/${isoTime}`
        const rqOptions = rqBuilder(
          'GET',
          endpoint,
        )
        const res = await axios(rqOptions)
        if (res.data) {
          exchangeRate = res.data
          commit('addExchangeRate', res.data)
        }
      } catch (e) {
        console.log(`Could not get exchange rate for ${date.getHours()}`)
      }
    }
    return exchangeRate
  },
  async getMatches({commit, rootGetters}) {
    try {
      const endpoint = `/api/worldcupgames`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {},
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      commit('setMatchesLoaded', true)
      if (res.data) {
        commit('setMatches', res.data)
        return res.data;
      }
    } catch (e) {
      console.log('Could not get world cup games')
    }
  },
  async getPredictions({commit, rootGetters}) {
    try {
      const endpoint = `/api/worldcup/user/allbetprediction`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setPredictions', res.data.prediction)
      }
    } catch (e) {
      console.log('Could not get world cup predictions')
    }
  },
  async getMatch({commit, rootGetters}, matchId) {
    try {
      if (!matchId) return;
      const endpoint = `/api/worldcup/match/${matchId}`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
      )
      const res = await axios(rqOptions)
      return res.data.data
    } catch (e) {
      console.log('Could not get world cup predictions')
    }
  },
  async getScoresByIds({commit, rootGetters}, arrIds) {
    try {
      if (!Array.isArray(arrIds) || !arrIds.length) return;
      const endpoint = `/api/worldcup/match/scores`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {ids: arrIds.join(',')}
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setScoresByIds', res.data)
        return res.data
      }
    } catch (e) {
      console.log('Could not get world cup predictions')
    }
  },
  async getPredictionResults({commit, rootGetters}) {
    try {
      const endpoint = '/api/worldcup/user/prediction-results'
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setPredictionResults', res.data.data)
        return res.data
      }
    } catch (e) {
      console.log('Could not get world cup predictions')
    }
  },
  async getBetPredictionResults({commit, rootGetters}){
    try{
      const endpoint = '/api/worldcup/user/betPredictionResult'
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setBetPredictionResults', res.data.data)
        return res.data
      }
    }catch(e){
      console.log('Could not get world cup predictions')
    }
  },
  async getLiveScore({commit, rootGetters}) {
    try {
      const endpoint = '/api/worldcup/live'
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
      );
      const res = await axios(rqOptions)
      if (res.data) {
        commit("setLiveGameData", res.data.data)
      } else {
        return {
          success: false,
          error: 'Error getting live score'
        }
      }
    } catch (e) {
      console.log('Could not get live score', e)
    }
  },
  async getAllBetPredictions({commit, rootGetters}) {
    try {
      const endpoint = `/api/worldcup/user/allbetprediction`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {},
        rootGetters['account/token']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        commit('setBetPredictions', res.data.prediction);
      }
    } catch (e) {
      console.log('Could not get world cup predictions')
    }
  },
  async getUserPredictionsToday({ commit, rootGetters }) {
    // Returns the predictions of the user for the current UTC day.
    try {
      const endpoint = `/api/worldcup/user/predictions`
      const rqOptions = rqBuilder(
        'GET',
        endpoint,
        {
          date: moment().utc().startOf('day').toISOString()
        },
        rootGetters['account/token']
      )
      const res = await axios(rqOptions);
      const predictionsToday = res.data ? res.data.prediction : []
      commit('setPredictionsToday', predictionsToday)
    } catch (e) {
      console.log('Could not get world cup predictions')
      return []
    }
  },
}

export const worldCup: Module<WorldCupState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
  