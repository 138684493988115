








































import { Component, Vue } from 'vue-property-decorator'
import Common from "../../mixins/Common.vue"
import { namespace } from 'vuex-class'
const SettingsGetter = namespace('settings').Getter
const AccountAction = namespace("account").Action
const AccountGetter = namespace("account").Getter
const AccountMutation = namespace("account").Mutation

const AppProps = Vue.extend({
  mixins: [Common],
  data: () => {
    return {
     isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
      navigator.userAgent
     )
    }
  },
  mounted() {
    if (!this.userAddress) {
      this.getDepositAddress()
    }
    let modal = this.$refs['deposit-incoming-modal']
    modal.show()
  }
});

@Component
export default class DepositIncomingDialog extends AppProps {
  @SettingsGetter getPlatformCurrency
  @AccountAction getDepositAddress
  @AccountGetter userAddress
  @AccountMutation setDepositIncomingCryptoAmountToZero
  @AccountGetter depositIncomingCryptoAmount
  @AccountGetter depositIncomingUSDAmount
}
