export const buildGameUrl = (gameUrl: string, options: Object) => {
  const [path, queryParams] = gameUrl.split("?");

  // Note(@Oussa): This won't work in IE browsers*
  // * https://caniuse.com/urlsearchparams
  const params = new URLSearchParams(queryParams);
  for (const param of Object.keys(options)) {
    
    // don't override params from gameUrl
    !params.has(param) && params.set(param, options[param]);
  }

  return [path, params.toString()].join("?");
};
