import Game from '../Game/game.js'
import { store } from '../../../../../store/index'

const CLASSIC_JPS = {
  'bitcoincom:Slots': 'slots',
  'bitcoincom:Blackjack': 'blackjack',
  'bitcoincom:VideoPoker': 'videopoker',
  'bitcoincom:Roulette': 'roulette',
  'bitcoincom:Keno': 'keno',
  'bitcoincom:Craps': 'craps',
  'bitcoincom:Dice': 'dice'
}

export default class MenuController {
  constructor (model) {
    this.model = model
    this.fetchGames()
  }

  toggleSlide (options) {
    this.model.selectorIndex = 0
    if (options.categoryId) {
      this.model.showFavorites = false
      this.toggleShowCategory(options.categoryId)
    } else if (options.favorite) {
      this.model.activeCategory = 0
      this.toggleShowFavorites()
    }
    this.model.open = !!this.model.activeCategory || this.model.showFavorites
    this.setGamesToDraw()
    this.model.notifyAll()
  }

  toggleFilterOpen () {
    this.model.filterOpen = !this.model.filterOpen
    this.model.notifyAll()
  }

  setSearchString (str) {
    this.model.searchString = str
    this.model.notifyAll()
  }

  setGameProvider (str) {
    this.model.gameProvider = str
    this.model.notifyAll()
  }

  closeMenu () {
    this.model.open = false
    this.model.activeCategory = 0
    this.model.showFavorites = false
    this.model.notifyAll()
  }

  toggleShowCategory (categoryId) {
    if (categoryId === this.model.activeCategory) {
      this.model.activeCategory = 0
    } else {
      this.model.activeCategory = categoryId
    }
  }

  showCategory (categoryId) {
    this.model.showFavorites = false
    this.model.activeCategory = categoryId
    this.setGamesToDraw()
    this.model.notifyAll()
  }

  toggleShowFavorites () {
    this.model.showFavorites = !this.model.showFavorites
  }

  showFavorites () {
    this.model.activeCategory = 0
    this.model.showFavorites = true
    this.setGamesToDraw()
    this.model.notifyAll()
  }

  decreaseIndex (gameAmount) {
    this.model.selectorIndex -= gameAmount
    if (this.model.selectorIndex < 0) this.model.selectorIndex = 0
    this.model.notifyAll()
  }

  increaseIndex (gameAmount) {
    if (gameAmount > 0) {
      this.model.selectorIndex += gameAmount
    }
    this.model.notifyAll()
  }

  setGamesToDraw () {
    if (this.model.showFavorites) {
      this.model.visibleGames = this.getFavoriteGames()
    } else {
      this.model.visibleGames = this.getActiveCategoryGames()
      this.model.visibleGames.forEach(g => {
        // clean all 'green' stars
        // eslint-disable-next-line no-undef
        $(g.view.body).find('.rating svg[class^=\'rating_\']').removeClass('green-star')
        // add 'green' class
        for (let i = 1; i <= g.model.rating; i += 1) {
          let star = g.view.body.querySelector('svg.rating_' + (i))
          if (star) {
            star.classList.add('green-star')
          }
        }
      })
    }
  }

  getGamesFromCategoryId (id) {
    let isActiveCategory = game => {
      return game.model.categoryData.data.indexOf(id.toString()) != -1
    }
    return this.model.games.filter(game => isActiveCategory(game))
  }

  getActiveCategoryGames () {
    return this.getGamesFromCategoryId(this.model.activeCategory)
  }

  getFavoriteGames () {
    return this.model.games.filter(game => game.model.favorite)
  }

  loadGame (gameIdentifier) {
    window.loadGame(gameIdentifier)
  }

  pushCloneToUpdate (clone) {
    this.model.clonesToUpdate.push(clone)
  }

  clearClonesToUpdate () {
    this.model.clonesToUpdate = []
  }

  async updateFavorites () {
    const favorites = await this.fetchPoint('favorite-games')
    const update = (games, g) => this._updateFavorite(games, g.model.id, favorites.game_ids.includes(g.model.id))
    this.model.games.forEach(g => update(this.model.games, g))
    this.model.clonesToUpdate.forEach(g => update(this.model.clonesToUpdate, g))
  }

  updateFavoritesLocal (gameId, isFavorite) {
    this._updateFavorite(this.model.games, gameId, isFavorite)
    this._updateFavorite(this.model.clonesToUpdate, gameId, isFavorite)

    this.model.games.forEach(game => {
      if (game.model.id == gameId && isFavorite) {
        game.view.drawFavorite()
      }
      if (game.model.id == gameId && !isFavorite) {
        game.view.drawNotFavorite()
      }
    })
  }

  _updateFavorite (games, gameId, isFavorite) {
    const game = games.find(g => g.model.id === gameId)
    if (game) {
      game.controller.setFavorite(isFavorite)
      game.model.notifyAll()
    }
  }

  async fetchGames () {
    // TODO UPDATE ENDPOINTS
    let gamesList = await this.fetchPoint('games')
    let favorites = { game_ids: [] }
    const loggedIn = store.getters['account/isLoggedIn']
    if (loggedIn) {
      favorites = await this.fetchPoint('favorite-games')
    } else {
      // Filter out games that have no demo mode/free play, if player is not logged in. All exclusive games should have demo mode
      gamesList.games = gamesList.games.filter((game) => {
        return game.hasDemoMode === true || game.isExclusive == 'true'
      })
    }
    let jackpots = {}

    let allGameData = gamesList.games.map(game => {
      const data = {
        favorite: favorites.game_ids.includes(game.internalGameId),
        jackpot: game.identifier in jackpots
          ? ('BCH' in jackpots[game.identifier] ? jackpots[game.identifier]['BCH'] : null)
          : null
      }

      Object.assign(data, game)
      return data
    })
    this.model.games = allGameData.map(game => new Game(game, this))
    this.setGamesToDraw()
    this.model.initialize()
    this.model.notifyAll()
  }

  async fetchJackpots () {
    let classic_data = await fetch('/jackpots')
    let aggr_json = {}
    let classic_json = await classic_data.json()
    for (let pjp in CLASSIC_JPS) {
      if (CLASSIC_JPS[pjp] in classic_json) {
        let val = classic_json[CLASSIC_JPS[pjp]]
        if (typeof (val) == 'object') {
          let _val = 0
          for (let k in val) {
            if (val[k] > _val) {
              _val = val[k]
            }
          }
          aggr_json[pjp] = { 'BCH': Math.round(_val / 1000000) / 100 }
        } else if (typeof (val) == 'number') {
          aggr_json[pjp] = { 'BCH': Math.round(val / 1000000) / 100 }
        } else {
          console.log('Unknown Type:', typeof (val), '-', val)
        }
      }
    }

    return aggr_json
  }

  async fetchCategories () {
    let categories = await this.fetchPoint('categories')
    this.model.categories = categories.categories
    this.model.notifyAll()
  }

  async fetchSections () {
    let sections = await this.fetchPoint('category-sections')
    let featuredSections = sections.sections.filter(s => s.is_featured === 1)
    this.model.featuredSections = (featuredSections && featuredSections.map(sec => sec.id)) || [0]
    this.model.notifyAll()
  }

  async fetchPoint (point) {
    if (point === 'favorite-games') {
      if (store.getters['game/favoriteGames'].length == 0) {
        await store.dispatch('game/getFavorites')
      }
      return {
        game_ids: store.getters['game/favoriteGames']
      }
    }
    if (point === 'games') {
      if (store.getters['game/games'].length == 0) {
        await store.dispatch('game/getPublicGamesList')
      }
      return {
        games: store.getters['game/games']
      }

    }
    let data = await fetch(`/aggregator/${point}?_=${new Date().getTime()}`)
    return await data.json()
  }
}
