<template>
  <div v-if='!isMobile' id="wrapper">
    <div class="content">
      <span class="casino-time">Casino Time: </span>{{ casinoTime }}

      <span class="spacing" />
      <span class="divider" />
      <span class="spacing" />

      <div v-if="this.prices.BTC && this.prices.BCH" class="inline">
        <img src="@/assets/images/common/btc-logo.svg" /> ≈ ${{ prices.BTC }}
        <span class="spacing" />
        <img src="@/assets/images/common/bch-logo.svg" /> ≈ ${{ prices.BCH }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'BitcoinPrice',
  data() {
    return {
      casinoTime: moment.tz(moment(), 'cet').format('ddd[,] MMM D [•] H[:]mm [(CET)]'),
      prices: {
        BTC: null,
        BCH: null
      },
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
        navigator.userAgent
      ),
    }
  },
  methods: {
    updateCasinoTime() {
      this.casinoTime = moment.tz(moment(), 'cet').format('ddd[,] MMM D [•] H[:]mm [(CET)]')
    },

    async fetchPrices(api) {
      try {
        const res = await fetch(api)
        const { BTC, BCH } = await res.json()

        this.prices.BTC = this.addComma(BTC.toFixed(2))
        this.prices.BCH = this.addComma(BCH.toFixed(2))
      } catch (e) {
        console.log(e)
      }
    },

    addComma(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
  created() {
    this.fetchPrices('api/markets')
    this.fetchPrices('api/markets')
  },
  mounted() {
    setInterval(() => {
      this.updateCasinoTime()
    }, 1000)

    setInterval(() => {
      this.fetchPrices('api/markets')
      this.fetchPrices('api/markets')
    }, 60000);
  },
}
</script>

<style scoped>
* {
  font-family: Gilroy-Bold, sans-serif;
}

#wrapper {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  color: white;
  position: fixed;
  padding-left: 60px;
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.6);
}

.content {
  margin-top: 8px;
}

.casino-time {
  bottom: 1px;
  color: #b9bdc6;
  position: relative;
}

.divider {
  border-left: 2px solid rgba(185, 189, 198, 0.4);
}

.spacing {
  margin: 0 10px;
}

.inline {
  display: inline;
}
</style>
