//Todo: This should all be merged with bonus-wager.ts as the requests are repeated / code is not DRY?

const axios = require('axios');
const cookies = require('js-cookie')
const rqBuilder = require('../helpers/store').rqBuild
import { GetterTree, MutationTree, Module, ActionTree } from 'vuex'
import { RootState, ActivePromotionsCampaignState, AvailablePromotionsCampaignState } from './types'
import apolloClient from '../vue-apollo';
import gql from "graphql-tag";

type ActivePromotionsCampaignGetter = GetterTree<ActivePromotionsCampaignState, RootState>

const getDefaultState = () => {
  return {
    authorUUID: '',
    rewardUUID: '',
    status: '',
    bonus_content: {
      bonusUUID: '',
      playerUUID: '',
      label: '',
      currency: '',
      grantedAmount: {
        amount: 0,
        currency: ''
      },
      balance: {
        amount: 0,
        currency: ''
      },
      wagered: {
        amount: 0,
        currency: ''
      },
      amountToWage: {
        amount: 0,
        currency: ''
      },
      state: '',
      createdDate: '',
      startDate: '',
      endDate: '',
      expirationDate: '',
      prize: '',
      capping: {},
      claimable: false,
      bonusLifetime: 0,
      bonusType: '',
      operatorUUID: '',
      campaignUUID: '',
      cancellerUUID: '',
      cancellationReason: '',
      convertedAmount: 0,
      moneyTypePriority: '',
      brandId: '',
      templateUUID: '',
      lockedAmount: {
        amount: 0,
        currency: ''
      },
      maxBet: 0,
      maxBetSport: 0,
      rewardVersion: 0,
      initialLockedAmount: {
        amount: 0,
        currency: ''
      },
      lockAmountStrategy: '',
      bonusDuration: 0,
      description: '',
      requirements: '',
      wager_requirement: 0,
      image: '',
      campaign_name: '',
      terms_and_condition: '',
      is_deposit: false,
      uuid: '',
      name: '',
      authorUUID: '',
      status: '',
      gameId: '',
      lifetime: 0,
      amount: 0,
      played: 0,
      creationDate: '',
      reason: '',
      type: ''
    },
    isPromoActive: false,
    activePromoRewardUUID: ''
  }
}

export const state: ActivePromotionsCampaignState = getDefaultState()

export const getters: ActivePromotionsCampaignGetter = {
  getActiveBonusData: state => state.bonus_content,
  getActiveBonusAuthorUUID: state => state.authorUUID,
  getActiveBonusRewardUUID: state => state.rewardUUID,
  isAnyPromotionActive: state => state.isPromoActive,
  getAnyActivePromoRewardUUID: state => state.activePromoRewardUUID
}

export const mutations: MutationTree<ActivePromotionsCampaignState> = {
  async setBonusContent (state, content) {
    if(content.length > 0) {
      state.activePromoRewardUUID = content[0].rewardUUID;
      state.isPromoActive = true;
      let campaignData = [];
      let banner_id = cookies.get('banner_id');

      if(banner_id === undefined) {
        banner_id = 'default';
      }

      const response = await apolloClient.query({
        query: gql`
            query Promos($casinoCode: String!, $status: String!, $bannerId: String!) {
              casinos(where: { name: $casinoCode }) {
                name
                promotions(
                  where: { status: $status, advertisements: { banner_id: $bannerId } }
                ) {
                  id
                  index
                  description
                  description_ru
                  description_es
                  description_pt
                  wager_requirement
                  campaign_name
                  requirements
                  requirements_ru
                  requirements_es
                  requirements_pt
                  terms_and_condition
                  terms_and_condition_ru
                  terms_and_condition_es
                  terms_and_condition_pt
                  buttonText
                  buttonText_ru
                  buttonText_es
                  buttonText_pt
                  auto_opt_in
                  redirectToAfterClaim
                  uuid
                  status
                  is_deposit
                  is_playnow
                  is_freespins
                  is_disabled
                  advertisements(where: { banner_id: $bannerId }) {
                    banner_id
                  }
                  image {
                    formats
                  }
                }
              }
            }
        `,
        variables: {
          status: process.env.VUE_APP_WMS_URL.includes("btctest.net") ? "draft" : "published",
          casinoCode: cookies.get('casino_code'),
          bannerId: banner_id
        },
        fetchPolicy: "no-cache"
      });
      const casino_campaigns = response.data.casinos.find(c => c.name == cookies.get('casino_code'))
      campaignData = casino_campaigns.promotions.filter(
        function(data){ return data.uuid == content[0].authorUUID }
      );

      if(campaignData.length > 0) {
        const active_campaign = content[0];
        active_campaign['data'] = {...active_campaign['data'], ...campaignData[0]}
        state.bonus_content = {...state.bonus_content,...active_campaign['data']}
        state.authorUUID = active_campaign.authorUUID
        state.rewardUUID = active_campaign.rewardUUID
        state.status = active_campaign.status
      }
    }
  }
}

export const actions: ActionTree<ActivePromotionsCampaignState, RootState> = {
  async getActiveBonuses({ commit, rootGetters }) {
    // Todo: rename/refactor, as this also retrieves free spins available to the player, not only bonuses.
    // Can be refactored/merged with bonus-wager.ts
    try {
      const endpoint = `/reward_view/${rootGetters['account/userId']}?status=ACTIVE,PENDING`
      const rqOptions = rqBuilder(
        'GET',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
          commit('setBonusContent', res.data.content)
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('Could not get active promotions.', e);
    }
  },
  async cancelActiveBonus({ commit, rootGetters, dispatch }, bonusUUID) {
    try {
      if (!bonusUUID) return
      const endpoint = `/bonus/bonuses/${rootGetters['account/userId']}/${bonusUUID}/cancel`
      const rqOptions = rqBuilder(
        'PUT',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId']
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        dispatch('getActiveBonuses')
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('Could not cancel promotions.', e)
    }
  },
  async cancelActiveFreeSpins({ commit, rootGetters, dispatch }, freeSpinUUID) {
    try {
      const endpoint = `/free_spin/free-spins/${rootGetters['account/userId']}/${freeSpinUUID}/cancel`
      const rqOptions = rqBuilder(
        'POST',
        rootGetters['settings/getApiUrl'] + endpoint,
        {
          brandId: rootGetters['settings/getBrandId'],
          reason: "Try to withdraw the coins when the promotion is active."
        },
        rootGetters['account/userToken']
      )
      const res = await axios(rqOptions)
      if (res.data) {
        dispatch('getActiveBonuses')
      } else throw new Error('Invalid response from server')
    } catch (e) {
      console.log('could not cancel freespins', e)
    }
  }
}

export const activePromotionsCampaign: Module<ActivePromotionsCampaignState, RootState> = {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
