

































































































import { Component, Vue } from 'vue-property-decorator'

const AppProps = Vue.extend({})
@Component({
  components: {},
  props: ['blurred'],
  methods: {
    showModal () {
        this.$modal.show('contact-us-modal');
    },
    hideModal () {
        this.$modal.hide('contact-us-modal');
    },
    showProvablyFair() {
      // @ts-ignore
      $('#provably_fair_explain_dialog').lightbox_me({})
    },
    showExpectedReturn() {
      // @ts-ignore
      $('#expected_return_dialog').lightbox_me({})
    },
    reloadToHome(){
      this.$router.replace("/");
    }
  }
})
export default class Footer extends AppProps {

}
