




















import { Component, Vue } from 'vue-property-decorator'
import { namespace} from "vuex-class";

const AccountGetter = namespace("account").Getter;
const AppProps = Vue.extend({
  data: () => {
    return {
      isMobile: false,
    }
  },
  props: {},
  methods: {
    redirectToPromotionPage() {
      if(this.$route.name !== 'promotion'){
        if (this.$route.query.banner_id !== undefined) {
          this.$router.push({name: 'promotion', query: {banner_id: this.$route.query.banner_id}});
        } else {
          this.$router.push('/promotion');
        }
      }
    },
    allowToAccess() {
      const showAfter = new Date('2022-11-20 00:00:00');
      const today = new Date();
      if (showAfter < today) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent);
  }
})
@Component({
  components: {

  },
  methods: {
    showModal () {
        this.$modal.show('contact-us-modal');
    },
    hideModal () {
        this.$modal.hide('contact-us-modal');
    },
    showProvablyFair() {
      // @ts-ignore
      $('#provably_fair_explain_dialog').lightbox_me({})
    },
    showExpectedReturn() {
      // @ts-ignore
      $('#expected_return_dialog').lightbox_me({})
    }
  }
})
export default class HeaderNav extends AppProps {
  @AccountGetter emailVerified!: boolean
  @AccountGetter isLoggedIn: boolean
}
