



















import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const UIMutation = namespace('ui').Mutation
const AccountAction = namespace('account').Action
const AccountGetter = namespace('account').Getter
const AppProps = Vue.extend({
  data: function() {
      return {
          error: '',
          msg: '',
          success: false,
          loading: true
      }
  },
  created () {
    // @ts-ignore
  },
  async mounted() {
      if(this.$route.query.token) {
          let res = await this.verifyEmail(this.$route.query.token)
          if(res.success) {
              this.loading = false
              this.success = true
              this.msg = "your_email_address_was_successfully"
              this.$modal.show('verified-email-modal');
              // todo: possible improvement is to auto-redirect and show a toast that user
              // was successfuly verified
          } 
          else {
              this.loading = false
              this.error = "the_link_has_expired"
          }
      } else {
          this.loading = false
          this.error = "the_link_you_have_entered"
      }
  },
  methods: {
    redirectToLogin() {
      this.$router.push('/player-login')
    },
    redirectToHome() {
      this.$router.push('/home')
    }
  }
})
@Component({
  components: {}
})
export default class EmailVerification extends AppProps {
  @UIMutation('setSimpleFooter') setSimpleFooter
  @AccountAction verifyEmail
  @AccountGetter magicToken
}
