import { store } from '../../../../../store/index'

export default class GameView {

  constructor (controller) {
    this.BITCOINCOM_PROVIDER_NAME = 'bitcoincom:'
    this.CATEGORY_LIVE_GAMES = 5
    this.CATEGORY_EXCLUSIVE = 6
    this.NO_DEMO_CATEGORIES = [this.CATEGORY_LIVE_GAMES, this.CATEGORY_EXCLUSIVE]
    this.controller = controller

    if (!GameView.template) {
      GameView.template = document.querySelector('div#game_template')
    }
    let clone = document.importNode(GameView.template, true)
    this.body = clone.querySelector('.game_body')

    if (controller.model.favorite) this.body.classList.add('favorite')

    this.image = this.body.querySelector('img')
    this.image.addEventListener('load', this.imageLoaded.bind(this))
    this.image.setAttribute('src', this.controller.getIconURL())

    this.favoriteHeart = this.body.querySelector('.fav_heart svg')

    this.title = this.body.querySelector('.game_title')
    this.title.innerHTML = controller.getTitle()

    let hasRealBalance = window.VueStore.getters["account/userBalance"] !== 0
    let hasBonusBalance = window.VueStore.getters["account/userBonusBalance"] !== 0
    let hasFreeSpinsForGame = window.VueStore.getters["bonusWager/activeFreeSpinsGamesIds"].includes(this.controller.getId())
    let playNowButton = this.body.querySelector('.play_now_link')
    // If player has real (or bonus if it's not an exclusive game) balance,
    // or free spins for that particular game, allow him to play it for real;
    // otherwise modify 'play now' text and its link to open the deposit modal
    if (window.VueStore.getters["account/isLoggedIn"]
        && !hasRealBalance
        && (!hasBonusBalance || this.controller.model.isExclusive)
        && !hasFreeSpinsForGame) {
      playNowButton.innerHTML = window.i18n.t('deposit_to_play')
      playNowButton.setAttribute('href', '#')
      playNowButton.setAttribute('data-toggle', 'modal')
      playNowButton.setAttribute('data-target', '#pre_deposit_dialog')
    } else {
      playNowButton.innerHTML = window.i18n.t('play_now')
      this.body.querySelector('.play_now_link').setAttribute('href', this._buildUrl())
    }
    let noDemo = false
    this.controller.model.categoryData.data.forEach(category => {
      if (this.NO_DEMO_CATEGORIES.indexOf(category.id) !== -1) noDemo = true
    })

    // Filter out games that have no demo mode/free play, if player is not logged in; or exclusive games free play if
    // player is logged in and has real balance.
    // All exclusive games should have demo mode
    if (this.controller.model.hasDemoMode
      && (!noDemo || this.controller.model.isExclusive == 'true')
      && !(window.VueStore.getters["account/isLoggedIn"] && this.controller.model.isExclusive == 'true' && hasRealBalance)) {
      this.body.querySelector('.free_play_link').setAttribute('href', this._buildUrl(true))
    } else {
      this.body.querySelector('.free_play_link').style.display = 'none'
      this.body.querySelector('.play_now_link').style.marginTop = '21%'
    }

    let jackpot = this.controller.model.jackpot
    if (jackpot) {
      this.body.querySelector('.game_jackpot').style.display = 'block'
      this.body.querySelector('.game_jackpot').innerHTML += jackpot + ' BTC'
    }

    this.controller.model.registerObserver(this)
    this.setUpHandlers()
    this.update(this.controller.model)
  }

  _buildUrl (isDemo = false) {
    let game = this.controller.model
    let link = ''
    if (game.isExclusive == 'true') {
      link = (store.getters['account/isLoggedIn'] || isDemo) ? '/' + game.gameId : '/loginorsignup'
    } else {
      link = '3game?game_id=' + game.gameId + (isDemo ? '&demo=true' : '')
    }
    return link
  }

  update (model) {
    this.drawFavoriteClass(model)
  }

  setUpHandlers () {
    this.setUpFavoriteHandler()
  }

  addTo (parent) {
    parent.appendChild(this.body)
  }

  setUpFavoriteHandler () {
    this.favoriteHeart.addEventListener('click', () => {
      this.controller.setFavoriteData()
    })
  }

  setUpMobile () {
    this.image.addEventListener('click', () => {
      let template = document.querySelector('template#mobile_game_overlay')
      let overlayClone = document.importNode(template.content, true)
      let overlay = overlayClone.querySelector('.mobile_overlay')
      let container = overlay.querySelector('.overlay_focus')
      container.addEventListener('click', event => event.stopPropagation())
      let closeOverlay = () => overlay.remove()
      let closeOverlaySvg = container.querySelector('.close_overlay')
      overlay.addEventListener('click', closeOverlay)
      closeOverlaySvg.addEventListener('click', closeOverlay)
      let bodyClone = document.importNode(this.body, true)
      let gameLinks = bodyClone.querySelector('.game_view_overlay')
      gameLinks.classList.remove('game_view_overlay')
      gameLinks.classList.add('game_links')
      container.appendChild(bodyClone)
      container.appendChild(gameLinks)
      let ultra_container = document.querySelector('#ultra_container')
      ultra_container.appendChild(overlay)
    })
  }

  drawFavoriteClass (model) {
    let game = JSON.parse(model.gameData)
    game.favorite ? this.drawFavorite() : this.drawNotFavorite()
  }

  drawFavorite () {
    this.body.classList.add('favorite')
  }

  drawNotFavorite () {
    this.body.classList.remove('favorite')
  }

  //Observable to see if image is loaded
  isImageLoaded (cb) {
    this.controller.model.imageSubs.push(cb)
    if (this.controller.model.loaded) cb(true)
  }

  imageLoaded () {
    this.controller.model.loaded = true
    this.controller.model.imageSubs.forEach(cb => cb(true))
  }
}
