



































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Common from "../../mixins/Common.vue";
import QrcodeVue from "qrcode.vue";
import axios from 'axios'
import DepositDialogSkeleton from "@/components/dialogs/DepositDialogSkeleton.vue";


const SettingsGetter = namespace('settings').Getter
const AccountGetter = namespace('account').Getter
const PaymentMethodsGetter = namespace('paymentMethods').Getter
const AccountAction = namespace('account').Action
const AccountMutation = namespace('account').Mutation
const cookies = require('js-cookie')

const PaymentMethodsMutation = namespace('paymentMethods').Mutation

const AppProps = Vue.extend({
  mixins: [Common],
  data: () => {
    return {
      activePage: 0,
      copied: false,
      moonpayLoadingSignedUrl: false,
      isMobile: /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(
        navigator.userAgent
      ),
      isAppleDevice: /iphone|ipod|ipad/i.test(navigator.userAgent),
      moonpayModal: false,
      moonpayUrl: '',
      xanpoolUrl: '',
      clickCount: 0,
      loading: true,
      userAddr: '',
      tooltipHover: false,
      selectedCountry: "",
      xanpoolPaymentMethods: [],
      availablePaymentMethods: [],
      paymentMethodsLoading: false,
      selectedPaymentMethodIdx: 0,
      Deselect: {
        render: createElement => createElement('span', '')
      },
      thirdPartyPaymentMethodLoader: false,
    }
  },
  watch: {
    userAddress: function (newValue) {
      this.userAddr = newValue.split(':')[1]
      if (!this.userAddr) {
        this.userAddr = newValue
      }
    },
    depositIncomingCryptoAmount: () => {
      ($('#pre_deposit_dialog') as any).modal('hide')
    },
    selectedCountry: function(countryCodeIndex) {
      this.selectCountry(countryCodeIndex);
    },
    async getSelectedPaymentMethod (newVal) {
      if(this.isMobile) {
        this.loading = false
        this.activePage = 0
        this.setPaymentIndex(newVal)
      } else {
        this.loading = true
        this.activePage = -99;
        if(newVal === -1) return
        let map = this.getCountries.map(country => country.countryCode)
        let idx = map.indexOf(this.userCountryCode.toUpperCase())
        if (idx < 0) idx = 0
        this.selectedCountry = idx

        window.setTimeout(async () => {
          this.usePaymentMethod(this.getSelectedPaymentMethod)
        }, 1000)
      }
    }
  },
  async mounted () {
    const that = this


    $(this.$refs.modal).on('hidden.bs.modal', () => {
      this.activePage = 0
      this.moonpayModal = false
      this.setSelectedPaymentMethod(-1)
    })
    $(this.$refs.modal).on('show.bs.modal', async () => {
      if (this.limitedAccount) {
        this.$bvModal.show('new-restricted-region-modal')
        return
      }
      this.activePage = 0
      if (cookies.get('cid')) {
        const url = `/api/affreport?cid=${cookies.get(
          'cid'
        )}&et=open_deposit_screen`
        axios.get(url)
      }

      const initializeDepositScreen = time => {
        window.setTimeout(async () => {
          if (!this.userAddress) {
            initializeDepositScreen(time)
          } else {
            this.userAddr = this.userAddress.split(':')[1]
            if (!this.userAddr) {
              this.userAddr = this.userAddress
            }

            // Translation hook for 'All Other Countries', might not be the brightest solution as a middleware though
            this.getCountries[0].country = this.$i18n.t('all_other_countries')
            let map = this.getCountries.map(country => country.countryCode)
            let idx = map.indexOf(this.userCountryCode.toUpperCase())
            if (idx < 0) idx = 0
            this.selectedCountry = idx
            that.loading = false;
            this.thirdPartyPaymentMethodLoader = (this.activePage === -99);
          }
        }, time)
      }
      initializeDepositScreen(500)

      if (!this.userAddress.length) {
        await this.getDepositAddress()
      }
      const urlParams = new URL(location.href).searchParams;
      if (urlParams.has("convert")) {
        this.activePage = 1;
        that.openSideShift();
      } else if (urlParams.has("buy")) {
        this.activePage = 0;
      }
    })
  },
  methods: {
    iFrameLoaded() {
      this.thirdPartyPaymentMethodLoader = false
    },
    getPaymentMethodName(string) {
      return string
              .toLowerCase()
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
    },
    setPaymentIndex(index) {
      // if user single clicks, set payment method
      this.clickCount++
      this.selectedPaymentMethodIdx = index

      // if user double clicks on item, open payment screen
      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0
        }, 250)
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer)
        this.clickCount = 0
        this.usePaymentMethod(index)

      }
    },
    closeToolTip () {
      this.tooltipHover = false
    },
    // method is responsible for launching payment method
    async usePaymentMethod (paymentIdx = this.selectedPaymentMethodIdx) {
      // we do not know how long iframes take to load from external vendors
      // so we implement a fixed 1 second spinner from our side to improve UX
      let payment = this.availablePaymentMethods[paymentIdx]
      if (payment.aggregator === 'moonpay') {
        let windowReference = null
        // default fallback currency is USD
        let denomination = "USD";
        // get the currency of the selected method
        let denominationArr = this.getCountryCurrencies.filter(
          currency => currency.country === this.getCountries[this.selectedCountry].countryCode)
        // if its not available get the denomination of the ip address
        if (denominationArr.length === 0) {
          denominationArr = this.getCountryCurrencies.filter(
            currency => currency.country === this.userCountryCode
          )
          if (denominationArr.length != 0)
            denomination = denominationArr[0].currency
        } else {
          denomination = denominationArr[0].currency;
        }

        // we need to have user
        this.moonpayLoadingSignedUrl = true;
        await this.getDepositAddress()

        const moonpayUrl =
          `${this.getMoonpayUrl}?apiKey=${this.getMoonpayApiKey}` +
          `&currencyCode=${this.getPlatformCurrency}` +
          `&colorCode=%2300C58A` +
          `&baseCurrencyCode=${denomination}` +
          `&walletAddress=${this.userAddr}`

        await this.getSignedMoonpayUrl(moonpayUrl)
        this.moonpayLoadingSignedUrl = false;
        windowReference = window.open()
        windowReference.location = moonpayUrl;
      } else if (payment.aggregator === 'xanpool') {
        this.xanpoolUrl = `${this.getXanpoolUrl}/?apiKey=${this.getXanpoolApiKey}&partnerData=${this.userId}&method=${payment.method}&transactionType=buy&cryptoCurrency=${this.getPlatformCurrency}&wallet=${this.userAddr}`
        this.thirdPartyPaymentMethodLoader = true;
        this.activePage = 4
      }
    },
    doCopy (payload) {
      this.$copyText(payload)
      this.copied = true
      if (cookies.get('cid')) {
        const url = `/api/affreport?cid=${cookies.get('cid')}&et=copy_address`
        $.get(url)
      }
      setTimeout(() => {
        this.copied = false
      }, 3000)
    },
    closeModal () {
      ($(this.$refs.modal) as any).modal('hide')
      // this.setSelectedPaymentMethod('')
    },
    async selectCountry (countryCodeIndex) {
      let country = this.getCountries[countryCodeIndex]
      this.availablePaymentMethods = []
      if (country.moonpay) {
        country.moonpayMethods.forEach(method =>
          this.availablePaymentMethods.push({ method, aggregator: 'moonpay' })
        )
      }

      if (country.xanpool && this.getPlatformCurrency === 'BTC') {
        if (this.xanpoolPaymentMethods.length === 0) {
          try {
            const url = '/api/xanpool/methods'
            let res = await axios.get(url)
            if (res.data.buy) this.xanpoolPaymentMethods = res.data.buy
          } catch (e) {
            console.log('could not reach xanpool')
          }
        }
        this.xanpoolPaymentMethods
          .filter(item => item.country === country.countryCode)
          .map(item => item.methods)
          .forEach(item => {
            item.forEach(item =>
              this.availablePaymentMethods.push({
                method: item.method,
                aggregator: 'xanpool'
              })
            )
          })
      }
      this.paymentMethodsLoading = false
    },
    async openSideShift () {
      if (this.getPlatformCurrency === 'BTC') {
        (window as any).__SIDESHIFT__.defaultDepositMethodId = 'bch'
        ;(window as any).__SIDESHIFT__.defaultSettleMethodId = 'btc'
      } else if (this.getPlatformCurrency === 'BCH') {
        (window as any).__SIDESHIFT__.defaultDepositMethodId = 'btc'
        ;(window as any).__SIDESHIFT__.defaultSettleMethodId = 'bch'
      }
      (window as any).__SIDESHIFT__.settleAddress = this.userAddress
      ;(window as any).sideshift.show()
      this.updateParamsInLocation('remove');
      ($(this.$refs.modal) as any).modal('hide')
    }
  }
})
@Component({
  components: {DepositDialogSkeleton, QrcodeVue },
})
export default class DepositDialog extends AppProps {
  @SettingsGetter getPlatformCurrency!: String
  @SettingsGetter getMoonpayApiKey!: String
  @SettingsGetter getMoonpayUrl!: String
  @SettingsGetter getXanpoolUrl!: String
  @SettingsGetter getXanpoolApiKey!: String
  @AccountGetter userAddress
  @AccountGetter email
  @AccountGetter userId
  @AccountGetter userBalance
  @AccountGetter depositIncomingCryptoAmount
  @AccountGetter limitedAccount
  @AccountMutation setDepositIncomingAmount
  @AccountGetter userBonusBalance
  @AccountGetter signedMoonPayUrl
  @AccountGetter userCountryCode
  @AccountAction getSignedMoonpayUrl
  @AccountAction getDepositAddress
  @PaymentMethodsGetter getCountries
  @PaymentMethodsGetter getCountryCurrencies
  @PaymentMethodsGetter getSelectedPaymentMethod
  @PaymentMethodsMutation setSelectedPaymentMethod
}
