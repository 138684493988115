



















import { Component, Vue } from 'vue-property-decorator'
import Common from '@/mixins/Common.vue'
import { namespace } from 'vuex-class'

const SettingsGetter = namespace('settings').Getter
const AppProps = Vue.extend({
  mixins: [Common],
  props: {}
})
@Component({
  components: {}
})
export default class HeaderControls extends AppProps {
  @SettingsGetter getPlatformName!: String
}
